import CONFIG from 'src/constants/Config';

/* eslint-disable no-console */
export const badge = {
  MISSINGDATA: 'warning',
  PENDING: 'warning',
  CONFIRMED: 'success',
};

export const parseJson = str => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return {};
  }
};

export const checkUrlExists = async url => {
  let exists = false;
  try {
    await fetch(url, { method: 'HEAD' });
  } catch (error) {
    exists = false;
  }
  return new Promise(resolve => exists);
};

export const formatIBAN = value => {
  value = value.replace(/[^a-zA-Z0-9]/g, '').slice(0, 34);
  return value.replace(/(.{4})/g, '$1 ');
};

export const getRandomItem = list => list[Math.floor(Math.random() * list.length)];
export const isNotEmpty = value => value !== undefined && value !== null && value !== '';

export const activityImageUrl = (tenantId, id, type) =>
  id !== undefined && id !== null && id !== ''
    ? `${CONFIG.BASE_URL}/images/${tenantId}/activity/${id?.split('#')[0]}/${
        id?.split('#')[1]
      }/${type}`
    : '';

export const offeringImageUrl = (tenantId, id, visibility, type) =>
  `${CONFIG.BASE_URL}/images/${tenantId}/offering/${id}/${visibility}_${type}`;
export const offeringNFTUrl = (tenantId, id, visibility, type) =>
  `${CONFIG.BASE_URL}/images/${tenantId}/offering/${id}/nft`;
export const pageContentUrl = (domain, name, lng, type) =>
  `${CONFIG.BASE_URL}/images/${domain}/pages/${name}.${lng}`;

export const orderStatusBadge = {
  NEW: 'warning',
  ORDERED: 'info',
  PAID: 'info',
  ERROR: 'danger',
  NOPAYMENT: 'info',
  READYTOMINT: 'success',
  READYTOUPLOAD: 'success',
  STARTMINTING: 'success',
  UPLOADING: 'success',
  CANCELED: 'secondary',
  OWNER: 'primary',
  OUTDATED: 'danger',
};

export const BLOCKCHAIN_CHAINID = {
  ETHEREUM_MAINNET: 1,
  ETHEREUM_ROPSTEN: 3,
  ETHEREUM_LOCALHOST: 1337,
  POLYGON_MATIC: 137,
  POLYGON_MUMBAI: 80001,
  POLKADOT_MOONBEAM: 1284,
  POLKADOT_MOONRIVER: 1285,
  POLKADOT_MOONBASEALPHA: 1287,
  XDAI_CHAIN: 100,
  ECREDITS_MAINNET: 63000,
  ECREDITS_TESTNET: 63001,
};

export function custom_sort_transaction(a, b) {
  return new Date(a.transactionDate).getTime() - new Date(b.transactionDate).getTime();
}

export function custom_sort_desc_transaction(a, b) {
  return new Date(b.transactionDate).getTime() - new Date(a.transactionDate).getTime();
}
