import { fromJS } from 'immutable';
import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import {
  getAssetNextToken,
  getAssetsNextToken,
  getPaymentsData,
  getPortfolioTransactionsData,
  getSelectedOrderId,
  getTransferNextToken,
} from 'src/views/Account/account.selector';
import {
  resetAppSettings,
  setActiveAccountAction,
  setRedirectAction,
} from 'src/components/AppSettings/appSettings.action';
import {
  showError,
  showInformation,
  showSuccess,
} from 'src/components/shared/Notification/actions';
import NotificationActions from 'src/components/shared/Notification/actions';
import { verifyBoardingStep } from 'src/views/Store/store.saga';
import { setBoardingSteps } from 'src/views/Store/store.action';
import { getBoardingSelectedOffering, getOnboardingProcess } from 'src/views/Store/store.selector';
import { push } from 'react-router-redux';
import { isEmpty } from 'lodash';
import { custom_sort_desc_transaction } from 'src/utils/common';

import * as queries from './GraphQL/queries';
import * as paymentQueries from './GraphQL/queries.payments';
import * as mutations from './GraphQL/mutations';
import { actionsTypes } from './constants';
import {
  addAccountActions,
  addBlockchainWalletActions,
  changeProfilePasswordAction,
  checkSignatureBlockchainWalletActions,
  confirmLinkPaymentAccountsActions,
  fetchAccountActions,
  updateAccountActions,
  toggleCreateAccountModalAction,
  fetchBlockchainWalletActions,
  transferTokenFromBlockchainWalletActions,
  fetchAccountPortfolioActions,
  fetchAccountOrderActions,
  setMainBlockchainWalletActions,
  updateAccountEmailActions,
  confirmAcountEmailActions,
  fetchOrderDetailsActions,
  fetchAccountOpenOrderActions,
  fetchAccountAssetActions,
  setWalletAddStepActions,
  setWalletTransferStepActions,
  updateAccountPaperWalletActions,
  fetchKycSignDDataActions,
  confirmKycSignDDataActions,
  updateInvestorTypeActions,
  updateFinancialSituationActions,
  updateKnowledgeActions,
  updateReferenceBankActions,
  updateInvestmentObjectivesActions,
  fetchKycGubbiDataActions,
  updateAccountMobileActions,
  confirmAcountMobileActions,
  updateAMLActions,
  fetchAccountTransfersActions,
  fetchTransferDetailsActions,
  listTokenOnMarketActions,
  setWalletListStepActions,
  confirmAcountEmailPinActions,
  fetchAccountPaymentsActions,
  fetchKycSumsubDataActions,
} from './account.action';
import { getBoardingStepsNormalizer, normalizeAccount } from './normalizeAccount';

const ACCOUNTID_TEMP = localStorage.getItem('activeAccount');

export function* fetchAccountData({ payload }) {
  // check if there is a accountId stored
  // if yes, take this account id
  // DAGER: Error if accountId is from a user where you
  // don't have access
  const accountId = localStorage.getItem('activeAccount');
  const selectedOffering = yield select(getBoardingSelectedOffering);
  let params = {
    id: {
      eq: selectedOffering || ' ',
    },
  };

  if (accountId) {
    params = {
      ...params,
      accountId: {
        eq: localStorage.getItem('activeAccount'),
      },
    };
  }

  try {
    const response = yield call([API, 'graphql'], graphqlOperation(queries.readAccount, params));
    const offering = response.data.myPortal.offerings.items?.[0];
    const account = response.data.myPortal.accounts.items[0].account;
    const affiliateId = response.data.myPortal.accounts.items[0].account?.affiliate?.referralId;

    if (account) {
      yield put(setActiveAccountAction(account.id, account.name, affiliateId));
      const blockchainWallets = account.connectedAccounts?.items.filter(element =>
        element.id.startsWith('BLOCKCHAIN#'),
      );
      const normalizedData = normalizeAccount(account, offering);
      const boardingSteps = getBoardingStepsNormalizer(normalizedData);

      yield put(setBoardingSteps(boardingSteps));

      yield put(
        fetchAccountActions.success({
          account: normalizedData,
          // users: account.users.items,
          blockchainWallets,
        }),
      );
    } else {
      yield put(fetchAccountActions.pending());
    }

    if (payload && payload.loadOrders) {
      yield put(fetchAccountOpenOrderActions.pending());
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    if (Array.isArray(error.errors) && error.errors[0]?.originalError?.response?.status === 403) {
      yield call([Auth, 'signOut']);
      yield put(resetAppSettings());
      yield put(
        showInformation({
          title: 'common:Notification.Error.SessionTitle',
          message: 'common:Notification.Error.SessionExpired',
        }),
      );
    }
    yield put(fetchAccountActions.error());
  }
}

export function* fetchPortfolioData({ payload }) {
  const nextToken = yield select(getAssetsNextToken);
  let transactions = yield select(getPortfolioTransactionsData);

  try {
    let params = {
      accountId: {
        eq: localStorage.getItem('activeAccount'),
      },
      // filter: {
      //   transactionId: {
      //     beginsWith: 'OWNER#',
      //   },
      // },
      nextToken: nextToken !== '' ? nextToken : null,
    };

    if (!isEmpty(payload?.offeringId)) {
      params['filter'] = {
        offeringId: {
          eq: payload?.offeringId,
        },
      };
    }

    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(queries.readAccountPortfolio, params),
    );

    const loadedTransactions =
      response.data.myPortal.accounts.items[0].account.transactions?.items || [];
    const _nextToken = response.data.myPortal.accounts.items[0].account.transactions?.nextToken;

    let statistics = {};
    transactions = [...transactions, ...loadedTransactions];

    const ownerList = transactions.filter(asset => asset.transactionId.startsWith('OWNER#'));
    const orderList = transactions.filter(asset => asset.transactionId.startsWith('ORDER#'));
    const paymentList = transactions.filter(asset => asset.transactionId.startsWith('PAYMENT#'));
    if (!isEmpty(payload?.offeringId)) {
      // const owner = transactions.filter(asset => asset.transactionId.startsWith('OWNER#'));
      // transactions = transactions.filter(asset => asset.transactionId.startsWith('ORDER#'));
      statistics['amount'] = 0;
      if (ownerList.length > 0) {
        statistics['amount'] = ownerList[0].amount;
        statistics['decimals'] = ownerList[0].offering?.decimals;
        statistics['symbol'] = ownerList[0].offering?.symbol;
      }

      let orderBonusList =
        transactions.filter(
          asset =>
            asset.transactionId.startsWith('ORDER#BONUS#') &&
            asset.offeringId === payload?.offeringId,
        ) || [];
      let rewardsPaidOut = BigInt(0);
      let rewardsPlanned = BigInt(0);
      let referrals = 0;
      orderBonusList.forEach(reward => {
        if (reward.status === 'OWNER') {
          rewardsPaidOut += BigInt(reward.amount || 0);
          referrals += 1;
        } else if (['ORDERED', 'NOPAYMENT'].includes(reward.status)) {
          rewardsPlanned += BigInt(reward.amount || 0);
          referrals += 1;
        }
      });

      statistics['rewards'] = (rewardsPaidOut + rewardsPlanned).toString();

      statistics['rewardsPaidOut'] = rewardsPaidOut.toString();
      statistics['rewardsPlanned'] = rewardsPlanned.toString();
      statistics['referrals'] = referrals.toString();

      // const rewards = transactions
      //   .filter(asset => asset.transactionId.startsWith('REFERRAL#'))
      //   .map(asset => asset.amount)
      //   .reduce((acc, amount) => acc + amount, 0);

      // console.log(rewards);
    }

    yield put(
      fetchAccountPortfolioActions.success({
        assets: ownerList,
        orders: orderList,
        payments: paymentList,
        transactions: transactions,
        nextToken: _nextToken,
        statistics,
      }),
    );
    if (!isEmpty(_nextToken)) {
      yield put(fetchAccountPortfolioActions.pending(payload));
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchAccountPortfolioActions.error());
  }
}

export function* fetchAssetData({ payload }) {
  const nextToken = yield select(getAssetNextToken);

  try {
    const params = {
      accountId: {
        eq: localStorage.getItem('activeAccount'),
      },
      filter: {
        transactionId: {
          eq: payload,
        },
      },
      nextToken: nextToken !== '' ? nextToken : null,
    };
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(queries.readAccountPortfolio, params),
    );
    const assets = response.data.myPortal.accounts.items[0].account.transactions.items;
    const _nextToken = response.data.myPortal.accounts.items[0].account.transactions.nextToken;

    yield put(
      fetchAccountAssetActions.success({
        asset: assets[0],
        nextToken: assets.length === 0 ? _nextToken : null,
      }),
    );
    if (!isEmpty(_nextToken) && assets.length === 0) {
      yield put(fetchAccountAssetActions.pending(payload));
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchAccountAssetActions.error());
  }
}

export function* fetchOpenOrderData({ payload }) {
  try {
    const params = {
      accountId: {
        eq: localStorage.getItem('activeAccount'),
      },
      filter: {
        transactionId: {
          beginsWith: 'ORDER#',
        },
        status: {
          eq: 'NEW', //ORDERED,
        },
      },
      limit: 6, // todo need to check why it's not working
    };
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(queries.readAccountPortfolio, params),
    );
    const openOrders = response.data.myPortal.accounts.items[0].account.transactions.items;

    yield put(
      fetchAccountOpenOrderActions.success({
        openOrders,
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchAccountOpenOrderActions.error());
  }
}

export function* fetchTransfersData({ payload }) {
  try {
    const params = {
      accountId: {
        eq: localStorage.getItem('activeAccount'),
      },
      filter: {
        transactionId: {
          beginsWith: 'TRANSFERORDER#',
        },
      },
      limit: 50, // todo need to check why it's not working
    };
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(queries.readAccountTransfers, params),
    );
    const transfers = response.data.myPortal.accounts.items[0].account.transactions.items;

    yield put(
      fetchAccountTransfersActions.success({
        transfers,
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchAccountTransfersActions.error());
  }
}

export function* fetchOrderData({ payload }) {
  try {
    const params = {
      accountId: {
        eq: localStorage.getItem('activeAccount'),
      },
      filter: {
        transactionId: {
          beginsWith: 'ORDER#',
        },
      },
    };
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(queries.readAccountPortfolio, params),
    );
    const orders = response.data.myPortal.accounts.items[0].account.transactions.items;

    yield put(
      fetchAccountOrderActions.success({
        orders,
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchAccountOrderActions.error());
  }
}

export function* fetchOrderDetails({ payload }) {
  const orderId = yield select(getSelectedOrderId);
  try {
    const params = {
      accountId: {
        eq: localStorage.getItem('activeAccount'),
      },
      filter: {
        transactionId: {
          // beginsWith: 'ORDER#',
          eq: orderId,
        },
      },
    };

    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(queries.readAccountOrders, params),
    );
    const details = fromJS(
      response.data.myPortal.accounts.items[0].account.transactions.items[0] || {},
    );

    yield put(
      fetchOrderDetailsActions.success({
        details,
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchOrderDetailsActions.error());
  }
}

export function* fetchTransferDetails({ payload }) {
  const nextToken = yield select(getTransferNextToken);

  try {
    const params = {
      accountId: {
        eq: localStorage.getItem('activeAccount'),
      },
      filter: {
        transactionId: {
          // beginsWith: 'ORDER#',
          eq: payload,
        },
      },
      nextToken: nextToken !== '' ? nextToken : null,
    };

    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(queries.readAccountTransfers, params),
    );
    const transfers = response.data.myPortal.accounts.items[0].account.transactions.items;
    const _nextToken = response.data.myPortal.accounts.items[0].account.transactions.nextToken;

    yield put(
      fetchTransferDetailsActions.success({
        transfer: transfers[0] || {},
        nextToken: transfers.length === 0 ? _nextToken : null,
      }),
    );

    if (!isEmpty(_nextToken) && transfers.length === 0) {
      yield put(fetchTransferDetailsActions.pending(payload));
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchTransferDetailsActions.error());
  }
}

export function* fetchBlockchainWalletData({ payload }) {
  try {
    const params = {
      accountId: {
        eq: localStorage.getItem('activeAccount'),
      },
      filter: {
        id: {
          eq: payload,
        },
      },
    };
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(queries.readAccountBlockchainWallet, params),
    );
    const blockchainWallet =
      response.data.myPortal.accounts.items[0].account.connectedAccounts.items[0];
    yield put(
      fetchBlockchainWalletActions.success({
        blockchainWallet,
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchBlockchainWalletActions.error());
  }
}

export function* fetchSignDDetailsSaga({ payload }) {
  const params = {
    accountId: localStorage.getItem('activeAccount'),
  };
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.generateIPSigndSessionToken, params),
    );
    yield put(fetchKycSignDDataActions.success(response.data.generateIPSigndSessionToken));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchKycSignDDataActions.error());
    yield put(
      NotificationActions.showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.code
        }`,
      }),
    );
  }
}

export function* fetchSumsubDetailsSaga({ payload }) {
  const params = {
    accountId: localStorage.getItem('activeAccount'),
  };
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.generateSumsubToken, params),
    );

    const accountResponse = response.data.generateSumsubToken;
    const normalizedData = normalizeAccount(accountResponse);

    yield put(
      fetchKycSumsubDataActions.success({
        credentials: {
          access_token: accountResponse.kycSession,
        },
        account: normalizedData,
      }),
    );

    if (normalizedData.kycStatus === 'CLOSED_CONFIRMED') {
      const isOnboarding = yield select(getOnboardingProcess);

      if (isOnboarding) {
        yield call(verifyBoardingStep);
      } else {
        yield put(setRedirectAction('/dashboard'));
      }
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchKycSumsubDataActions.error());
    yield put(
      NotificationActions.showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.code
        }`,
      }),
    );
  }
}

export function* fetchPaymentsData({ payload }) {
  let payments = yield select(getPaymentsData);
  const nextToken = yield select(getTransferNextToken);

  try {
    const params = {
      accountId: {
        eq: localStorage.getItem('activeAccount'),
      },
      limit: 500, // todo need to check why it's not working
      nextToken: !isEmpty(nextToken) ? nextToken : null,
    };
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(paymentQueries.readPayments, params),
    );
    const data = response.data.myPortal.accounts.items[0].account.payments?.items;
    const _nextToken = response.data.myPortal.accounts.items[0].account.payments?.nextToken;

    payments = [...payments, ...data];
    payments = payments.sort(custom_sort_desc_transaction);

    yield put(
      fetchAccountPaymentsActions.success({
        data: payments,
        nextToken: _nextToken,
      }),
    );

    if (!isEmpty(_nextToken)) {
      yield put(fetchAccountPaymentsActions.pending(payload));
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchAccountPaymentsActions.error());
  }
}

export function* confirmSignDDetailsSaga({ payload }) {
  const params = {
    accountId: localStorage.getItem('activeAccount'),
  };
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.confirmSigndIdentification, params),
    );

    const accountResponse = response.data.confirmSigndIdentification;
    const normalizedData = normalizeAccount(accountResponse);

    yield put(
      confirmKycSignDDataActions.success({
        account: normalizedData,
      }),
    );
    yield call(verifyBoardingStep);
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(confirmKycSignDDataActions.error());
    yield put(
      showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.code
        }`,
      }),
    );
  }
}

export function* fetchGubbiDetailsData({ payload }) {
  const params = {
    accountId: localStorage.getItem('activeAccount'),
  };
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.generateGubbiKYCSession, params),
    );

    const accountResponse = response.data.generateGubbiKYCSession;
    const normalizedData = normalizeAccount(accountResponse);

    yield put(
      fetchKycGubbiDataActions.success({
        url: response.data.generateGubbiKYCSession.kycUrl || '',
        account: normalizedData,
      }),
    );
    if (normalizedData.kycStatus === 'CLOSED_CONFIRMED') {
      const isOnboarding = yield select(getOnboardingProcess);
      if (isOnboarding) {
        yield call(verifyBoardingStep);
      } else {
        yield put(push('/dashboard'));
      }
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchKycGubbiDataActions.error());
    if (error.errors[0].message === 'PersonalDataNotConfirmed') {
      yield put(push('/account/edit'));
    }
    yield put(
      NotificationActions.showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.code
        }`,
      }),
    );
  }
}

export function* addAccountData({ payload }) {
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.addIpAccount, payload),
    );
    const accountId = response.data.addIPAccount.id;
    const accountName = response.data.addIPAccount.name;
    yield put(setActiveAccountAction(accountId, accountName));
    localStorage.setItem('activeAccount', accountId);
    yield put(
      addAccountActions.success({
        data: response.data.addIPAccount,
      }),
    );
    yield put(toggleCreateAccountModalAction());
    window.location.reload();
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(addAccountActions.error());
  }
}

export function* updateAccountData({ payload }) {
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.updateIpAccount, payload),
    );
    const accountResponse = response.data.updateIPAccount;
    const normalizedData = normalizeAccount(accountResponse);

    yield put(
      updateAccountActions.success({
        account: normalizedData,
      }),
    );
    yield call(verifyBoardingStep);
  } catch (error) {
    console.error(error); // eslint-disable-line

    yield put(
      showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.message
        }`,
      }),
    );
    yield put(updateAccountActions.error());
  }
}

export function* setAccountTypeData({ payload }) {
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.setAccountType, payload),
    );
    const accountResponse = response.data.setAccountType;
    const normalizedData = normalizeAccount(accountResponse);

    yield put(
      updateAccountActions.success({
        account: normalizedData,
      }),
    );
    yield call(verifyBoardingStep);
  } catch (error) {
    console.error(error); // eslint-disable-line

    yield put(
      showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.message
        }`,
      }),
    );
    yield put(updateAccountActions.error());
  }
}

export function* updateAccountAMLData({ payload }) {
  try {
    const response = yield call([API, 'graphql'], graphqlOperation(mutations.updateAML, payload));
    const accountResponse = response.data.updateAML;
    const normalizedData = normalizeAccount(accountResponse);

    yield put(
      updateAMLActions.success({
        account: normalizedData,
      }),
    );
    yield call(verifyBoardingStep);
  } catch (error) {
    console.error(error); // eslint-disable-line

    yield put(
      showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.message
        }`,
      }),
    );
    yield put(updateAMLActions.error());
  }
}

export function* updateInvestorTypeData({ payload }) {
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.updateIpAccount, payload),
    );
    const accountResponse = response.data.updateIPAccount;
    const normalizedData = normalizeAccount(accountResponse);

    yield put(
      updateInvestorTypeActions.success({
        account: normalizedData,
      }),
    );
    yield put(
      showSuccess({
        title: 'view_account:InvestorType.Notification.Title',
        message: 'view_account:InvestorType.Notification.Success',
      }),
    );
    yield call(verifyBoardingStep);
  } catch (error) {
    console.error(error); // eslint-disable-line

    yield put(
      NotificationActions.showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.message
        }`,
      }),
    );
    yield put(updateAccountActions.error());
  }
}

export function* updateInvestmentObjectivesData({ payload }) {
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.updateInvestmentObjectives, payload),
    );
    const accountResponse = response.data.updateInvestmentObjectives;
    const normalizedData = normalizeAccount(accountResponse);

    yield put(
      updateInvestmentObjectivesActions.success({
        account: normalizedData,
      }),
    );
    yield put(
      NotificationActions.showSuccess({
        title: 'view_account:InvestmentObjectives.Notification.Title',
        message: 'view_account:InvestmentObjectives.Notification.Success',
      }),
    );
    yield call(verifyBoardingStep);
  } catch (error) {
    console.error(error); // eslint-disable-line

    yield put(
      NotificationActions.showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.message
        }`,
      }),
    );
    yield put(updateInvestmentObjectivesActions.error());
  }
}

export function* updateFinancialSituationData({ payload }) {
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.updateFinancialSituation, payload),
    );
    const accountResponse = response.data.updateFinancialSituation;
    const normalizedData = normalizeAccount(accountResponse);

    yield put(
      updateFinancialSituationActions.success({
        account: normalizedData,
      }),
    );
    yield put(
      NotificationActions.showSuccess({
        title: 'view_account:FinacialSituation.Notification.Title',
        message: 'view_account:FinancialSituation.Notification.Success',
      }),
    );
    yield call(verifyBoardingStep);
  } catch (error) {
    console.error(error); // eslint-disable-line

    yield put(
      NotificationActions.showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.message
        }`,
      }),
    );
    yield put(updateFinancialSituationActions.error());
  }
}

export function* updateKnowledgeData({ payload }) {
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.updateKnowledgeAndExperience, payload),
    );
    const accountResponse = response.data.updateKnowledgeAndExperience;
    const normalizedData = normalizeAccount(accountResponse);

    yield put(
      updateKnowledgeActions.success({
        account: normalizedData,
      }),
    );
    yield put(
      NotificationActions.showSuccess({
        title: 'view_account:InvestorType.Notification.Title',
        message: 'view_account:InvestorType.Notification.Success',
      }),
    );
    yield call(verifyBoardingStep);
  } catch (error) {
    console.error(error); // eslint-disable-line

    yield put(
      NotificationActions.showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.message
        }`,
      }),
    );
    yield put(updateKnowledgeActions.error());
  }
}

export function* updateReferenceBankData({ payload }) {
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.updateReferenceBankAccount, payload),
    );
    const accountResponse = response.data.updateReferenceBankAccount;
    const normalizedData = normalizeAccount(accountResponse);

    yield put(
      updateReferenceBankActions.success({
        account: normalizedData,
      }),
    );
    yield put(
      NotificationActions.showSuccess({
        title: 'view_account:ReferenceBankAccount.Notification.Title',
        message: 'view_account:ReferenceBankAccount.Notification.Success',
      }),
    );
    yield call(verifyBoardingStep);
  } catch (error) {
    console.error(error); // eslint-disable-line

    yield put(
      NotificationActions.showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.message
        }`,
      }),
    );
    yield put(updateReferenceBankActions.error());
  }
}

export function* updateAccountPaperWalletData({ payload }) {
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.updateIpAccountPaperWallet, payload),
    );
    const accountResponse = response.data.updateIPAccountPaperWallet;
    const normalizedData = normalizeAccount(accountResponse);

    yield put(
      updateAccountPaperWalletActions.success({
        account: normalizedData,
      }),
    );

    yield call(verifyBoardingStep);
  } catch (error) {
    console.error(error); // eslint-disable-line

    yield put(
      NotificationActions.showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.message
        }`,
      }),
    );
    yield put(updateAccountPaperWalletActions.error());
  }
}

export function* updateAccountEmailData({ payload }) {
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.updateIpAccountEmail, payload),
    );
    const accountResponse = response.data.updateIPAccountEmail;
    const normalizedData = normalizeAccount(accountResponse);

    // if (normalizedData.emailStatus === 'CONFIRMED') {
    //   normalizedData.emailStatus = 'UPDATED';
    // }

    yield put(
      updateAccountEmailActions.success({
        account: normalizedData,
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line

    yield put(
      NotificationActions.showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.message
        }`,
      }),
    );
    yield put(updateAccountEmailActions.error());
  }
}

export function* confirmAccountEmailData({ payload }) {
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.confirmIpAccountEmail, payload),
    );
    const accountResponse = response.data.confirmIPAccountEmail;
    const normalizedData = normalizeAccount(accountResponse);

    yield put(
      confirmAcountEmailActions.success({
        account: normalizedData,
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line

    yield put(
      NotificationActions.showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.message
        }`,
      }),
    );
    yield put(confirmAcountEmailActions.error());
  }
}

export function* confirmAccountEmailPinData({ payload }) {
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.confirmIpAccountEmailPin, payload),
    );
    const accountResponse = response.data.confirmIPAccountEmailPin;
    const normalizedData = normalizeAccount(accountResponse);

    yield put(
      confirmAcountEmailPinActions.success({
        account: normalizedData,
      }),
    );

    yield call(verifyBoardingStep);
  } catch (error) {
    console.error(error); // eslint-disable-line

    yield put(
      NotificationActions.showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.message
        }`,
      }),
    );
    yield put(confirmAcountEmailPinActions.error());
  }
}

export function* updateAccountMobileData({ payload }) {
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.updateAccountMobile, payload),
    );
    const accountResponse = response.data.updateAccountMobile;
    const normalizedData = normalizeAccount(accountResponse);

    // if (normalizedData.emailStatus === 'CONFIRMED') {
    //   normalizedData.emailStatus = 'UPDATED';
    // }

    yield put(
      updateAccountMobileActions.success({
        account: normalizedData,
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line

    yield put(
      NotificationActions.showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.message
        }`,
      }),
    );
    yield put(updateAccountMobileActions.error());
  }
}

export function* confirmAccountMobileData({ payload }) {
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.confirmAccountMobile, payload),
    );
    const accountResponse = response.data.confirmAccountMobile;
    const normalizedData = normalizeAccount(accountResponse);

    yield put(
      confirmAcountMobileActions.success({
        account: normalizedData,
      }),
    );
    // yield call(fetchAccountData);
    yield call(verifyBoardingStep);
  } catch (error) {
    console.error(error); // eslint-disable-line

    yield put(
      NotificationActions.showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.message
        }`,
      }),
    );
    yield put(confirmAcountMobileActions.error());
  }
}

export function* setMainBlockchainAccountData({ payload }) {
  try {
    yield call([API, 'graphql'], graphqlOperation(mutations.setIpMainBlockchainWallet, payload));
    // const functionResult = response.data.setIPMainBlockchainWallet;
    yield put(setMainBlockchainWalletActions.success());

    yield put(fetchAccountActions.pending({ silent: true }));

    // const params = {
    //   accountId: {
    //     eq: localStorage.getItem('activeAccount'),
    //   },
    // };
    // const responseAccount = yield call(
    //   [API, 'graphql'],
    //   graphqlOperation(queries.readAccount, params),
    // );
    // const account = responseAccount.myPortal.accounts.items[0].account;
    // const normalizedData = normalizeAccount(account);
  } catch (error) {
    console.error(error); // eslint-disable-line

    yield put(
      NotificationActions.showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.message
        }`,
      }),
    );
    yield put(setMainBlockchainWalletActions.error());
  }
}

export function* confirmLinkAccountData({ payload }) {
  const accountId = ACCOUNTID_TEMP;
  payload.accountId = accountId;

  try {
    yield call([API, 'graphql'], graphqlOperation(mutations.confirmIpLinkAccount, payload));

    yield put(confirmLinkPaymentAccountsActions.success({}));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(
      NotificationActions.showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.message
        }`,
      }),
    );
  }
}

export function* addBlockchainWalletData({ payload }) {
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.addIPBlockchainWallet, payload),
    );

    yield put(
      addBlockchainWalletActions.success({
        blockchainWallet: response.data.addIPBlockchainWallet,
      }),
    );
    yield put(setWalletAddStepActions(2));
    yield put(fetchAccountActions.pending({ silent: true }));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(
      NotificationActions.showError({
        title: 'view_account:Notification.Error.Title',
        message: `view_account:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.message
        }`,
      }),
    );

    yield put(addBlockchainWalletActions.error());
  }
}

export function* checkSignatureBlockchainWalletData({ payload }) {
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.checkIPMetamaskSignature, payload),
    );

    const accountResponse = response.data.checkIPMetamaskSignature.account;
    const normalizedData = normalizeAccount(accountResponse);

    yield put(
      checkSignatureBlockchainWalletActions.success({
        blockchainWallet: response.data.checkIPMetamaskSignature,
        account: normalizedData,
      }),
    );

    yield call(verifyBoardingStep);
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(checkSignatureBlockchainWalletActions.error());
  }
}

export function* transferTokenFromBlockchainWalletData({ payload }) {
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.transferIpToken, payload),
    );

    yield put(
      transferTokenFromBlockchainWalletActions.success({
        data: response.data.transferIPToken,
      }),
    );

    yield put(setWalletTransferStepActions(3));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(transferTokenFromBlockchainWalletActions.error());
  }
}

export function* listTokenOnMarketData({ payload }) {
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.listTokenOnMarket, payload),
    );

    yield put(
      listTokenOnMarketActions.success({
        data: response.data.listTokenOnMarket,
      }),
    );

    yield put(setWalletListStepActions(3));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(listTokenOnMarketActions.error());
  }
}

export function* changeProfilePassword({ payload }) {
  try {
    const currentUser = yield call([Auth, 'currentAuthenticatedUser']);
    yield call([Auth, 'changePassword'], currentUser, payload.oldPassword, payload.newPassword);
    yield put(changeProfilePasswordAction.success({}));
    yield put(
      showSuccess({
        title: 'view_profile:Notification.Updated.Title',
        message: 'view_profile:Notification.Updated.Message',
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(changeProfilePasswordAction.error());
    yield put(
      showError({
        title: 'view_profile:Notification.Error.Title',
        message: `view_profile:Notification.Error.${error.code}`,
      }),
    );
  }
}

export default function* appSeetingsSagas() {
  yield all([
    takeLatest(actionsTypes.FETCH_ACCOUNT.PENDING, fetchAccountData),
    takeLatest(actionsTypes.FETCH_ACCOUNT_PORTFOLIO.PENDING, fetchPortfolioData),
    takeLatest(actionsTypes.FETCH_ACCOUNT_ASSET.PENDING, fetchAssetData),
    takeLatest(actionsTypes.FETCH_ACCOUNT_ORDERS.PENDING, fetchOrderData),
    takeLatest(actionsTypes.FETCH_ACCOUNT_TRANSFERS.PENDING, fetchTransfersData),
    takeLatest(actionsTypes.FETCH_ACCOUNT_TRANSFER.PENDING, fetchTransferDetails),
    takeLatest(actionsTypes.FETCH_ORDER_DETAILS.PENDING, fetchOrderDetails),
    takeLatest(actionsTypes.FETCH_WALLET.PENDING, fetchBlockchainWalletData),
    takeLatest(actionsTypes.FETCH_ACCOUNT_OPENORDERS.PENDING, fetchOpenOrderData),
    takeLatest(actionsTypes.FETCH_GUBBI_KYC.PENDING, fetchGubbiDetailsData),
    takeLatest(actionsTypes.FETCH_ACCOUNT_PAYMENTS.PENDING, fetchPaymentsData),
    takeLatest(actionsTypes.GET_SIGND_DETAILS.PENDING, fetchSignDDetailsSaga),
    takeLatest(actionsTypes.CONFIRM_SIGND.PENDING, confirmSignDDetailsSaga),
    takeLatest(actionsTypes.FETCH_SUMSUB_KYC.PENDING, fetchSumsubDetailsSaga),
    takeLatest(actionsTypes.CHANGE_PROFILE_PASSWORD.PENDING, changeProfilePassword),
    takeLatest(actionsTypes.ADD_ACCOUNT.PENDING, addAccountData),
    takeLatest(actionsTypes.SET_MAINBLOCKCHAINWALLET.PENDING, setMainBlockchainAccountData),
    takeLatest(actionsTypes.UPDATE_ACCOUNT.PENDING, updateAccountData),
    takeLatest(actionsTypes.SET_ACCOUNT_TYPE.PENDING, setAccountTypeData),
    takeLatest(actionsTypes.UPDATE_AML.PENDING, updateAccountAMLData),
    takeLatest(actionsTypes.UPDATE_INVESTOR_TYPE.PENDING, updateInvestorTypeData),
    takeLatest(actionsTypes.UPDATE_INVESTMENTOBJECTIVES.PENDING, updateInvestmentObjectivesData),
    takeLatest(actionsTypes.UPDATE_FINANCIALSITUATION.PENDING, updateFinancialSituationData),
    takeLatest(actionsTypes.UPDATE_KNOWLEDGE.PENDING, updateKnowledgeData),
    takeLatest(actionsTypes.UPDATE_REFERENCEBANK.PENDING, updateReferenceBankData),
    takeLatest(actionsTypes.UPDATE_ACCOUNT_EMAIL.PENDING, updateAccountEmailData),
    takeLatest(actionsTypes.UPDATE_ACCOUNT_MOBILE.PENDING, updateAccountMobileData),
    takeLatest(actionsTypes.UPDATE_ACCOUNT_PAPERWALLET.PENDING, updateAccountPaperWalletData),
    takeLatest(actionsTypes.CONFIRM_UPDATE_EMAIL_ACCOUNT.PENDING, confirmAccountEmailData),
    takeLatest(actionsTypes.CONFIRM_UPDATE_EMAIL_PIN_ACCOUNT.PENDING, confirmAccountEmailPinData),
    takeLatest(actionsTypes.CONFIRM_UPDATE_MOBILE_ACCOUNT.PENDING, confirmAccountMobileData),
    takeLatest(actionsTypes.CONFIRM_LINK_PAYMENT_ACCOUNT.PENDING, confirmLinkAccountData),
    takeLatest(actionsTypes.ADD_BLOCKCHAINWALLET.PENDING, addBlockchainWalletData),
    takeLatest(
      actionsTypes.TRANSFER_TOKEN_FROM_BLOCKCHAINWALLET.PENDING,
      transferTokenFromBlockchainWalletData,
    ),
    takeLatest(actionsTypes.LIST_TOKENONMARKET.PENDING, listTokenOnMarketData),
    takeLatest(
      actionsTypes.CHECKSIGNATURE_BLOCKCHAINWALLET.PENDING,
      checkSignatureBlockchainWalletData,
    ),
  ]);
}
