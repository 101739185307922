import { all, takeLatest, put, call } from 'redux-saga/effects';
import { pageContentUrl } from 'src/utils/common';
import i18n from 'src/i18n';
import ENV_CONFIG from 'src/constants/Config';
import { actionsTypes } from './constants';
import { fetchPageAction, fetchPageLayoutAction } from './page.action';

export function* fetchPageData({ payload }) {
  const language = i18n.language || 'en';

  try {
    const responseHTML = yield call(
      fetch,
      `${pageContentUrl(ENV_CONFIG.PORTAL_ID, payload, language)}.html`,
    );
    const html = yield call([responseHTML, 'text']);
    const responseCSS = yield call(
      fetch,
      `${pageContentUrl(ENV_CONFIG.PORTAL_ID, payload, language)}.css`,
    );
    const css = yield call([responseCSS, 'text']);
    let data = null;
    if (responseHTML.status < 400) {
      data = `<style>${css}</style>${html}`;
    }
    yield put(fetchPageAction.success({ data }));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchPageAction.error());
  }
}

export function* fetchPageLayoutData({ payload }) {
  const language = i18n.language || 'de';

  try {
    const headerHTML = yield call(
      fetch,
      `${pageContentUrl(ENV_CONFIG.PORTAL_ID, 'header', language)}.html`,
    );
    const header = yield call([headerHTML, 'text']);

    let layout = {};
    if (headerHTML.status >= 400) {
      yield put(fetchPageLayoutAction.error());
      return;
    }
    const headerCSS = yield call(
      fetch,
      `${pageContentUrl(ENV_CONFIG.PORTAL_ID, 'header', language)}.css`,
    );
    const headerCss = yield call([headerCSS, 'text']);
    layout.headerData = `<style>${headerCss}</style>${header}`;

    const footerHTML = yield call(
      fetch,
      `${pageContentUrl(ENV_CONFIG.PORTAL_ID, 'footer', language)}.html`,
    );
    const footer = yield call([footerHTML, 'text']);

    if (footerHTML.status >= 400) {
      yield put(fetchPageLayoutAction.error());
      return;
    }
    const footerCSS = yield call(
      fetch,
      `${pageContentUrl(ENV_CONFIG.PORTAL_ID, 'footer', language)}.css`,
    );
    const footerCss = yield call([footerCSS, 'text']);
    layout.footerData = `<style>${footerCss}</style>${footer}`;

    yield put(fetchPageLayoutAction.success({ data: layout }));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchPageLayoutAction.error());
  }
}

export default function* newsAndEventsSaga() {
  yield all([takeLatest(actionsTypes.FETCH_PAGE.PENDING, fetchPageData)]);
  yield all([takeLatest(actionsTypes.FETCH_LAYOUT.PENDING, fetchPageLayoutData)]);
}
