export const listActivities = /* GraphQL */ `
  query Query(
    $tenantId: ID!
    $filter: ModelActivityFilterInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listActivities(
      tenantId: $tenantId
      filter: $filter
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        tenantId
        id
        name
        publishingDate
        activityType
        status
        title
        slug
        description
        content
        featured
        hasOverviewImage
      }
    }
  }
`;

export const getActivity = /* GraphQL */ `
  query Query(
    $slug: ModelStringKeyConditionInput
    $filter: ModelActivityFilterInput
    $tenantId: ID!
  ) {
    activityBySlug(slug: $slug, filter: $filter, tenantId: $tenantId) {
      items {
        tenantId
        id
        name
        publishingDate
        activityType
        status
        title
        slug
        description
        content
      }
    }
  }
`;
