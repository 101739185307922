import { all, takeLatest, takeEvery, put, call } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
import { actionsTypes } from './constants';
import {
  fetchDetailsAction,
  fetchFeaturedNewsAndEventsActions,
  fetchNewsAndEventsActions,
  fetchOtherNewsAndEventsActions,
} from './news.action';
import { getAllNewsAndEvents, getNewsAndEventDetails } from './GraphQL/queries';

export function* fetchNewsAndEventsData({ payload }) {
  try {
    const params = {
      // sortDirection: 'DESC', // todo need to check why it's sending empty limit when it's uncommented
      // filter: {
      //   // status: {
      //   //   eq: 'PUBLIC',
      //   // },
      //   ...payload.filter,
      // },
      limit: payload.limit || 10,
    };

    const response = yield call([API, 'graphql'], graphqlOperation(getAllNewsAndEvents, params));
    const newsAndEvents = response.data.myPortal.activities.items;

    yield put(fetchNewsAndEventsActions.success({ newsAndEvents }));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchNewsAndEventsActions.error());
  }
}

export function* fetchFeaturedNewsAndEventsData({ payload }) {
  try {
    const params = {
      sortDirection: 'DESC',
      filter: {
        featured: {
          eq: 'true',
        },
        // status: {
        //   eq: 'PUBLIC',
        // },
      },
      limit: payload.limit,
    };

    const response = yield call([API, 'graphql'], graphqlOperation(getAllNewsAndEvents, params));
    const newsAndEvents = response.data.myPortal.activities.items;

    yield put(fetchFeaturedNewsAndEventsActions.success({ newsAndEvents }));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchFeaturedNewsAndEventsActions.error());
  }
}
export function* fetchOthersNewsAndEventsData({ payload }) {
  try {
    const params = {
      sortDirection: 'DESC',
      filter: {
        or: {
          activityType: {
            eq: 'POST',
          },
        },
      },
      //   // ...payload.filter,
      // },
      limit: 100,
    };

    // Load 20 last POST or Events
    let countActivities = 0;
    let activityData = [];
    let nextToken = undefined;
    while (countActivities < 20 && nextToken !== null) {
      params['nextToken'] = nextToken;
      const response = yield call([API, 'graphql'], graphqlOperation(getAllNewsAndEvents, params));
      const _activityData = response.data.myPortal.activities.items.slice(0, payload.limit);
      const _nextToken = response.data.myPortal.activities.nextToken;
      nextToken = _nextToken;
      activityData = [...activityData, ..._activityData];
      countActivities = activityData.length;
      yield put(fetchOtherNewsAndEventsActions.success({ newsAndEvents: activityData }));
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchOtherNewsAndEventsActions.error());
  }
}
export function* fetchNewsAndEventDetails({ payload }) {
  const { id } = payload;
  let params = {};
  try {
    params = {
      slug: {
        eq: id,
      },
    };

    const response = yield call([API, 'graphql'], graphqlOperation(getNewsAndEventDetails, params));
    const newsAndEvent = response.data.myPortal.activityBySlug.items[0];

    yield put(fetchDetailsAction.success({ newsAndEvent }));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchDetailsAction.error());
  }
}

export default function* newsAndEventsSaga() {
  yield all([
    takeEvery(actionsTypes.FETCH_ALL_NEWS_AND_EVENTS.PENDING, fetchNewsAndEventsData),
    takeEvery(actionsTypes.FETCH_FEATURED_NEWS_AND_EVENTS.PENDING, fetchFeaturedNewsAndEventsData),
    takeEvery(actionsTypes.FETCH_OTHER_NEWS_AND_EVENTS.PENDING, fetchOthersNewsAndEventsData),
    takeLatest(actionsTypes.FETCH_DETAILS_NEWS_AND_EVENT.PENDING, fetchNewsAndEventDetails),
  ]);
}
