import { all, takeEvery, put, call } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
import { actionsTypes } from './constants';
import { fetchActionActivitiesActions } from './actions.action';
import { getAllActions } from './GraphQL/queries';

export function* fetchActionsData({ payload }) {
  try {
    const params = {
      // sortDirection: 'DESC',
      filter: {
        // status: {
        //   eq: 'PUBLIC',
        // },
        activityType: {
          eq: 'ACTION',
        },
        // ...payload.filter,
      },
      limit: payload.limit || 10,
    };

    const response = yield call([API, 'graphql'], graphqlOperation(getAllActions, params));
    const actions = response.data.portal.me.activities.items;

    yield put(fetchActionActivitiesActions.success({ actions }));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchActionActivitiesActions.error());
  }
}

export default function* newsAndEventsSaga() {
  yield all([takeEvery(actionsTypes.FETCH_ALL_ACTIONS.PENDING, fetchActionsData)]);
}
