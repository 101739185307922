import { fromJS } from 'immutable';
import { STATUS } from 'src/constants/Status';
import { actionsTypes } from './constants';

const initialState = fromJS({
  status: null,
  data: [],
  limit: 100,
  message: {
    status: STATUS.LOADING,
    data: {},
  },
});

const offeringReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionsTypes.FETCH_MESSAGES.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.FETCH_MESSAGES.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .update('data', value => value.concat(payload.data))
        .set('nextToken', payload.nextToken || '');
    case actionsTypes.FETCH_MESSAGES.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.FETCH_MESSAGE.PENDING:
      return state.setIn(['message', 'status'], STATUS.LOADING);
    case actionsTypes.FETCH_MESSAGE.SUCCESS:
      return state
        .setIn(['message', 'status'], STATUS.SUCCESS)
        .setIn(['message', 'data'], payload.data);
    case actionsTypes.FETCH_MESSAGE.ERROR:
      return state.setIn(['layout', 'status'], STATUS.ERROR);

    case actionsTypes.SEND_MESSAGE.PENDING:
      return state.setIn(['message', 'status'], STATUS.ADDING);
    case actionsTypes.SEND_MESSAGE.SUCCESS:
      return state
        .setIn(['message', 'status'], STATUS.SUCCESS)
        .setIn(['message', 'data'], payload.data);
    case actionsTypes.SEND_MESSAGE.ERROR:
      return state.setIn(['layout', 'status'], STATUS.ERROR);

    case actionsTypes.CLEAR_VALUES:
      return initialState;

    default:
      return state;
  }
};
export default offeringReducer;
