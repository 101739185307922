import { fromJS } from 'immutable';
import { STATUS } from 'src/constants/Status';
import { actionsTypes } from './constants';

const initialState = fromJS({
  status: STATUS.LOADING,

  orders: {
    status: STATUS.LOADING,
    limit: 100,
    nextToken: null,
    data: [],
  },

  signups: {
    status: STATUS.LOADING,
    limit: 100,
    nextToken: null,
    data: [],
  },

  affiliatedAccount: {
    status: STATUS.LOADING,
    data: {},
  },
});

const accountReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionsTypes.FETCH_AFFILIATE.PENDING:
      return state.set(
        'status',
        payload !== undefined && payload.silent ? STATUS.SUCCESS : STATUS.LOADING,
      );
    case actionsTypes.FETCH_AFFILIATE.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .set('affiliate', payload.affiliate || {})
        .set('account', payload.account || {});
    case actionsTypes.FETCH_AFFILIATE.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.FETCH_ORDERS.PENDING:
      return state.setIn(['orders', 'status'], STATUS.LOADING);
    case actionsTypes.FETCH_ORDERS.SUCCESS:
      return state
        .setIn(['orders', 'status'], STATUS.SUCCESS)
        .updateIn(['orders', 'data'], value => value.concat(payload.data))
        .setIn(['orders', 'nextToken'], payload.nextToken);
    case actionsTypes.FETCH_ORDERS.ERROR:
      return state.set(['orders', 'status'], STATUS.ERROR);

    case actionsTypes.FETCH_SIGNUPS.PENDING:
      return state.setIn(['signups', 'status'], STATUS.LOADING);
    case actionsTypes.FETCH_SIGNUPS.SUCCESS:
      return state
        .setIn(['signups', 'status'], STATUS.SUCCESS)
        .updateIn(['signups', 'data'], value => value.concat(payload.data))
        .setIn(['signups', 'nextToken'], payload.nextToken);
    case actionsTypes.FETCH_SIGNUPS.ERROR:
      return state.set(['signups', 'status'], STATUS.ERROR);

    case actionsTypes.FETCH_AFFILIATEDACCOUNT.PENDING:
      return state.setIn(['affiliatedAccount', 'status'], STATUS.LOADING);
    case actionsTypes.FETCH_AFFILIATEDACCOUNT.SUCCESS:
      return state
        .setIn(['affiliatedAccount', 'status'], STATUS.SUCCESS)
        .updateIn(['affiliatedAccount', 'data'], value => value.concat(payload.data))
        .setIn(['affiliatedAccount', 'nextToken'], payload.nextToken);
    case actionsTypes.FETCH_AFFILIATEDACCOUNT.ERROR:
      return state.set(['affiliatedAccount', 'status'], STATUS.ERROR);

    case actionsTypes.ACTIVATE_AFFILIATE.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.ACTIVATE_AFFILIATE.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .set('account', payload.account || {})
        .set('affiliate', payload.affiliate || {});
    case actionsTypes.ACTIVATE_AFFILIATE.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.CLEAR_VALUES:
      return initialState;

    case actionsTypes.CLEAR_ORDERS:
      return state
        .setIn(['orders', 'status'], STATUS.LOADING)
        .setIn(['orders', 'data'], [])
        .setIn(['orders', 'nextToken'], null);

    case actionsTypes.CLEAR_SIGNUPS:
      return state
        .setIn(['signups', 'status'], STATUS.LOADING)
        .setIn(['signups', 'data'], [])
        .setIn(['signups', 'nextToken'], null);

    default:
      return state;
  }
};
export default accountReducer;
