export const buyTokenFromMarket = /* GraphQL */ `
  mutation BuyTokenFromMarket($accountId: ID, $input: BlockchainWalletBuyInput) {
    buyTokenFromMarket(accountId: $accountId, input: $input) {
      tenantId
      transactionId
      amount
      status
      message
      transactionData {
        txTo
        txValue
      }
    }
  }
`;
