import { isEmpty } from 'lodash';
import {
  ECSP_STATUS,
  EMAIL_STATUS,
  MOBILE_STATUS,
  FINANCIAL_SITUATION_STATUS,
  INVESTMENT_OBJECTIVES_STATUS,
  INVESTOR_PROFILE_STATUS,
  KNOWLEDGE_STATUS,
  KYC_STATUS,
  PERSONAL_STATUS,
  REFERENCEBANK_STATUS,
  AML_STATUS,
} from 'src/constants/Status';
import { ON_BOARDING_STEPS } from 'src/views/Store/constants';

export const normalizeAccount = (account, offering = {}) => {
  const individual = account.individual;
  const address = account.address;
  const company = account.company || {};
  return {
    ...company,
    accountType: account.accountType,
    id: account.id,
    name: account.name,
    country: account.address?.country,
    status: account.status,
    kycStatus: account.kycStatus,
    individualCompanyStatus: account.individualCompanyStatus,
    individualCompanyMessage: account.individualCompanyMessage,

    emailStatus: account.emailStatus,
    email: account.email,
    mobileStatus: account.mobileStatus,
    mobile: account.mobile,

    message: account.message,

    investmentObjectivesStatus: account.investmentObjectivesStatus,
    investmentObjectivesMessage: account.investmentObjectivesMessage,
    riskTolerance: account?.investmentObjectives?.riskTolerance,
    timeHorizon: account?.investmentObjectives?.timeHorizon,
    investmentReason: account?.investmentObjectives?.investmentReason,

    financialSituationStatus: account?.financialSituationStatus,
    financialSituationMessage: account?.financialSituationMessage,
    monthlyNetIncome: account?.financialSituation?.monthlyNetIncome,
    monthlyExpenses: account?.financialSituation?.monthlyExpenses,
    liquidAssets: account?.financialSituation?.liquidAssets,
    illiquidAssets: account?.financialSituation?.illiquidAssets,
    liabilities: account?.financialSituation?.liabilities,
    financialReserves: account?.financialSituation?.financialReserves,

    disclaimerFinancialReserve: account?.financialSituation?.disclaimerFinancialReserve,
    disclaimerLongterm: account?.financialSituation?.disclaimerLongterm,
    disclaimerFlexibility: account?.financialSituation?.disclaimerFlexibility,
    disclaimerLifeStandard: account?.financialSituation?.disclaimerLifeStandard,
    disclaimerFluctuations: account?.financialSituation?.disclaimerFluctuations,

    allowAffiliate: account.tenant?.allowAffiliate,
    showAffiliate: account.tenant?.showAffiliate,
    allowReferAFriend: account.tenant?.allowReferAFriend,
    showReferAFriend: account.tenant?.showReferAFriend,
    isReferral: account.isReferral,
    isAffiliate: account.isAffiliate,
    hasReferral: account.hasReferral,

    amlStatus: account.amlStatus,
    amlMessage: account.amlMessage,
    sourceOfFunds: account?.aml?.sourceOfFunds,
    sourceOfFundsOthers: account?.aml?.sourceOfFundsOthers,
    sourceOfWealth: account?.aml?.sourceOfWealth,
    sourceOfWealthOthers: account?.aml?.sourceOfWealthOthers,
    pep: account?.aml?.pep,
    pepRelatedPerson: account?.aml?.pepRelatedPerson,
    pepStatusDescription: account?.aml?.pepStatusDescription,

    referenceBankAccountStatus: account.referenceBankAccountStatus,
    referenceBankAccountMessage: account.referenceBankAccountMessage,
    iban: account?.referenceBankAccount?.iban,
    bic: account?.referenceBankAccount?.bic,
    bankAccountOwner: account?.referenceBankAccount?.owner,
    bankName: account?.referenceBankAccount?.bankName,

    knowledgeAndExperienceStatus: account.knowledgeAndExperienceStatus,
    knowledgeAndExperienceMessage: account.knowledgeAndExperienceMessage,
    knowHow1: account?.knowledgeAndExperience?.knowHow1,
    knowHow2: account?.knowledgeAndExperience?.knowHow2,
    knowHow3: account?.knowledgeAndExperience?.knowHow3,
    knowHow4: account?.knowledgeAndExperience?.knowHow4,
    knowHow5: account?.knowledgeAndExperience?.knowHow5,

    vatPhysical: account.vatPhysical,
    vatDigital: account.vatDigital,
    requireInvestorType: account.tenant?.requireInvestorType,
    requireAML: account.tenant?.requireAML,
    requireECSPProfile: account.tenant?.requireECSPProfile,
    requireInvestmentObjective: account.tenant?.requireInvestmentObjective,
    requireFinancialSituation: account.tenant?.requireFinancialSituation,
    requireKnowledge: account.tenant?.requireKnowledge,
    requireReferenceBank: account.tenant?.requireReferenceBank,
    requireEmail: account.tenant?.requireEmail,
    requireMobile: account.tenant?.requireMobile,
    requireFullRegistration: account.tenant?.requireFullRegistration,

    kycProvider: account.tenant?.kycProvider,
    walletProvider: account.tenant?.walletProvider,

    confirmedTerms1: account.confirmedTerms1,
    confirmedTerms2: account.confirmedTerms2,
    confirmedTerms3: account.confirmedTerms3,
    confirmedTerms4: account.confirmedTerms4,
    confirmedTerms5: account.confirmedTerms5,

    requireTerms1: account.tenant?.requireTerms1,
    requireTerms2: account.tenant?.requireTerms2,
    requireTerms3: account.tenant?.requireTerms3,
    requireTerms4: account.tenant?.requireTerms4,
    requireTerms5: account.tenant?.requireTerms5,

    requireWallet: account.tenant?.requireWallet,
    requireVAT: account.tenant?.requireVAT,
    requireIdentificationNr: account.tenant?.requireIdentificationNr,
    requireWebsite: account.tenant?.requireWebsite,
    requireTIN: account.tenant?.requireTIN,
    requireOccupation: account.tenant?.requireOccupation,
    requireEducation: account.tenant?.requireEducation,

    metamaskLogin: account.tenant?.requireEducation,
    onboardingWelcome: account.tenant?.onboardingWelcome || null,
    autoWalletMessage: account.tenant?.autoWalletMessage || null,

    terms: account.tenant?.terms,
    mainBlockchainWalletId: account.mainBlockchainWalletId,
    publicAddress: account.mainBlockchainWallet?.publicAddress,
    blockchainProvider: account.mainBlockchainWallet?.blockchainProvider,
    createPaperWallet: account.createPaperWallet,
    investorProfileStatus: account.investorProfileStatus,
    ecspStatus: account.ecspStatus,
    chain: offering.chain,
    noKYC: offering.noKYC || false,
    emailRequired: true,
    ...individual,
    ...address,
  };
};

export const checkReferAFriendStatus = account => {
  let isCompleted = true;
  if (
    ((['ALL', 'ONLY_REFERRAL'].includes(account.showReferAFriend) && account.hasReferral) ||
      (['ALL', 'ONLY_NO_REFERRAL'].includes(account.showReferAFriend) && !account.hasReferral)) &&
    account.allowReferAFriend &&
    account.isReferral === null
  ) {
    isCompleted = false;
    return { ...ON_BOARDING_STEPS.REFERRAL, isCompleted };
  } else {
    return null;
  }
};

export const checkAffiliateStatus = account => {
  let isCompleted = true;
  if (
    ((['ALL', 'ONLY_REFERRAL'].includes(account.showAffiliate) && account.hasReferral) ||
      (['ALL', 'ONLY_NO_REFERRAL'].includes(account.showAffiliate) && !account.hasReferral)) &&
    account.allowAffiliate &&
    account.isAffiliate === null
  ) {
    isCompleted = false;
    return { ...ON_BOARDING_STEPS.AFFILIATE, isCompleted };
  } else {
    return null;
  }
};

export const checkEmailBoardingStatus = account => {
  let isCompleted = true;
  if (account.requireEmail) {
    isCompleted = account.email && account.emailStatus === EMAIL_STATUS.CONFIRMED;
    return { ...ON_BOARDING_STEPS.EMAIL, isCompleted };
  } else {
    return null;
  }
};

export const checkAccountType = account => {
  let isCompleted =
    account.accountType === 'INDIVIDUAL' || account.accountType === 'COMPANY' ? true : false;

  return { ...ON_BOARDING_STEPS.ACCOUNTTYPE, isCompleted };
};

export const checkMobileBoardingStatus = account => {
  let isCompleted = true;
  if (account.requireMobile) {
    isCompleted = account.mobile && account.mobileStatus === MOBILE_STATUS.CONFIRMED;
    return { ...ON_BOARDING_STEPS.MOBILE, isCompleted };
  } else {
    return null;
  }
};

export const checkPersonalBoardingStatus = account => {
  let isCompleted =
    account.noKYC ||
    account.individualCompanyStatus === PERSONAL_STATUS.CLOSED_CONFIRMED ||
    account.accountType === 'COMPANY';

  let termsMissing = false;
  if (account.accountType === 'INDIVIDUAL') {
    if (account.requireTerms1 && !account.confirmedTerms1) {
      isCompleted = false;
      termsMissing = true;
    }
    if (account.requireTerms2 && !account.confirmedTerms2) {
      isCompleted = false;
      termsMissing = true;
    }
    if (account.requireTerms3 && !account.confirmedTerms3) {
      isCompleted = false;
      termsMissing = true;
    }
    if (account.requireTerms4 && !account.confirmedTerms4) {
      isCompleted = false;
      termsMissing = true;
    }
    if (account.requireTerms5 && !account.confirmedTerms5) {
      isCompleted = false;
      termsMissing = true;
    }
  }

  return {
    ...ON_BOARDING_STEPS.PERSONAL,
    type: account.kycProvider,
    isCompleted,
    termsMissing,
  };
};

export const checkCompanyBoardingStatus = account => {
  let isCompleted =
    account.noKYC ||
    account.individualCompanyStatus === PERSONAL_STATUS.CLOSED_CONFIRMED ||
    account.accountType === 'INDIVIDUAL';

  let termsMissing = false;
  if (account.accountType === 'COMPANY') {
    if (account.requireTerms1 && !account.confirmedTerms1) {
      isCompleted = false;
      termsMissing = true;
    }
    if (account.requireTerms2 && !account.confirmedTerms2) {
      isCompleted = false;
      termsMissing = true;
    }
    if (account.requireTerms3 && !account.confirmedTerms3) {
      isCompleted = false;
      termsMissing = true;
    }
    if (account.requireTerms4 && !account.confirmedTerms4) {
      isCompleted = false;
      termsMissing = true;
    }
    if (account.requireTerms5 && !account.confirmedTerms5) {
      isCompleted = false;
      termsMissing = true;
    }
  }

  return {
    ...ON_BOARDING_STEPS.COMPANY,
    type: account.kycProvider,
    isCompleted,
    termsMissing,
  };
};

export const checkIdentityBoardingStatus = account => {
  const isCompleted = account.noKYC || account.kycStatus === KYC_STATUS.CLOSED_CONFIRMED;
  let redirectTo = '/account/edit';
  switch (account.kycProvider) {
    case 'SIGND':
      redirectTo = '/account/signd';
      break;
    case 'GUBBI':
      redirectTo = '/account/gubbiKYC';
      break;
    case 'SUMSUB':
      redirectTo = '/account/sumsub';
      break;
    default:
  }

  if (
    account.kycProvider &&
    (account.kycProvider === 'SIGND' ||
      account.kycProvider === 'GUBBI' ||
      account.kycProvider === 'SUMSUB')
  ) {
    return {
      ...ON_BOARDING_STEPS.IDENTITY,
      type: account.kycProvider,
      redirectTo,
      isCompleted,
    };
  } else {
    return null;
  }
};

export const checkWalletBoardingStatus = account => {
  const isCompleted = !!(
    account.mainBlockchainWalletId ||
    account.createPaperWallet ||
    account.chain === 'NOBLOCKCHAIN' ||
    !account.requireWallet
  );

  if (isEmpty(account.walletProvider)) {
    return { ...ON_BOARDING_STEPS.WALLET, isCompleted, redirectTo: '/account/wallet/type' };
  } else if (account.walletProvider === 'SIMPLYTOKENIZED') {
    return { ...ON_BOARDING_STEPS.WALLET, isCompleted, redirectTo: '/account/wallet/add' };
  } else if (account.walletProvider !== 'GUBBI') {
    return { ...ON_BOARDING_STEPS.WALLET, isCompleted };
  } else {
    return null;
  }
};

export const checkInvestorTypeBoardingStatus = account => {
  let isCompleted = true;

  if (account.requireInvestorType) {
    isCompleted =
      account.noKYC || account.investorProfileStatus === INVESTOR_PROFILE_STATUS.CONFIRMED;
    return { ...ON_BOARDING_STEPS.AML, isCompleted };
  } else {
    return null;
  }
};

export const checkAMLBoardingStatus = account => {
  let isCompleted = true;

  if (account.requireAML) {
    isCompleted = account.noKYC || account.amlStatus === AML_STATUS.CLOSED_CONFIRMED;
    return { ...ON_BOARDING_STEPS.AML, isCompleted };
  } else {
    return null;
  }
};

export const checkInvestmentObjectiveBoardingStatus = account => {
  let isCompleted = true;

  if (account.requireInvestmentObjective) {
    isCompleted =
      account.noKYC ||
      account.investmentObjectivesStatus === INVESTMENT_OBJECTIVES_STATUS.CLOSED_CONFIRMED;

    return { ...ON_BOARDING_STEPS.INVESTMENT_OBJECTIVES, isCompleted };
  } else {
    return null;
  }
};

export const checkFinancialSituationBoardingStatus = account => {
  let isCompleted = true;

  if (account.requireFinancialSituation) {
    isCompleted =
      account.noKYC ||
      account.financialSituationStatus === FINANCIAL_SITUATION_STATUS.CLOSED_CONFIRMED;

    return { ...ON_BOARDING_STEPS.FINANCIAL_SITUATION, isCompleted };
  } else {
    return null;
  }
};

export const checkKnowledgeBoardingStatus = account => {
  let isCompleted = true;

  if (account.requireKnowledge) {
    isCompleted =
      account.noKYC || account.knowledgeAndExperienceStatus === KNOWLEDGE_STATUS.CLOSED_CONFIRMED;

    return { ...ON_BOARDING_STEPS.KNOWLEDGE, isCompleted };
  } else {
    return null;
  }
};

export const checkReferenceBankBoardingStatus = account => {
  let isCompleted = true;

  if (account.requireReferenceBank) {
    isCompleted =
      account.noKYC || account.referenceBankAccountStatus === REFERENCEBANK_STATUS.CLOSED_CONFIRMED;

    return { ...ON_BOARDING_STEPS.REFERENCE_BANK, isCompleted };
  } else {
    return null;
  }
};

export const checkECSPBoardingStatus = account => {
  let isCompleted = true;

  if (account.requireECSPProfile) {
    isCompleted = account.noKYC || account.ecspStatus === ECSP_STATUS.CONFIRMED;
    return { ...ON_BOARDING_STEPS.ECSP, isCompleted };
  } else {
    return null;
  }
};

function custom_sort_desc(a, b) {
  return a.order - b.order;
}

export const getBoardingStepsNormalizer = normalizedData => {
  const accountType = checkAccountType(normalizedData);
  const emailBoarding = checkEmailBoardingStatus(normalizedData);
  const mobileBoarding = checkMobileBoardingStatus(normalizedData);
  const personalBoarding = checkPersonalBoardingStatus(normalizedData);
  const companyBoarding = checkCompanyBoardingStatus(normalizedData);
  const walletBoarding = checkWalletBoardingStatus(normalizedData);
  // const investorTypeBoarding = checkInvestorTypeBoardingStatus(normalizedData);
  const ECSPBoardingStatus = checkECSPBoardingStatus(normalizedData);
  const investmentObjectivesStatus = checkInvestmentObjectiveBoardingStatus(normalizedData);
  const financialSituationStatus = checkFinancialSituationBoardingStatus(normalizedData);
  const knowledgeStatus = checkKnowledgeBoardingStatus(normalizedData);
  const referenceBankAccountStatus = checkReferenceBankBoardingStatus(normalizedData);
  const amlStatus = checkAMLBoardingStatus(normalizedData);
  const identityStatus = checkIdentityBoardingStatus(normalizedData);
  const referAFriendStatus = checkReferAFriendStatus(normalizedData);
  const affiliateStatus = checkAffiliateStatus(normalizedData);

  const boardings = [
    accountType,
    emailBoarding,
    mobileBoarding,
    personalBoarding,
    companyBoarding,
    walletBoarding,
    // investorTypeBoarding,
    ECSPBoardingStatus,
    investmentObjectivesStatus,
    financialSituationStatus,
    knowledgeStatus,
    referenceBankAccountStatus,
    amlStatus,
    identityStatus,
    referAFriendStatus,
    affiliateStatus,
  ];
  const boardingsClean = boardings.map(item => item).filter(item => item !== null);
  boardingsClean.sort(custom_sort_desc);
  return boardingsClean.filter(step => {
    if (step && !step.isCompleted) {
      // if (step) {
      return step;
    }
    return false;
  });
};
