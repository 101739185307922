import { fromJS, List } from 'immutable';
import { STATUS } from 'src/constants/Status';
import { actionsTypes } from './constants';

const initialState = fromJS({
  status: null,
  reports: [],
});

const newsAndEventsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionsTypes.FETCH_ALL_REPORT.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.FETCH_ALL_REPORT.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .set('reports', fromJS(payload.reports) || new List());
    case actionsTypes.FETCH_ALL_REPORT.ERROR:
      return state.set('status', STATUS.ERROR);

    default:
      return state;
  }
};
export default newsAndEventsReducer;
