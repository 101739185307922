import createSelector from 'src/utils/reselect';
import { Map } from 'immutable';

const offering = state => state.getIn(['public', 'offerings'], new Map());

export const getPublicOfferingStatus = createSelector(offering, data => data.get('status'));
export const getPublicOfferingData = createSelector(offering, data => data.get('data'));
export const getPublicOfferingQuote = createSelector(offering, data =>
  data.getIn(['quote', 'data']),
);
export const getPublicOfferingQuoteStatus = createSelector(offering, data =>
  data.getIn(['quote', 'status']),
);

export const orderProcess = createSelector(offering, data => data.get('orderProcess'));
export const getCurrentStep = createSelector(orderProcess, data => data.get('step'));
export const getCurrentOrderProcessData = createSelector(orderProcess, data => data.get('data'));
