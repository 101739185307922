import { fromJS } from 'immutable';
import { STATUS } from 'src/constants/Status';
import { actionsTypes } from './constants';

const initialState = fromJS({
  status: null,
  statuBuyToken: null,
  data: [],
});

const marketplaceReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionsTypes.FETCH_MARKETPLACE.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.FETCH_MARKETPLACE.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .update('data', value => value.concat(payload.data))
        .set('nextToken', payload.nextToken);
    case actionsTypes.FETCH_MARKETPLACE.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.FETCH_MARKETOFFER.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.FETCH_MARKETOFFER.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .set('marketOffer', payload.marketOffer)
        .set('nextToken', payload.nextToken);
    case actionsTypes.FETCH_MARKETOFFER.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.BUY_TOKENFROMMARKET.PENDING:
      return state.set('statuBuyToken', STATUS.ADDING);
    case actionsTypes.BUY_TOKENFROMMARKET.SUCCESS:
      return state
        .set('statuBuyToken', STATUS.SUCCESS)
        .set('buyTokenTransaction', payload.transaction);
    case actionsTypes.BUY_TOKENFROMMARKET.ERROR:
      return state.set('statuBuyToken', STATUS.ERROR);

    case actionsTypes.FETCH_BUYTOKENTRANSACTION.PENDING:
      return state.set('statuBuyToken', STATUS.LOADING);
    case actionsTypes.FETCH_BUYTOKENTRANSACTION.SUCCESS:
      return state
        .set('statuBuyToken', STATUS.SUCCESS)
        .set('buyTokenNextToken', payload.nextToken)
        .set('buyTokenTransaction', payload.transaction);
    case actionsTypes.FETCH_BUYTOKENTRANSACTION.ERROR:
      return state.set('statuBuyToken', STATUS.ERROR);

    case actionsTypes.SET_BUYTOKEN_STEP:
      return state.set('stepBuyToken', payload);
    case actionsTypes.SET_BUYTOKEN_TRANSACTION:
      return state.set('buyTokenTransaction', payload);

    case actionsTypes.CLEAR_MARKET:
      return initialState;

    default:
      return state;
  }
};
export default marketplaceReducer;
