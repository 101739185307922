import { fromJS, Map } from 'immutable';
import { SHOW_NOTIFICATION, HIDE_NOTIFICATION, UPDATE_NOTIFICATION } from 'src/constants/Actions';

const initialState = fromJS([]);

const NotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return state.push(new Map(action));
    case HIDE_NOTIFICATION:
      return state.filter(item => item.get('id') !== action.id);
    case UPDATE_NOTIFICATION:
      return state.map(item => {
        if (item.get('id') === action.payload.id) {
          return item.set('message', action.payload.message).set('updated', true);
        } else {
          return item;
        }
      });
    // return state.filter(item => item.get('id') !== action.id);

    default:
      return state;
  }
};
export default NotificationsReducer;
