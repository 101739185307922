export const readMessages = /* GraphQL */ `
  query Portal(
    $accountId: ModelIDKeyConditionInput
    $folderId: ID!
    $nextToken: String
    $sortDirection: String
  ) {
    myPortal {
      id
      tenantId
      accounts(accountId: $accountId) {
        items {
          status
          accountId
          account {
            id

            messages(folderId: $folderId, nextToken: $nextToken, sortDirection: $sortDirection) {
              items {
                tenantId
                id

                title
                content
                timestamp
              }
              nextToken
            }
          }
        }
      }
    }
  }
`;

export const readComments = /* GraphQL */ `
  query Portal($channelId: ID!, $nextToken: String, $sortDirection: String, $byRating: Boolean) {
    myPortal {
      id
      tenantId
      channel(id: $channelId) {
        status

        comments(nextToken: $nextToken, sortDirection: $sortDirection, byRating: $byRating) {
          items {
            tenantId
            id

            title
            content
            timestamp
            contentId

            replies {
              items {
                tenantId
                id

                title
                content
                timestamp
                replies {
                  items {
                    tenantId
                    id

                    title
                    content
                    timestamp
                  }
                  nextToken
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;

export const readComment = /* GraphQL */ `
  query Portal($channelId: ID!, $commentId: ID!, $nextToken: String, $sortDirection: String) {
    myPortal {
      id
      tenantId
      channel(id: $channelId) {
        status

        comment(id: $commentId) {
          tenantId
          id

          title
          content
          timestamp

          replies(nextToken: $nextToken, sortDirection: $sortDirection) {
            items {
              tenantId
              id

              title
              content
              timestamp
            }
            nextToken
          }
        }
      }
    }
  }
`;
