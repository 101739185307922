import { actionsTypes } from './constants';

export const toggleSideBar = () => ({
  type: actionsTypes.TOGGLE_SIDEBAR,
});
export const setActiveAccountAction = (accountId, name, affiliateId) => ({
  type: actionsTypes.ACTIVE_ACCOUNT,
  payload: { accountId, name, affiliateId },
});

export const setTenantMetaDataAction = (
  publicKey,
  allowCreateWallet,
  allowPaperWallet,
  isMultiLang,
  locale,
  languages,
  requireFullRegistration,
) => ({
  type: actionsTypes.SET_TENANT_METADATA,
  payload: {
    publicKey,
    allowCreateWallet,
    allowPaperWallet,
    isMultiLang,
    locale,
    languages,
    requireFullRegistration,
  },
});

export const setWebpageAction = webpage => ({
  type: actionsTypes.SET_WEBPAGE,
  payload: { webpage },
});

export const setRedirectAction = redirect => ({
  type: actionsTypes.SET_REDIRECT,
  payload: { redirect },
});

export const setActiveLanguageAction = language => ({
  type: actionsTypes.ACTIVE_LANGUAGE,
  payload: { language: language?.substr(0, 2) },
});

export const fetchPortalActions = {
  pending: () => ({
    type: actionsTypes.FETCH_PORTAL.PENDING,
  }),
  success: ({
    portal,
    accounts,
    allowReferAFriend,
    allowAffiliate,
    showReferAFriend,
    showAffiliate,
  }) => ({
    type: actionsTypes.FETCH_PORTAL.SUCCESS,
    payload: {
      portal,
      accounts,
      allowReferAFriend,
      allowAffiliate,
      showReferAFriend,
      showAffiliate,
    },
  }),
  error: () => ({
    type: actionsTypes.FETCH_PORTAL.ERROR,
  }),
};

export const fetchPortalFooterAction = {
  pending: payload => ({
    type: actionsTypes.FETCH_PAGE.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.FETCH_PAGE.SUCCESS,
    payload: { data },
  }),
  error: () => ({
    type: actionsTypes.FETCH_PAGE.ERROR,
  }),
};

export const toggleSwitchLanguageModalAction = () => ({
  type: actionsTypes.SWITCH_LANGUAGE_MODAL,
});

export const resetAppSettings = () => ({
  type: actionsTypes.RESET_APP_SETTING,
});

export const setIsMarketPlaceActive = isActive => ({
  type: actionsTypes.SET_IS_MARKET_PLACE_AVAILABLE,
  payload: { isActive: isActive === 'true' || isActive === true ? true : false },
});

export const setIsGovernanceActive = isActive => ({
  type: actionsTypes.SET_IS_GOVERNANCE_AVAILABLE,
  payload: { isActive: isActive === 'true' || isActive === true ? true : false },
});

export const setRequireAffiliateLink = isActive => ({
  type: actionsTypes.SET_REQUIRE_AFFILIATELINK,
  payload: { isActive: isActive === 'true' || isActive === true ? true : false },
});

export const setAllowWalletLogin = isActive => ({
  type: actionsTypes.SET_ALLOW_WALLETLOGIN,
  payload: { isActive: isActive === 'true' || isActive === true ? true : false },
});

export const setWebpageHierarchy = pageHierarchy => ({
  type: actionsTypes.SET_PAGE_HIERARCHY,
  payload: { pageHierarchy },
});

export const setGraphql = (graphql, auth) => ({
  type: actionsTypes.SET_GRAPHQL,
  payload: { graphql, auth },
});

export const setGraphqlPublic = (publicGraphql, apiKey) => ({
  type: actionsTypes.SET_GRAPHQL_PUBLIC,
  payload: { publicGraphql, apiKey },
});

export const setTenantId = tenantId => ({
  type: actionsTypes.SET_TENANTID,
  payload: { tenantId },
});
export const setPortalAbility = abilitySet => ({
  type: actionsTypes.SET_PORTALABILITY,
  payload: { abilitySet },
});
