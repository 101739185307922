export const activateReferral = /* GraphQL */ `
  mutation ActivateReferral($accountId: ID! ) {
    activateReferral(accountId: $accountId) {
      tenantId
      id
      name
      affiliate {
        referralLink
      }
    }
  }
`;
