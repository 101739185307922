import { fromJS, List } from 'immutable';
import { STATUS } from 'src/constants/Status';
import { actionsTypes } from './constants';

const buyToken = fromJS({
  status: STATUS.SUCCESS,
  data: {},
});

const initialState = fromJS({
  status: STATUS.LOADING,
  onBoardingProcess: false,
  orderProcess: {
    status: null,
  },
  onboarding: {
    step: 0,
    steps: 0,
    status: null,
  },
  selectedOffering: null,
  boardingSteps: [],
  order: null,
  step: 1,
  minimumInvestmentAmount: 0,
  confirmation: {
    popup: false,
    step: 1,
  },
  whitelisting: {
    status: STATUS.LOADING,
    data: {},
  },
  buyToken,
});

const orderReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionsTypes.FETCH_ORDER.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.FETCH_ORDER.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .set('order', payload.order || {})
        .set('activity', payload.activity || {});
    case actionsTypes.FETCH_ORDER.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.FETCH_WHITELISTED.PENDING:
      return state.setIn(['whitelisting', 'status'], STATUS.LOADING);
    case actionsTypes.FETCH_WHITELISTED.SUCCESS:
      return state
        .setIn(['whitelisting', 'status'], STATUS.SUCCESS)
        .setIn(['whitelisting', 'data'], payload.data || {});
    case actionsTypes.FETCH_WHITELISTED.ERROR:
      return state.setIn(['whitelisting', 'status'], STATUS.ERROR);

    case actionsTypes.WHITELISTING.PENDING:
      return state.setIn(['whitelisting', 'status'], STATUS.UPDATING);
    case actionsTypes.WHITELISTING.SUCCESS:
      return state
        .setIn(['whitelisting', 'status'], STATUS.SUCCESS)
        .setIn(['whitelisting', 'data'], payload.data || {});
    case actionsTypes.WHITELISTING.ERROR:
      return state.setIn(['whitelisting', 'status'], STATUS.ERROR);

    case actionsTypes.ADD_ORDER.PENDING:
      return state.setIn(['orderProcess', 'status'], STATUS.LOADING);
    case actionsTypes.ADD_ORDER.SUCCESS:
      return state
        .setIn(['orderProcess', 'status'], STATUS.SUCCESS)
        .set('order', payload.order || {});
    case actionsTypes.ADD_ORDER.ERROR:
      return state.setIn(['orderProcess', 'status'], STATUS.ERROR);

    case actionsTypes.UPDATE_ORDER.PENDING:
      return state.setIn(['orderProcess', 'status'], STATUS.LOADING);
    case actionsTypes.UPDATE_ORDER.SUCCESS:
      return state
        .setIn(['orderProcess', 'status'], STATUS.SUCCESS)
        .set('order', payload.order || {});
    case actionsTypes.UPDATE_ORDER.ERROR:
      return state.setIn(['orderProcess', 'status'], STATUS.ERROR);

    case actionsTypes.CONFIRM_ORDER.PENDING:
    case actionsTypes.CANCEL_ORDER.PENDING:
      return state.setIn(['orderProcess', 'status'], STATUS.LOADING);
    case actionsTypes.CONFIRM_ORDER.SUCCESS:
    case actionsTypes.CANCEL_ORDER.SUCCESS:
      return state
        .setIn(['orderProcess', 'status'], STATUS.SUCCESS)
        .set('order', payload.order || {});
    case actionsTypes.CONFIRM_ORDER.ERROR:
    case actionsTypes.CANCEL_ORDER.ERROR:
      return state.setIn(['orderProcess', 'status'], STATUS.ERROR);

    case actionsTypes.SET_STEP:
      return state.set('step', payload.step);
    case actionsTypes.SET_ONBOARDNIG:
      return state.set('onBoardingProcess', payload.onBoardingProcess);
    case actionsTypes.SET_MIN_INVEST_AMOUNT:
      return state.set('minimumInvestmentAmount', payload.amount);

    case actionsTypes.SET_ONBOARDING_STEPS:
      return state.setIn(['boardingSteps'], new List(payload.boardingSteps));

    case actionsTypes.UPDATE_ONBOARDING.PENDING:
      return state.setIn(['onboarding', 'status'], STATUS.LOADING);
    case actionsTypes.SET_SELECTED_OFFERING:
      return state.set('selectedOffering', payload.selectedOffering);
    case actionsTypes.UPDATE_ONBOARDING.SUCCESS:
      return state
        .setIn(['onboarding', 'status'], STATUS.SUCCESS)
        .set('order', payload.order || {});
    case actionsTypes.UPDATE_ONBOARDING.ERROR:
      return state.setIn(['onboarding', 'status'], STATUS.ERROR);

    case actionsTypes.BUY_TOKEN.PENDING:
      return state.setIn(['buyToken', 'status'], STATUS.LOADING);
    case actionsTypes.BUY_TOKEN.SUCCESS:
      return state
        .setIn(['buyToken', 'status'], STATUS.SUCCESS)
        .setIn(['buyToken', 'paymentData'], payload.paymentData || {})
        .setIn(['buyToken', 'order'], payload.order || {});
    case actionsTypes.BUY_TOKEN.ERROR:
      return state.setIn(['buyToken', 'status'], STATUS.ERROR);

    case actionsTypes.BUY_TOKEN_CONFIRM.PENDING:
      return state.setIn(['buyToken', 'status'], STATUS.LOADING);
    case actionsTypes.BUY_TOKEN_CONFIRM.SUCCESS:
      return state
        .setIn(['buyToken', 'status'], STATUS.SUCCESS)
        .setIn(['buyToken', 'order'], payload.order || {});
    case actionsTypes.BUY_TOKEN_CONFIRM.ERROR:
      return state.setIn(['buyToken', 'status'], STATUS.ERROR);

    case actionsTypes.BUY_TOKEN_RESET: {
      return state.set('buyToken', buyToken);
    }

    case actionsTypes.BUY_TOKEN_SET_PAYMENTDATA: {
      return state.setIn(['buyToken', 'paymentData'], payload.paymentData || {});
    }

    case actionsTypes.CONFIRMATION_MODAL: {
      return state.setIn(['confirmation', 'popup'], payload.status);
    }
    case actionsTypes.CONFIRMATION_MODAL_STEP: {
      return state.setIn(['confirmation', 'step'], payload.step);
    }

    case actionsTypes.VERIFYBOARDING: {
      return state;
    }
    case actionsTypes.CLEAR_VALUES:
      return initialState;

    default:
      return state;
  }
};
export default orderReducer;
