export const listGiveaways = /* GraphQL */ `
  query Query(
    $tenantId: ID!
    $id: ModelIDKeyConditionInput
    $filter: ModelGiveawayFilterInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    listGiveaways(
      tenantId: $tenantId
      id: $id
      filter: $filter
      sortDirection: $sortDirection
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        tenantId
        id
        name
        description
        overview
        heroImage
        overviewImage
      }
    }
  }
`;
