import { SHOW_NOTIFICATION, HIDE_NOTIFICATION, UPDATE_NOTIFICATION } from 'src/constants/Actions';
import { v4 as uuidv4 } from 'uuid';

export const NOTIFICATION_TYPE = {
  INFO: 'info',
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
};

const showNotifications = (
  { id = uuidv4(), title = document.title, message = '', autoDismiss, dismissible, action } = {},
  notificationType = NOTIFICATION_TYPE.INFO,
) => ({
  id,
  title,
  action,
  message,
  dismissible,
  autoDismiss,
  notificationType,
  type: SHOW_NOTIFICATION,
});

export const hideNotification = id => ({
  type: HIDE_NOTIFICATION,
  id,
});
export const updateNotification = (id, message) => ({
  type: UPDATE_NOTIFICATION,
  payload: { id, message },
});

const DEFAULT_ERROR_DATA = {
  title: 'Error',
  message: 'There was an error when trying to contact.',
};
export const showError = (data = DEFAULT_ERROR_DATA) =>
  showNotifications(data, NOTIFICATION_TYPE.ERROR);
export const showSuccess = data => showNotifications(data, NOTIFICATION_TYPE.SUCCESS);
export const showWarning = data => showNotifications(data, NOTIFICATION_TYPE.WARNING);
export const showInformation = data => showNotifications(data, NOTIFICATION_TYPE.INFO);

export default {
  showNotifications,
  hideNotification,
  updateNotification,
  showSuccess,
  showWarning,
  showInformation,
  showError,
};
