import { actionsTypes } from './constants';

export const fetchActionActivitiesActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_ALL_ACTIONS.PENDING,
    payload,
  }),
  success: ({ actions }) => ({
    type: actionsTypes.FETCH_ALL_ACTIONS.SUCCESS,
    payload: { actions },
  }),
  error: () => ({
    type: actionsTypes.FETCH_ALL_ACTIONS.ERROR,
  }),
};
