import { fromJS, List } from 'immutable';
import { STATUS } from 'src/constants/Status';
import { actionsTypes } from './constants';

const initialState = fromJS({
  status: null,
  data: [],
  giveaway: {},
});

const publicGiveaways = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionsTypes.FETCH_GIVEAWAYS.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.FETCH_GIVEAWAYS.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('data', fromJS(payload.data) || new List());
    case actionsTypes.FETCH_GIVEAWAYS.ERROR:
      return state.set('status', STATUS.ERROR);

    default:
      return state;
  }
};
export default publicGiveaways;
