export const getAllOfferings = /* GraphQL */ `
  query Portal {
    myPortal {
      id
      individual {
        firstName
        lastName
      }
      tenantId
      offerings {
        items {
          tenantId
          id
          name
          symbol
          status
          visibility
          visualType
          description
          funding {
            goalFunding
            capFunding
            totalFunding
            numSupporter
            startDate
            endDate
          }
        }
      }
    }
  }
`;

export const getOfferingDetails = /* GraphQL */ `
  query Portal($id: ModelIDKeyConditionInput, $accountId: String) {
    myPortal {
      tenantId
      id
      individual {
        firstName
        lastName
      }
      offerings(id: $id) {
        items {
          tenantId
          id
          name
          symbol
          decimals
          domain
          status
          visibility
          visualType
          description
          faq
          overview
          hasOverviewImage
          hasHeroImage
          advanced
          publicAddress
          chain
          videoUrl
          smartContractTemplate

          noKYC
          hasVAT

          fundingSupplyCap
          fundingSupplyGoal
          fundingPricePerToken
          fundingCurrency
          fundingStartDate
          fundingEndDate

          fundingAllowedCurrencies
          fundingPricePerTokenList {
            currency
            price
          }

          fundingComplianceStep
          fundingDisclaimer
          fundingComplianceDescription
          fundingComplianceFields

          totalSupplyOrdered
          numSupporterOrdered

          contractTemplate

          legal {
            companyName
            address {
              street
              postalCode
              city
              country
            }
          }

          paymentAccounts(accountId: $accountId) {
            items {
              id
              paymentProviderType
              paymentBankName
              paymentBankNr
              paymentAccountNr
              paymentServiceFee
              paymentCurrencies
              paymentAddGasFee
            }
          }

          tokenDetails {
            field0
            field1
            field2
            field3
          }
          funding {
            totalSupply
            numSupporter
          }

          collectionGroups {
            id
            title
            shortDescription
            description
            translations
            minAmount
            amount
            sold
            status
          }
        }
      }
    }
  }
`;
