import { actionsTypes } from './constants';

export const fetchChatMessagesAction = {
  pending: payload => ({
    type: actionsTypes.FETCH_MESSAGES.PENDING,
    payload,
  }),
  success: ({ data, nextToken, limit }) => ({
    type: actionsTypes.FETCH_MESSAGES.SUCCESS,
    payload: { data, nextToken, limit },
  }),
  error: () => ({
    type: actionsTypes.FETCH_MESSAGES.ERROR,
  }),
};

export const sendMessageAction = {
  pending: payload => ({
    type: actionsTypes.SEND_MESSAGE.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.SEND_MESSAGE.SUCCESS,
    payload: { data },
  }),
  error: () => ({
    type: actionsTypes.SEND_MESSAGE.ERROR,
  }),
};

export const fetchCommentsAction = {
  pending: payload => ({
    type: actionsTypes.FETCH_COMMENTS.PENDING,
    payload,
  }),
  success: ({ data, nextToken, limit }) => ({
    type: actionsTypes.FETCH_COMMENTS.SUCCESS,
    payload: { data, nextToken, limit },
  }),
  error: () => ({
    type: actionsTypes.FETCH_COMMENTS.ERROR,
  }),
};

export const fetchCommentAction = {
  pending: payload => ({
    type: actionsTypes.FETCH_COMMENT.PENDING,
    payload,
  }),
  success: ({ id, data, nextToken, replies }) => ({
    type: actionsTypes.FETCH_COMMENT.SUCCESS,
    payload: { id, data, nextToken, replies },
  }),
  error: () => ({
    type: actionsTypes.FETCH_COMMENT.ERROR,
  }),
};

export const resetValues = () => ({ type: actionsTypes.CLEAR_VALUES });
export const resetComments = () => ({ type: actionsTypes.CLEAR_COMMENTS });
