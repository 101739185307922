import { asyncActionType } from 'src/utils/reduxActions';

export const NAMESPACE = 'REFERRALS';

const FETCH_REFERRAL = asyncActionType(`${NAMESPACE} FETCH`);
const ACTIVATE_REFERRAL = asyncActionType(`${NAMESPACE} ACTIVATE`);

const CLEAR_VALUES = `${NAMESPACE} CLEAR_VALUES`;

export const actionsTypes = {
  FETCH_REFERRAL,
  ACTIVATE_REFERRAL,

  CLEAR_VALUES,
};
