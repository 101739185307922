import { actionsTypes } from './constants';

export const fetchReferralActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_REFERRAL.PENDING,
    payload,
  }),
  success: ({ referral, account }) => ({
    type: actionsTypes.FETCH_REFERRAL.SUCCESS,
    payload: { referral, account },
  }),
  error: () => ({
    type: actionsTypes.FETCH_REFERRAL.ERROR,
  }),
};

export const activateReferralAction = {
  pending: payload => ({
    type: actionsTypes.ACTIVATE_REFERRAL.PENDING,
    payload,
  }),
  success: ({ referral, account }) => ({
    type: actionsTypes.ACTIVATE_REFERRAL.SUCCESS,
    payload: { referral, account },
  }),
  error: () => ({
    type: actionsTypes.ACTIVATE_REFERRAL.ERROR,
  }),
};
