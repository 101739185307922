export const readAffiliate = /* GraphQL */ `
  query Portal($accountId: ModelIDKeyConditionInput) {
    myPortal {
      tenantId
      id
      accounts(accountId: $accountId) {
        items {
          status
          accountId
          account {
            name
            id
            referral {
              signups
              referrals
              plannedRewards
              receivedRewards
              referralId
              referralLink
            }

            address {
              street
              postalCode
              city
              state
              country
            }
          }
        }
      }
    }
  }
`;
