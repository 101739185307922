import { API, graphqlOperation } from 'aws-amplify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import NotificationActions from 'src/components/shared/Notification/actions';

import * as mutations from './graphql/mutations';
import { onboardingStatusActions, updateOnboardingActions } from './onboarding.action';
import { actionsTypes } from './constants';

export function* onboardingStatusData({ payload }) {
  if (!payload) payload = {};
  payload.accountId = localStorage.getItem('activeAccount');

  let response = {};

  try {
    response = yield call([API, 'graphql'], graphqlOperation(mutations.onboardingStatus, payload));
    yield put(
      onboardingStatusActions.success({
        onboardingDone: response.data.onboardingStatus.onboardingDone || false,
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(onboardingStatusActions.error());
    yield put(
      NotificationActions.showError({
        title: 'view_onboarding:Notification.Error.Title',
        message: `view_onboarding:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.code
        }`,
      }),
    );
  }
}

export function* updateOnboardingData({ payload }) {
  if (!payload) payload = {};
  payload.accountId = localStorage.getItem('activeAccount');

  let response = {};

  try {
    response = yield call([API, 'graphql'], graphqlOperation(mutations.onboarding, payload));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(updateOnboardingActions.error());
    yield put(
      NotificationActions.showError({
        title: 'view_onboarding:Notification.Error.Title',
        message: `view_onboarding:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.code
        }`,
      }),
    );
  }

  let data = {};
  try {
    data = JSON.parse(response.data.onboarding.data) || {};
  } catch (e) {
    data = {};
  }

  let schema = {};
  try {
    schema = JSON.parse(response.data.onboarding.schema) || {};
  } catch (e) {
    schema = {};
  }

  let steps = {};
  try {
    steps = JSON.parse(response.data.onboarding.steps) || {};
  } catch (e) {
    steps = {};
  }

  yield put(
    updateOnboardingActions.success({
      data,
      schema,
      steps,
      step: response.data.onboarding.step || {},
    }),
  );
}

export default function* appSettingsSagas() {
  yield all([takeLatest(actionsTypes.UPDATE_ONBOARDING.PENDING, updateOnboardingData)]);
  yield all([takeLatest(actionsTypes.ONBOARDING_STATUS.PENDING, onboardingStatusData)]);
}
