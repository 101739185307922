import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
import {
  getActiveTenant,
  getPublicGraphql,
  getPublicGraphqlAPIKEY,
} from 'src/components/AppSettings/appSettings.selector';

import { actionsTypes } from './constants';
import { fetchGiveawaysActions } from './giveaway.action';

import * as queries from './graphql/queries';

export function* fetchGivewaysData({ payload }) {
  const tenantId = yield select(getActiveTenant);
  const endpointPublic = yield select(getPublicGraphql);
  const apiKey = yield select(getPublicGraphqlAPIKEY);

  try {
    const params = {
      // sortDirection: 'DESC', // todo need to check why it's sending empty limit when it's uncommented
      // filter: {
      //   // status: {
      //   //   eq: 'PUBLIC',
      //   // },
      //   ...payload.filter,
      // },
      tenantId,
      limit: (payload && payload.limit) || 100,
    };

    // Create a custom API client instance with the desired endpoint
    API.configure({
      aws_appsync_graphqlEndpoint: endpointPublic,
      aws_appsync_region: 'eu-central-1',
      aws_appsync_authenticationType: 'API_KEY',
      aws_appsync_apiKey: apiKey,
    });

    const response = yield call([API, 'graphql'], graphqlOperation(queries.listGiveaways, params));

    const giveaways = response.data.listGiveaways.items;

    yield put(fetchGiveawaysActions.success({ data: giveaways }));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchGiveawaysActions.error());
  }
}

export default function* newsAndEventsSaga() {
  yield all([takeEvery(actionsTypes.FETCH_GIVEAWAYS.PENDING, fetchGivewaysData)]);
}
