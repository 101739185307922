import { actionsTypes } from './constants';

export const fetchMessagesAction = {
  pending: payload => ({
    type: actionsTypes.FETCH_MESSAGES.PENDING,
    payload,
  }),
  success: ({ data, nextToken, limit }) => ({
    type: actionsTypes.FETCH_MESSAGES.SUCCESS,
    payload: { data, nextToken, limit },
  }),
  error: () => ({
    type: actionsTypes.FETCH_MESSAGES.ERROR,
  }),
};

export const fetchMessageAction = {
  pending: payload => ({
    type: actionsTypes.FETCH_MESSAGE.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.FETCH_MESSAGE.SUCCESS,
    payload: { data },
  }),
  error: () => ({
    type: actionsTypes.FETCH_MESSAGE.ERROR,
  }),
};

export const sendMessageAction = {
  pending: payload => ({
    type: actionsTypes.SEND_MESSAGE.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.SEND_MESSAGE.SUCCESS,
    payload: { data },
  }),
  error: () => ({
    type: actionsTypes.SEND_MESSAGE.ERROR,
  }),
};

export const resetValues = () => ({ type: actionsTypes.CLEAR_VALUES });
