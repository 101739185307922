const HOST_NAME = window.location.hostname;
const PORTAL_ID =
  process.env.REACT_APP_ISMOCK === true || process.env.REACT_APP_ISMOCK === 'true'
    ? process.env.REACT_APP_PORTAL_ID
    : HOST_NAME.includes('localhost')
    ? `${process.env.REACT_APP_PORTAL_DOMAIN}`
    : HOST_NAME;

const BASE_URL =
  process.env.REACT_APP_ISMOCK === true || process.env.REACT_APP_ISMOCK === 'true'
    ? process.env.REACT_APP_BASE_URL
    : `https://${PORTAL_ID}`;
const LOGO_URL = `${BASE_URL}/images/${PORTAL_ID}/logo`;
const FAVICON_URL = `${BASE_URL}/images/${PORTAL_ID}/favicon`;
const LOGIN_BACKGROUND_URL =
  window.innerWidth >= 650
    ? `${BASE_URL}/images/${PORTAL_ID}/loginBackground`
    : `${BASE_URL}/images/${PORTAL_ID}/loginMobile`;

const LOCAL_AWS = {
  tenant: {
    id: process.env.REACT_APP_TENANTID,
    aws_appsync_dangerously_connect_to_http_endpoint_for_testing: true,
    aws_appsync_authenticationType: 'AWS_IAM',
    graphql: process.env.REACT_APP_GRAPHQL,
    publicGraphql: process.env.REACT_APP_PUBLICGRAPHQL,
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    userPoolId: process.env.REACT_APP_USERPOOLID,
    userPoolClientId: process.env.REACT_APP_WEBCLIENTID,
    isMarketPlaceActive: process.env.REACT_APP_MARKETPLACE,
    isGovernanceActive: process.env.REACT_APP_GOVERNANCE,
    supportedLanguages: ['en', 'de'],
    graphqlPublic: 'http://127.0.0.1:40032/graphql',
    graphqlPublicAPIKEY: 'da2-fakeApiId123456',
    allowMMLogin: true,
    requireAffiliate: true,
    colorSchema: 'qlindox',
    webpagetitle: 'TestTile',
    copyright: '2023',
    loginTitle: 'Welcome',
    loginWelcome: 'Text Welcome',
    walletConnect: 'cabfcdea55e1a7c194ef7641b426edbe',
    pageHierarchy: [
      {
        name: 'mynews',
        registered: false,
        startpage: false,
        slug: 'mynews',
      },
      {
        name: 'mynews',
        registered: false,
        startpage: false,
        slug: 'mynews/:id',
      },
      {
        name: 'demo',
        registered: false,
        startpage: false,
        slug: 'demo',
      },
      {
        name: 'demo1',
        registered: false,
        startpage: false,
        slug: 'demo1',
      },
      {
        name: 'demo2',
        registered: false,
        startpage: true,
        slug: 'demo2',
      },
      {
        name: 'portfolio',
        registered: true,
        startpage: false,
        slug: 'portfolio',
      },
      {
        name: 'projects',
        registered: false,
        startpage: false,
        slug: 'projects',
      },
      {
        name: 'projects details',
        registered: false,
        startpage: false,
        slug: 'projects/:id',
      },
      {
        name: 'refer-a-friend',
        registered: false,
        startpage: false,
        slug: 'refer-a-friend',
      },
      {
        name: 'mywallet',
        registered: true,
        startpage: false,
        slug: 'mywallet',
      },
      {
        name: 'dashboard',
        registered: true,
        startpage: false,
        slug: 'dashboard',
      },
      {
        name: 'invest',
        registered: false,
        startpage: false,
        slug: 'invest/:id',
      },
    ],
  },
};

const CONFIG = {
  BASE_URL,
  LOGO_URL,
  FAVICON_URL,
  HOST_NAME,
  PORTAL_ID,
  LOGIN_BACKGROUND_URL,
  LOCAL_AWS,
};

export default CONFIG;
