import { actionsTypes } from './constants';

export const fetchMarketplaceActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_MARKETPLACE.PENDING,
    payload,
  }),
  success: ({ data, nextToken }) => ({
    type: actionsTypes.FETCH_MARKETPLACE.SUCCESS,
    payload: { data, nextToken },
  }),
  error: () => ({
    type: actionsTypes.FETCH_MARKETPLACE.ERROR,
  }),
};

export const fetchMarketOfferActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_MARKETOFFER.PENDING,
    payload,
  }),
  success: ({ marketOffer, nextToken }) => ({
    type: actionsTypes.FETCH_MARKETOFFER.SUCCESS,
    payload: { marketOffer, nextToken },
  }),
  error: () => ({
    type: actionsTypes.FETCH_MARKETOFFER.ERROR,
  }),
};

export const fetchBuyTokenTransactionActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_BUYTOKENTRANSACTION.PENDING,
    payload,
  }),
  success: ({ transaction, nextToken }) => ({
    type: actionsTypes.FETCH_BUYTOKENTRANSACTION.SUCCESS,
    payload: { transaction, nextToken },
  }),
  error: () => ({
    type: actionsTypes.FETCH_BUYTOKENTRANSACTION.ERROR,
  }),
};

export const buyTokenActions = {
  pending: payload => ({
    type: actionsTypes.BUY_TOKENFROMMARKET.PENDING,
    payload,
  }),
  success: ({ transaction }) => ({
    type: actionsTypes.BUY_TOKENFROMMARKET.SUCCESS,
    payload: { transaction },
  }),
  error: () => ({
    type: actionsTypes.BUY_TOKENFROMMARKET.ERROR,
  }),
};

export const resetMarket = () => ({ type: actionsTypes.CLEAR_MARKET });

export const setBuyTokenStepActions = payload => ({
  type: actionsTypes.SET_BUYTOKEN_STEP,
  payload,
});

export const setBuyTokenTransactionActions = payload => ({
  type: actionsTypes.SET_BUYTOKEN_TRANSACTION,
  payload,
});
