import { fromJS, List, Map } from 'immutable';
import { STATUS } from 'src/constants/Status';
import { actionsTypes } from './constants';

const initialState = fromJS({
  status: null,
  offerings: [],
  details: {
    status: null,
    offering: {},
  },
});

const offeringReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionsTypes.FETCH_ALL_OFFERING.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.FETCH_ALL_OFFERING.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .set('offerings', fromJS(payload.offerings) || new List());
    case actionsTypes.FETCH_ALL_OFFERING.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.FETCH_OFFERING_DETAILS.PENDING:
      return state.setIn(['details', 'status'], STATUS.LOADING);
    case actionsTypes.FETCH_OFFERING_DETAILS.SUCCESS:
      return state
        .setIn(['details', 'status'], STATUS.SUCCESS)
        .setIn(['details', 'offering'], fromJS(payload.offering) || new Map());
    case actionsTypes.FETCH_OFFERING_DETAILS.ERROR:
      return state.setIn(['details', 'status'], STATUS.ERROR);
    default:
      return state;
  }
};
export default offeringReducer;
