export const portal = /* GraphQL */ `
  query Portal {
    myPortal {
      tenantId
      id
      firstName
      lastName
      publicKey
      allowCreateWallet
      allowPaperWallet
      allowMMLogin
      isMultiLang
      locale
      languages
      accounts {
        items {
          accountId
          tenantId
          account {
            name
            id
            isReferred
            affiliate {
              referralLink
              referralId
            }
          }
        }
      }
      tenant {
        id
        name
        domain

        isMarketPlaceActive
        isGovernanceActive
        requireFullRegistration
        allowMMLogin

        allowReferAFriend
        showReferAFriend
        allowAffiliate
        showAffiliate

        webpagetitle
        copyright

        startPage

        facebook
        linkedin
        youtube
        instagram
        twitter
        requireTerms1
        requireTerms2
        requireTerms3
        requireTerms4
        requireTerms5
        terms {
          key
          title
          content
          description
        }
      }
    }
  }
`;

// export const portal = /* GraphQL */ `
//   query Portal(
//     $accountId: ModelIDKeyConditionInput
//   ) {
//     myPortal {
//       tenantId
//       id
//       individual {
//         firstName
//         lastName
//       }
//       publicKey
//       allowCreateWallet
//       accounts(accountId: $accountId) {
//         items {
//           status
//           accountId
//           tenantId
//           account {
//             name
//             id
//           }
//         }
//       }
//       tenant {
//         id
//         name
//         domain

//         webpagetitle
//         copyright
//         facebook
//         linkedin
//         youtube
//         instagram
//         twitter
//       }
//     }
//   }
// `;
