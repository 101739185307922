import { all, spawn } from 'redux-saga/effects';
import appSeetingsSagas from 'src/components/AppSettings/appSettings.saga';
import accountSagas from 'src/views/Account/account.saga';
import getNewsAndEventsSagas from 'src/views/NewsAndEvent/news.saga';
import OfferingSagas from 'src/views/Offering/offering.saga';
import StoreSagas from 'src/views/Store/store.saga';
import ActionSagas from 'src/views/Actions/actions.saga';
import AffiliateSagas from 'src/views/Affiliate/affiliate.saga';
import referralsSagas from 'src/views/Referrals/referrals.saga';
import ReportSagas from 'src/views/Reports/reports.saga';
import DashboardSagas from 'src/views/Dashboard/core/dashboard.saga';
import PageSagas from 'src/views/Pages/page.saga';
import MarketplaceSagas from 'src/views/Marketplace/core/marketplace.saga';
import GiveawaySagas from 'src/views/Giveaways/giveaway.saga';
import PublicGiveawaySagas from 'src/views/Public/Giveaways/giveaway.saga';
import PublicNewsAndEventSagas from 'src/views/Public/NewsAndEvent/news.saga';
import PublicOfferingsSagas from 'src/views/Public/Offerings/offerings.saga';
import OnboardingSagas from 'src/views/Onboarding/onboarding.saga';
import PostboxSagas from 'src/views/Postbox/postbox.saga';
import CommunicationSagas from 'src/views/Communication/communication.saga';

export default function* root() {
  yield all([
    spawn(appSeetingsSagas),
    spawn(accountSagas),
    spawn(getNewsAndEventsSagas),
    spawn(OfferingSagas),
    spawn(StoreSagas),
    spawn(ActionSagas),
    spawn(ReportSagas),
    spawn(DashboardSagas),
    spawn(PageSagas),
    spawn(MarketplaceSagas),
    spawn(AffiliateSagas),
    spawn(referralsSagas),
    spawn(GiveawaySagas),
    spawn(OnboardingSagas),
    spawn(PublicGiveawaySagas),
    spawn(PublicNewsAndEventSagas),
    spawn(PublicOfferingsSagas),
    spawn(PostboxSagas),
    spawn(CommunicationSagas),
  ]);
}
