import createSelector from 'src/utils/reselect';
import { Map } from 'immutable';

const marketplace = state => state.get('marketplace', new Map());

export const getMarketplaceStatus = createSelector(marketplace, data => data.get('status'));
export const getMarketplacesList = createSelector(marketplace, data => data.get('data'));
export const getMarketplacesNextToken = createSelector(marketplace, data => data.get('nextToken'));
export const getMarketOffer = createSelector(marketplace, data => data.get('marketOffer'));

export const getBuyTokenStep = createSelector(marketplace, data => data.get('stepBuyToken') || '1');
export const getBuyTokenTransaction = createSelector(
  marketplace,
  data => data.get('buyTokenTransaction') || {},
);
export const getBuyTokenNextToken = createSelector(
  marketplace,
  data => data.get('buyTokenNextToken') || null,
);
export const getBuyTokenStatus = createSelector(
  marketplace,
  data => data.get('statuBuyToken') || {},
);
