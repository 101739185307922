import { actionsTypes } from './constants';

export const fetchOfferingsActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_ALL_OFFERING.PENDING,
    payload,
  }),
  success: ({ offerings }) => ({
    type: actionsTypes.FETCH_ALL_OFFERING.SUCCESS,
    payload: { offerings },
  }),
  error: () => ({
    type: actionsTypes.FETCH_ALL_OFFERING.ERROR,
  }),
};

export const fetchOfferingDetailsAction = {
  pending: id => ({
    type: actionsTypes.FETCH_OFFERING_DETAILS.PENDING,
    payload: { id },
  }),
  success: ({ offering }) => ({
    type: actionsTypes.FETCH_OFFERING_DETAILS.SUCCESS,
    payload: { offering },
  }),
  error: () => ({
    type: actionsTypes.FETCH_OFFERING_DETAILS.ERROR,
  }),
};
