import i18n from 'i18next';
import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
import ENV_CONFIG from 'src/constants/Config';
import {
  getActiveAccount,
  getPageHierarchy,
  getUserLanguage,
} from 'src/components/AppSettings/appSettings.selector';
import { pageContentUrl } from 'src/utils/common';
import { fetchAccountActions } from 'src/views/Account/account.action';
import base64 from 'react-native-base64';
import analytics from 'src/analytics';
import * as queries from './GraphQL/queries';
import { actionsTypes } from './constants';
import {
  fetchPortalActions,
  fetchPortalFooterAction,
  setActiveAccountAction,
  setRedirectAction,
  setTenantMetaDataAction,
  setWebpageAction,
} from './appSettings.action';

export function* fetchPortalData() {
  const pageHierarchy = yield select(getPageHierarchy);
  const pageHierarchyStart = pageHierarchy.filter(page => page.startpage === true);
  try {
    const response = yield call([API, 'graphql'], graphqlOperation(queries.portal));
    const { myPortal } = response.data;
    let accountId = myPortal.accounts.items[0].accountId;
    let accountName = myPortal.accounts.items[0].account?.name;
    let affiliateId = myPortal.accounts.items[0].account?.affiliate?.referralId;
    let isReferred = myPortal.accounts.items[0].account?.isReferred || false;
    localStorage.setItem('isReferred', isReferred);

    const publicKey = myPortal.publicKey;
    const allowCreateWallet = myPortal.allowCreateWallet;
    // const allowMMLogin = myPortal.allowMMLogin;
    const allowPaperWallet = myPortal.allowPaperWallet;
    const isMultiLang = myPortal.isMultiLang;
    const requireFullRegistration = myPortal.tenant?.requireFullRegistration;
    const allowAffiliate = myPortal.tenant?.allowAffiliate;
    const allowReferAFriend = myPortal.tenant?.allowReferAFriend;
    const showReferAFriend = myPortal.tenant?.showReferAFriend;
    const showAffiliate = myPortal.tenant?.showAffiliate;
    const locale = myPortal.locale;
    const languages = myPortal.languages;
    const accounts = myPortal.accounts?.items;

    analytics.identify(base64.encode(accountId), {
      name: accountName,
      locale,
    });

    const startPage = myPortal.tenant?.startPage;

    if (startPage && pageHierarchyStart.length === 0) {
      yield put(setRedirectAction(startPage));
    }

    // Check if the account in local storage belongs to user
    // If this is the case, use the last active account
    const previousAccount = yield select(getActiveAccount);
    const foundPreviousAccount = accounts.find(account => account.accountId === previousAccount);
    if (foundPreviousAccount) {
      accountId = foundPreviousAccount.accountId;
      accountName = foundPreviousAccount.account?.name;
      affiliateId = foundPreviousAccount.account?.affiliate?.referralId;
    }

    yield put(setActiveAccountAction(accountId, accountName, affiliateId));
    if (!isMultiLang) {
      i18n.changeLanguage(locale);
    } else if (isMultiLang) {
      const isDetectedLanguageValid = languages.includes(i18n.language);
      if (!isDetectedLanguageValid) {
        i18n.changeLanguage(locale);
      }
    }

    yield put(
      setTenantMetaDataAction(
        publicKey,
        allowCreateWallet,
        allowPaperWallet,
        isMultiLang,
        locale,
        languages,
        requireFullRegistration,
      ),
    );

    yield put(
      setWebpageAction({
        webpagetitle: myPortal.tenant?.webpagetitle,
        copyright: myPortal.tenant?.copyright,
        facebook: myPortal.tenant?.facebook,
        linkedin: myPortal.tenant?.linkedin,
        youtube: myPortal.tenant?.youtube,
        instagram: myPortal.tenant?.instagram,
        twitter: myPortal.tenant?.twitter,
      }),
    );

    yield put(
      fetchPortalActions.success({
        accounts,
        portal: { myPortal },
        allowAffiliate,
        allowReferAFriend,
        showReferAFriend,
        showAffiliate,
      }),
    );

    yield put(fetchAccountActions.pending());
    yield put(fetchPortalFooterAction.pending('footerabout'));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchPortalActions.error());
  }
}

export function* fetchPageData({ payload }) {
  try {
    const language = yield select(getUserLanguage);
    const responseHTML = yield call(
      fetch,
      `${pageContentUrl(ENV_CONFIG.PORTAL_ID, payload, language)}.html`,
    );
    const html = yield call([responseHTML, 'text']);
    const responseCSS = yield call(
      fetch,
      `${pageContentUrl(ENV_CONFIG.PORTAL_ID, payload, language)}.css`,
    );
    const css = yield call([responseCSS, 'text']);
    let data = null;
    if (responseHTML.status < 400) {
      data = `<style>${css}</style>${html}`;
    }
    yield put(fetchPortalFooterAction.success({ data }));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchPortalFooterAction.error());
  }
}

export default function* appSeetingsSagas() {
  yield all([takeLatest(actionsTypes.FETCH_PAGE.PENDING, fetchPageData)]);
  yield all([takeLatest(actionsTypes.FETCH_PORTAL.PENDING, fetchPortalData)]);
}
