import { actionsTypes } from './constants';

export const fetchNewsAndEventsActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_ALL_NEWS_AND_EVENTS.PENDING,
    payload,
  }),
  success: ({ newsAndEvents }) => ({
    type: actionsTypes.FETCH_ALL_NEWS_AND_EVENTS.SUCCESS,
    payload: { newsAndEvents },
  }),
  error: () => ({
    type: actionsTypes.FETCH_ALL_NEWS_AND_EVENTS.ERROR,
  }),
};

export const fetchFeaturedNewsAndEventsActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_FEATURED_NEWS_AND_EVENTS.PENDING,
    payload,
  }),
  success: ({ newsAndEvents }) => ({
    type: actionsTypes.FETCH_FEATURED_NEWS_AND_EVENTS.SUCCESS,
    payload: { newsAndEvents },
  }),
  error: () => ({
    type: actionsTypes.FETCH_FEATURED_NEWS_AND_EVENTS.ERROR,
  }),
};

export const fetchOtherNewsAndEventsActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_OTHER_NEWS_AND_EVENTS.PENDING,
    payload,
  }),
  success: ({ newsAndEvents }) => ({
    type: actionsTypes.FETCH_OTHER_NEWS_AND_EVENTS.SUCCESS,
    payload: { newsAndEvents },
  }),
  error: () => ({
    type: actionsTypes.FETCH_OTHER_NEWS_AND_EVENTS.ERROR,
  }),
};

export const fetchDetailsAction = {
  pending: id => ({
    type: actionsTypes.FETCH_DETAILS_NEWS_AND_EVENT.PENDING,
    payload: { id },
  }),
  success: ({ newsAndEvent }) => ({
    type: actionsTypes.FETCH_DETAILS_NEWS_AND_EVENT.SUCCESS,
    payload: { newsAndEvent },
  }),
  error: () => ({
    type: actionsTypes.FETCH_DETAILS_NEWS_AND_EVENT.ERROR,
  }),
};
