import { fromJS } from 'immutable';
import { STATUS } from 'src/constants/Status';
import { actionsTypes } from './constants';

const comments = fromJS({
  status: STATUS.LOADING,
  data: [],
});

const comment = fromJS({ status: STATUS.LOADING });

const initialState = fromJS({
  status: null,
  data: [],
  limit: 100,
  message: {
    status: STATUS.LOADING,
    data: {},
  },

  comments,
  comment,
});

const offeringReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionsTypes.FETCH_MESSAGES.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.FETCH_MESSAGES.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .update('data', value => value.concat(payload.data))
        .set('nextToken', payload.nextToken || '');
    case actionsTypes.FETCH_MESSAGES.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.SEND_MESSAGE.PENDING:
      return state.setIn(['message', 'status'], STATUS.ADDING);
    case actionsTypes.SEND_MESSAGE.SUCCESS:
      return state
        .setIn(['message', 'status'], STATUS.SUCCESS)
        .setIn(['message', 'data'], payload.data);
    case actionsTypes.SEND_MESSAGE.ERROR:
      return state.setIn(['layout', 'status'], STATUS.ERROR);

    // Comments
    case actionsTypes.FETCH_COMMENTS.PENDING:
      return state.setIn(['comments', 'status'], STATUS.LOADING);
    case actionsTypes.FETCH_COMMENTS.SUCCESS:
      return state
        .setIn(['comments', 'status'], STATUS.SUCCESS)
        .updateIn(['comments', 'data'], value => value.concat(payload.data))
        .setIn(['comments', 'nextToken'], payload.nextToken || '');
    case actionsTypes.FETCH_COMMENTS.ERROR:
      return state.setIn(['comments', 'status'], STATUS.ERROR);

    case actionsTypes.FETCH_COMMENT.PENDING:
      return state
        .setIn(['comment', payload.commentId], fromJS({ replies: [] }))
        .setIn(['comment', payload.commentId, 'status'], STATUS.LOADING);
    case actionsTypes.FETCH_COMMENT.SUCCESS:
      return state
        .setIn(['comment', payload.id, 'status'], STATUS.SUCCESS)
        .setIn(['comment', payload.id, 'data'], payload.data)
        .updateIn(['comment', payload.id, 'replies'], value => value.concat(payload.replies))
        .setIn(['comment', payload.id, 'nextToken'], payload.nextToken || '');
    case actionsTypes.FETCH_COMMENT.ERROR:
      return state.setIn(['comment', 'status'], STATUS.ERROR);

    case actionsTypes.CLEAR_VALUES:
      return initialState;

    case actionsTypes.CLEAR_COMMENTS:
      return state.set('comments', comments);

    default:
      return state;
  }
};
export default offeringReducer;
