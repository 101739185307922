import { asyncActionType } from 'src/utils/reduxActions';

export const NAMESPACE = 'GIVEAWAYS';

const FETCH_GIVEAWAYS = asyncActionType(`${NAMESPACE} FETCH ALL`);
const FETCH_GIVEAWAY = asyncActionType(`${NAMESPACE} FETCH DETAILS`);
const REDEEM_GIVEAWAY = asyncActionType(`${NAMESPACE} REDEEM`);

export const actionsTypes = {
  FETCH_GIVEAWAYS,
  FETCH_GIVEAWAY,
  REDEEM_GIVEAWAY,
};
