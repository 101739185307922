import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { setRedirectAction } from 'src/components/AppSettings/appSettings.action';
import { API, graphqlOperation } from 'aws-amplify';
import NotificationActions from 'src/components/shared/Notification/actions';
import { fetchAccountOpenOrderActions } from 'src/views/Account/account.action';
import { getAccountData } from 'src/views/Account/account.selector';
import { getBoardingStepsNormalizer } from 'src/views/Account/normalizeAccount';
// import { getLocation } from 'src/components/AppSettings/appSettings.selector';
import {
  getGraphqlAuth,
  getGraphqlEndpoint,
} from 'src/components/AppSettings/appSettings.selector';
import base64 from 'react-native-base64';
import * as queries from './GraphQL/queries';
import * as mutations from './GraphQL/mutations';
import { getBoardingSelectedOffering, getInvestStep, getOnboardingProcess } from './store.selector';
// import { actionsTypes, SKIP_VERIFICATION_REDIRECTION } from './constants';
import { actionsTypes } from './constants';
import {
  addOrderActions,
  buyTokenAction,
  cancelOrderActions,
  confirmOrderActions,
  fetchOrderActions,
  fetchWhitelistedActions,
  resetValues,
  setBoardingSteps,
  setInvestStepAction,
  // updateOnboardingActions,
  updateOrderActions,
  whitelistingActions,
} from './store.action';
import { setOnboardingState } from '../Onboarding/onboarding.action';
import { setOrderProcess } from '../Public/Offerings/offerings.action';
import { getCurrentOrderProcessData } from '../Public/Offerings/offerings.selector';

// import { getAccountData } from '../Account/account.selector';
// import { getOfferingDetails } from '../Offering/offering.selector';

export function* fetchOrderData({ payload }) {
  try {
    const params = {
      accountId: {
        eq: localStorage.getItem('activeAccount'),
      },
      filter: {
        transactionId: {
          eq: 'ORDER#' + payload, //ORDER#9f402582-61ed-4e56-a079-3a7a42c7273d
        },
      },
    };
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(queries.readAccountSpecificOrder, params),
    );
    const order = response.data.myPortal.accounts.items[0].account.transactions.items[0];
    const fundingComplianceStep = order.offering.fundingComplianceStep;

    if (order.status === 'OUTDATED') {
      yield put(NotificationActions.showError({ message: 'Order is outdated' }));
      yield put(setInvestStepAction(1));
    } else if ((order.status === 'ORDERED' || order.status === 'PAID') && fundingComplianceStep) {
      yield put(setInvestStepAction(3));
    } else if (order.status === 'ORDERED' || order.status === 'PAID') {
      yield put(setInvestStepAction(3));
    } else if (order.amount) {
      yield put(setInvestStepAction(2));
    }

    yield put(
      fetchOrderActions.success({
        order,
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchOrderActions.error());
    yield put(NotificationActions.showError({ message: error.errors[0].message }));
  }
}

export function* fetchWhitelistedData({ payload }) {
  try {
    const params = {
      accountId: {
        eq: localStorage.getItem('activeAccount'),
      },
      filter: {
        transactionId: {
          eq: 'WHITELISTED#' + payload, //ORDER#9f402582-61ed-4e56-a079-3a7a42c7273d
        },
      },
    };
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(queries.readAccountWhitelisted, params),
    );
    const whitelisted = response.data.myPortal.accounts.items[0].account.transactions.items[0];
    yield put(
      fetchWhitelistedActions.success({
        data: whitelisted,
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchWhitelistedActions.error());
    yield put(NotificationActions.showError({ message: error.errors[0].message }));
  }
}

export function* addOrderData({ payload }) {
  payload.accountId = localStorage.getItem('activeAccount');
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.addIpOfferingOrder, payload),
    );
    yield put(
      addOrderActions.success({
        data: response.data.addIPOfferingOrder,
      }),
    );
    const currentStep = yield select(getInvestStep);
    yield put(setInvestStepAction(currentStep + 1));
    yield put(fetchAccountOpenOrderActions.pending());
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(addOrderActions.error());
    yield put(
      NotificationActions.showError({
        message: `view_store:Errors.${Array.isArray(error.errors) && error.errors[0].message}`,
      }),
    );
  }
}

export function* whitelistingData({ payload }) {
  payload.accountId = localStorage.getItem('activeAccount');
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.whitelistIpOfferingOrder, payload),
    );
    yield put(
      whitelistingActions.success({
        data: response.data.whitelistIPOfferingOrder,
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(whitelistingActions.error());
    yield put(
      NotificationActions.showError({
        message: `view_store:Errors.${Array.isArray(error.errors) && error.errors[0].message}`,
      }),
    );
  }
}

export function* updateOrderData({ payload }) {
  payload['accountId'] = localStorage.getItem('activeAccount');
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.updateIpOfferingOrder, payload),
    );

    // const orderData = response.data.updateIpOfferingOrder;

    yield put(
      updateOrderActions.success({
        data: response.data.updateIPOfferingOrder,
      }),
    );

    // if (orderData.status === 'OUTDATED') {
    //   yield put(NotificationActions.showError({ message: 'Order is outdated' }));
    //   yield put(setInvestStepAction(1));
    // } else {
    //   const currentStep = yield select(getInvestStep);
    //   yield put(setInvestStepAction(currentStep + 1));
    //   yield put(fetchAccountOpenOrderActions.pending());
    // }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(updateOrderActions.error());
    if (Array.isArray(error.errors)) {
      yield put(NotificationActions.showError({ message: error.errors[0].message }));
    }
  }
}

export function* confirmOrderData({ payload }) {
  payload['accountId'] = localStorage.getItem('activeAccount');
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.confirmIpOfferingOrder, payload),
    );
    const order = response.data.confirmIPOfferingOrder;
    yield put(
      confirmOrderActions.success({
        data: response.data.confirmIPOfferingOrder,
      }),
    );

    if (order.status === 'OUTDATED') {
      yield put(NotificationActions.showError({ message: 'Order is outdated' }));
      yield put(setInvestStepAction(1));
    } else {
      const currentStep = yield select(getInvestStep);
      yield put(setInvestStepAction(currentStep + 1));
      yield put(fetchAccountOpenOrderActions.pending());
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(confirmOrderActions.error());
    yield put(NotificationActions.showError({ message: error.errors[0].message }));
  }
}

export function* updateOnboardingData({ payload }) {
  // const account = yield select(getAccountData);
  // const offering = yield select(getOfferingDetails);
  // let steps = 0;
  // let step = 0;
  // if (!offering.noKYC) {
  //   steps += 1;
  //   if (account.kycStatus === 'CONFIREMD') {
  //     step += 1;
  //   }
  // }
  // if (account.emailStatus !== 'CONFIRMED') {
  //   steps += 1;
  // } else {
  //   steps += 1;
  //   step += 1;
  // }
  // if (
  //   !account.createPaperWallet &&
  //   (account.mainBlockchainWalletId === undefined ||
  //     account.mainBlockchainWalletId === null ||
  //     account.mainBlockchainWalletId === '')
  // ) {
  //   steps += 1;
  // } else {
  //   step += 1;
  //   steps += 1;
  // }
  // if (step === steps) {
  //   window.history.back();
  // }
}

export function* cancelOrderData({ payload, callback }) {
  payload['accountId'] = localStorage.getItem('activeAccount');
  try {
    yield call([API, 'graphql'], graphqlOperation(mutations.cancelIpOfferingOrder, payload));
    yield put(resetValues());
    yield put(fetchAccountOpenOrderActions.pending());
    // yield put(
    //   NotificationActions.showSuccess({
    //     title: 'view_store:Notification.Cancelled.Title',
    //     message: 'view_store:Notification.Cancelled.Message',
    //   }),
    // );
    if (callback) {
      callback();
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(cancelOrderActions.error());
    yield put(NotificationActions.showError({ message: error.errors[0].message }));
  }
}

export function* verifyBoardingStep() {
  try {
    const normalizedData = yield select(getAccountData);
    const isOnboarding = yield select(getOnboardingProcess);
    // const currenPath = yield select(getLocation);
    const newBoardingSteps = getBoardingStepsNormalizer(normalizedData);

    yield put(setBoardingSteps(newBoardingSteps));
    let sortBoardingStep = newBoardingSteps.sort((a, b) => a.order - b.order);
    sortBoardingStep = sortBoardingStep.filter(element => !element.isCompleted);

    // if (SKIP_VERIFICATION_REDIRECTION.includes(currenPath)) {
    //   return;
    // }

    if (isOnboarding && sortBoardingStep.length) {
      const nextBoardingUrl = sortBoardingStep[0].redirectTo;
      yield put(setRedirectAction(nextBoardingUrl));
      // yield put(push(`/#${nextBoardingUrl}`));
    } else if (isOnboarding) {
      const selectedOffering = yield select(getBoardingSelectedOffering);
      const redirectionUrl = selectedOffering ? `/offer/${selectedOffering}/order` : '/dashboard';
      // yield put(push(`/#${redirectionUrl}`));
      yield put(setRedirectAction(redirectionUrl));
      // } else {
      // yield put(push('/account/settings'));
      // yield put(setRedirectAction('/account/settings'));
    } else if (sortBoardingStep.length > 0) {
      yield put(setOnboardingState(sortBoardingStep[0].name));
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(NotificationActions.showError({ message: 'Something Went Wrong' }));
  }
}

export function* buyTokenData({ payload }) {
  payload.accountId = base64.encode(localStorage.getItem('activeAccount'));

  const graphqlEndpoint = yield select(getGraphqlEndpoint);
  const graphqlAuth = yield select(getGraphqlAuth);
  try {
    API.configure({
      aws_appsync_graphqlEndpoint: graphqlEndpoint,
      aws_appsync_region: 'eu-central-1',
      aws_appsync_authenticationType: graphqlAuth,
    });
    const response = yield call([API, 'graphql'], graphqlOperation(mutations.createOrder, payload));
    let paymentData = {};
    let paymentDataList = [];
    let provider = '';
    try {
      for (let i = 0; i < response.data.createOrder.paymentDataList.length; i += 1) {
        paymentDataList.push(JSON.parse(response.data.createOrder.paymentDataList[i]));
      }
      if (response.data.createOrder.paymentDataList.length === 1) {
        paymentData = JSON.parse(response.data.createOrder.paymentDataList[0]);
        provider = paymentData.provider;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    yield put(
      buyTokenAction.success({
        data: { ...response.data.createOrder, paymentDataList },
        paymentData,
      }),
    );

    const orderProcessData = yield select(getCurrentOrderProcessData);

    yield put(
      setOrderProcess({
        step: 1,
        data: {
          ...orderProcessData,
          provider,
        },
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(buyTokenAction.error());
    yield put(
      NotificationActions.showError({
        message: `view_store:Errors.${Array.isArray(error.errors) && error.errors[0].message}`,
      }),
    );
  }
}

export function* buyTokenConfirmData({ payload }) {
  payload.accountId = base64.encode(localStorage.getItem('activeAccount'));

  const graphqlEndpoint = yield select(getGraphqlEndpoint);
  const graphqlAuth = yield select(getGraphqlAuth);
  try {
    API.configure({
      aws_appsync_graphqlEndpoint: graphqlEndpoint,
      aws_appsync_region: 'eu-central-1',
      aws_appsync_authenticationType: graphqlAuth,
    });
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.confirmOrder, payload),
    );

    yield put(
      buyTokenAction.success({
        data: { ...response.data.confirmOrder },
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(buyTokenAction.error());
    yield put(
      NotificationActions.showError({
        message: `view_store:Errors.${Array.isArray(error.errors) && error.errors[0].message}`,
      }),
    );
  }
}

export default function* appSettingsSagas() {
  yield all([
    takeLatest(actionsTypes.FETCH_ORDER.PENDING, fetchOrderData),
    takeLatest(actionsTypes.FETCH_WHITELISTED.PENDING, fetchWhitelistedData),
    takeLatest(actionsTypes.ADD_ORDER.PENDING, addOrderData),
    takeLatest(actionsTypes.UPDATE_ORDER.PENDING, updateOrderData),
    takeLatest(actionsTypes.CONFIRM_ORDER.PENDING, confirmOrderData),
    takeLatest(actionsTypes.CANCEL_ORDER.PENDING, cancelOrderData),
    takeLatest(actionsTypes.UPDATE_ONBOARDING.PENDING, updateOnboardingData),
    takeLatest(actionsTypes.WHITELISTING.PENDING, whitelistingData),
    takeLatest(actionsTypes.BUY_TOKEN.PENDING, buyTokenData),
    takeLatest(actionsTypes.BUY_TOKEN_CONFIRM.PENDING, buyTokenConfirmData),
    takeLatest(actionsTypes.VERIFYBOARDING, verifyBoardingStep),
  ]);
}
