import { asyncActionType } from 'src/utils/reduxActions';

export const NAMESPACE = 'MARKETPLACE';

const FETCH_MARKETPLACE = asyncActionType(`${NAMESPACE} FETCH ALL`);
const FETCH_MARKETOFFER = asyncActionType(`${NAMESPACE} FETCH MARKETOFFER`);
const FETCH_BUYTOKENTRANSACTION = asyncActionType(`${NAMESPACE} FETCH BUY TOKEN TRANSACTION`);
const CLEAR_MARKET = `${NAMESPACE} CLEAR MARKET`;

const SET_BUYTOKEN_STEP = `${NAMESPACE} STEP LIST STEP`;
const SET_BUYTOKEN_TRANSACTION = `${NAMESPACE} LIST TRANSACTION`;
const BUY_TOKENFROMMARKET = asyncActionType(`${NAMESPACE} BUY TOKEN`);

export const actionsTypes = {
  FETCH_MARKETPLACE,
  FETCH_MARKETOFFER,
  CLEAR_MARKET,

  SET_BUYTOKEN_STEP,
  SET_BUYTOKEN_TRANSACTION,
  BUY_TOKENFROMMARKET,
  FETCH_BUYTOKENTRANSACTION,
};
