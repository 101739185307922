import { asyncActionType } from 'src/utils/reduxActions';

export const NAMESPACE = 'COMMUNICATION';

const FETCH_MESSAGES = asyncActionType(`${NAMESPACE} FETCH ALL`);
const FETCH_COMMENTS = asyncActionType(`${NAMESPACE} FETCH ALL COMMENTS`);
const FETCH_COMMENT = asyncActionType(`${NAMESPACE} FETCH COMMENT`);
const SEND_MESSAGE = asyncActionType(`${NAMESPACE} SEND MESSAGE`);
const CLEAR_VALUES = `${NAMESPACE} CLEAR_VALUES`;
const CLEAR_COMMENTS = `${NAMESPACE} CLEAR_COMMENTS`;

export const actionsTypes = {
  FETCH_MESSAGES,
  FETCH_COMMENTS,
  FETCH_COMMENT,
  SEND_MESSAGE,
  CLEAR_VALUES,
  CLEAR_COMMENTS,
};
