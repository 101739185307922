import { actionsTypes } from './constants';

export const fetchAffiliateActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_AFFILIATE.PENDING,
    payload,
  }),
  success: ({ affiliate, account }) => ({
    type: actionsTypes.FETCH_AFFILIATE.SUCCESS,
    payload: { affiliate, account },
  }),
  error: () => ({
    type: actionsTypes.FETCH_AFFILIATE.ERROR,
  }),
};

export const fetchOrdersActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_ORDERS.PENDING,
    payload,
  }),
  success: ({ data, nextToken }) => ({
    type: actionsTypes.FETCH_ORDERS.SUCCESS,
    payload: { data, nextToken },
  }),
  error: () => ({
    type: actionsTypes.FETCH_ORDERS.ERROR,
  }),
};

export const fetchSignupsActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_SIGNUPS.PENDING,
    payload,
  }),
  success: ({ data, nextToken }) => ({
    type: actionsTypes.FETCH_SIGNUPS.SUCCESS,
    payload: { data, nextToken },
  }),
  error: () => ({
    type: actionsTypes.FETCH_SIGNUPS.ERROR,
  }),
};

export const fetchAffiliatedAccountActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_AFFILIATEDACCOUNT.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.FETCH_AFFILIATEDACCOUNT.SUCCESS,
    payload: { data },
  }),
  error: () => ({
    type: actionsTypes.FETCH_AFFILIATEDACCOUNT.ERROR,
  }),
};

export const activateReferralAction = {
  pending: payload => ({
    type: actionsTypes.ACTIVATE_AFFILIATE.PENDING,
    payload,
  }),
  success: ({ affiliate, account }) => ({
    type: actionsTypes.ACTIVATE_AFFILIATE.SUCCESS,
    payload: { affiliate, account },
  }),
  error: () => ({
    type: actionsTypes.ACTIVATE_AFFILIATE.ERROR,
  }),
};

export const resetOrders = () => ({ type: actionsTypes.CLEAR_ORDERS });
export const resetSignups = () => ({ type: actionsTypes.CLEAR_SIGNUPS });
