import createSelector from 'src/utils/reselect';
import { Map } from 'immutable';

const appSettings = state => state.get('appSettings', new Map());

export const getPortalData = createSelector(appSettings, data => data.get('portal') || {});
export const getPortalStatus = createSelector(appSettings, data => data.get('status'));
export const getPortalStatus1 = createSelector(appSettings, data => data.get('portalStatus'));
export const getPortalAbilitySet = createSelector(appSettings, data =>
  data.get('portalAbilitySet'),
);
export const getAccountData = createSelector(appSettings, data => data.get('account') || {});
export const getUserLanguage = createSelector(appSettings, data => data.get('language') || 'en');
export const getWebpage = createSelector(appSettings, data => data.get('webpage') || {});
export const getFooter = createSelector(appSettings, data => data.get('page') || '');

export const getGraphqlEndpoint = createSelector(appSettings, data => data.get('graphql') || '');
export const getGraphqlAuth = createSelector(appSettings, data => data.get('graphqlAuth') || '');
export const getPublicGraphql = createSelector(
  appSettings,
  data => data.get('publicGraphql') || '',
);

export const getPublicGraphqlAPIKEY = createSelector(appSettings, data => data.get('apiKey') || '');

export const getActiveAccount = createSelector(appSettings, data =>
  data.getIn(['activeAccount', 'id'], ''),
);

export const getAffiliateId = createSelector(appSettings, data =>
  data.getIn(['activeAccount', 'affiliateId'], ''),
);
export const getActiveTenant = createSelector(appSettings, data => data.get('tenantId') || '');
export const getPublicKey = createSelector(appSettings, data => data.get('publicKey') || {});
export const getIsMultiLang = createSelector(appSettings, data => data.get('isMultiLang') || false);
export const getLocale = createSelector(appSettings, data => data.get('locale') || 'en');
export const getLanguages = createSelector(appSettings, data => data.get('languages') || []);
export const getIsCreateWalletAllowed = createSelector(
  appSettings,
  data => data.get('allowCreateWallet') || false,
);
export const getIsPaperWalletAllowed = createSelector(
  appSettings,
  data => data.get('allowPaperWallet') || false,
);

export const getAllowAffiliate = createSelector(
  appSettings,
  data => data.get('allowAffiliate') || false,
);
export const getAllowReferAFriend = createSelector(
  appSettings,
  data => data.get('allowReferAFriend') || false,
);

export const getAllowMMLogin = createSelector(
  appSettings,
  data => data.get('allowWalletLogin') || false,
);

export const getRequireFullRegistration = createSelector(
  appSettings,
  data => data.get('requireFullRegistration') || false,
);

export const getRedirect = createSelector(appSettings, data => data.get('redirect') || null);

export const getChangeLanguagePopupStatus = createSelector(appSettings, data =>
  data.getIn(['changeLanguage', 'showModal']),
);

export const getAllAccounts = createSelector(appSettings, data => data.get('accounts') || []);
export const getActiveAccountName = createSelector(appSettings, data =>
  data.getIn(['activeAccount', 'name'], ''),
);

export const getDomain = createSelector(appSettings, data =>
  data.getIn(['portal', 'myPortal', 'tenant', 'domain'], {}),
);

export const getIsMarketPlaceActive = createSelector(appSettings, data =>
  data.getIn(['isMarketPlaceActive'], false),
);

export const getIsGovernanceActive = createSelector(appSettings, data =>
  data.getIn(['isGovernanceActive'], false),
);

export const getRequireAffiliateLink = createSelector(appSettings, data =>
  data.getIn(['requireAffiliateLink'], false),
);
export const getAllowWalletLogin = createSelector(appSettings, data =>
  data.getIn(['allowWalletLogin'], false),
);

export const getTenant = createSelector(appSettings, data =>
  data.getIn(['portal', 'me', 'tenant'], {}),
);

export const getOfferings = createSelector(appSettings, data =>
  data.getIn(['portal', 'me', 'offerings', 'items'], []),
);
export const getActivities = createSelector(appSettings, data =>
  data.getIn(['portal', 'me', 'activities', 'items'], []),
);

export const getLocation = state => state.get('router', new Map()).location;
// export const getLocation = createSelector(router, data => data.getIn(['location', 'pathname']));

export const getPageHierarchy = createSelector(
  appSettings,
  data => data.get('pageHierarchy') || [],
);
