import { actionsTypes } from './constants';

export const onboardingStatusActions = {
  pending: payload => ({
    type: actionsTypes.ONBOARDING_STATUS.PENDING,
    payload,
  }),
  success: ({ onboardingDone }) => ({
    type: actionsTypes.ONBOARDING_STATUS.SUCCESS,
    payload: { onboardingDone },
  }),
  error: () => ({
    type: actionsTypes.ONBOARDING_STATUS.ERROR,
  }),
};

export const updateOnboardingActions = {
  pending: payload => ({
    type: actionsTypes.UPDATE_ONBOARDING.PENDING,
    payload,
  }),
  success: ({ steps, step, schema, data }) => ({
    type: actionsTypes.UPDATE_ONBOARDING.SUCCESS,
    payload: { steps, step, schema, data },
  }),
  error: () => ({
    type: actionsTypes.UPDATE_ONBOARDING.ERROR,
  }),
};

export const fetchTermsAction = {
  pending: payload => ({
    type: actionsTypes.FETCH_TERMS.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.FETCH_TERMS.SUCCESS,
    payload: { data },
  }),
  error: () => ({
    type: actionsTypes.FETCH_TERMS.ERROR,
  }),
};

export const resetValues = () => ({ type: actionsTypes.CLEAR_VALUES });
export const resetTerms = () => ({ type: actionsTypes.CLEAR_TERMS });

export const setMinimumInvestment = amount => ({
  type: actionsTypes.SET_MIN_INVEST_AMOUNT,
  payload: { amount },
});

export const setOnboardingState = onboardingState => ({
  type: actionsTypes.SET_ONBOARDNIG,
  payload: { onboardingState },
});
