import { actionsTypes } from './constants';

export const fetchDashboardDataActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_DASHBOARD.PENDING,
    payload,
  }),
  success: ({ offerings, activities, actions, featuredDetails, type, featuredActivities }) => ({
    type: actionsTypes.FETCH_DASHBOARD.SUCCESS,
    payload: { offerings, activities, actions, featuredDetails, type, featuredActivities },
  }),
  error: () => ({
    type: actionsTypes.FETCH_DASHBOARD.ERROR,
  }),
};
