import { fromJS } from 'immutable';
import { STATUS } from 'src/constants/Status';
import { actionsTypes } from './constants';

const terms = fromJS({
  status: STATUS.LOADING,
  data: [],
});

const initialState = fromJS({
  status: STATUS.LOADING,
  onboardingDone: false,
  onboardingState: null,
  step: 0,
  steps: 0,
  terms,
});

const orderReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionsTypes.ONBOARDING_STATUS.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.ONBOARDING_STATUS.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .set('onboardingDone', payload.onboardingDone || false);
    case actionsTypes.ONBOARDING_STATUS.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.UPDATE_ONBOARDING.PENDING:
      return state.set('status', STATUS.UPDATING);
    case actionsTypes.UPDATE_ONBOARDING.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .set('steps', payload.steps || {})
        .set('schema', payload.schema || {})
        .set('data', payload.data || {})
        .set('step', payload.step || 1);
    case actionsTypes.UPDATE_ONBOARDING.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.SET_STEP:
      return state.set('step', payload.step);
    case actionsTypes.SET_ONBOARDNIG:
      return state.set('onboardingState', payload.onboardingState);

    case actionsTypes.CLEAR_TERMS:
      return state.set('terms', terms);
    case actionsTypes.CLEAR_VALUES:
      return initialState;

    default:
      return state;
  }
};
export default orderReducer;
