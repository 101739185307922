import { asyncActionType } from 'src/utils/reduxActions';

export const NAMESPACE = 'APP SETTINGS';

const TOGGLE_SIDEBAR = `${NAMESPACE} - TOGGLE SIDEBAR`;
const ACTIVE_ACCOUNT = `${NAMESPACE} - SET ACTIVE ACCOUNT`;
const SET_TENANT_METADATA = `${NAMESPACE} - SET TENANT METADATA`;
const SET_WEBPAGE = `${NAMESPACE} - SET WEBPAGE`;
const ACTIVE_LANGUAGE = `${NAMESPACE} - SET LANGUAGE TENANT`;
const FETCH_PORTAL = asyncActionType(`${NAMESPACE} FETCH PORTAL`);
const FETCH_PAGE = asyncActionType(`${NAMESPACE} FETCH PAGE`);
const SWITCH_LANGUAGE_MODAL = `${NAMESPACE} - SWITCH_LANGUAGE_MODAL`;
const RESET_APP_SETTING = `${NAMESPACE} - RESET_APP_SETTING`;
const SET_IS_MARKET_PLACE_AVAILABLE = `${NAMESPACE} - SET_IS_MARKET_PLACE_AVAILABLE`;
const SET_IS_GOVERNANCE_AVAILABLE = `${NAMESPACE} - SET_IS_GOVERNANCE_AVAILABLE`;
const SET_REQUIRE_AFFILIATELINK = `${NAMESPACE} - SET_REQUIRE_AFFILIATELINK`;
const SET_ALLOW_WALLETLOGIN = `${NAMESPACE} - SET_ALLOW_WALLETLOGIN`;
const SET_WEBPAGE_DETAILS = `${NAMESPACE} - SET_WEBPAGE_DETAILS`;
const SET_REDIRECT = `${NAMESPACE} - SET_REDIRECT`;
const SET_PAGE_HIERARCHY = `${NAMESPACE} - SET_PAGE_HIERARCHY`;
const SET_GRAPHQL = `${NAMESPACE} - SET_GRAPHQL`;
const SET_GRAPHQL_PUBLIC = `${NAMESPACE} - SET_PUBLIC_GRAPHQL`;
const SET_TENANTID = `${NAMESPACE} - SET_TENANTID`;
const SET_PORTALABILITY = `${NAMESPACE} - SET_PORTALABILITY`;

export const actionsTypes = {
  TOGGLE_SIDEBAR,
  ACTIVE_ACCOUNT,
  ACTIVE_LANGUAGE,
  SET_TENANT_METADATA,
  FETCH_PORTAL,
  SWITCH_LANGUAGE_MODAL,
  SET_WEBPAGE,
  FETCH_PAGE,
  RESET_APP_SETTING,
  SET_IS_MARKET_PLACE_AVAILABLE,
  SET_IS_GOVERNANCE_AVAILABLE,
  SET_REQUIRE_AFFILIATELINK,
  SET_ALLOW_WALLETLOGIN,
  SET_WEBPAGE_DETAILS,
  SET_REDIRECT,
  SET_PAGE_HIERARCHY,
  SET_GRAPHQL,
  SET_GRAPHQL_PUBLIC,
  SET_TENANTID,
  SET_PORTALABILITY,
};
