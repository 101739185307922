import { asyncActionType } from 'src/utils/reduxActions';

export const NAMESPACE = 'ACCOUNT';

const FETCH_ACCOUNT = asyncActionType(`${NAMESPACE} FETCH`);
const FETCH_ACCOUNT_PORTFOLIO = asyncActionType(`${NAMESPACE} FETCH PORTFOLIO`);
const FETCH_ACCOUNT_ASSET = asyncActionType(`${NAMESPACE} FETCH ASSET`);
const FETCH_ACCOUNT_ORDERS = asyncActionType(`${NAMESPACE} FETCH ORDERS`);
const FETCH_ACCOUNT_OPENORDERS = asyncActionType(`${NAMESPACE} FETCH OPEN ORDERS`);
const FETCH_ACCOUNT_TRANSFERS = asyncActionType(`${NAMESPACE} FETCH TRANSFERS`);
const FETCH_ACCOUNT_TRANSFER = asyncActionType(`${NAMESPACE} FETCH TRANSFER`);

const FETCH_ACCOUNT_PAYMENTS = asyncActionType(`${NAMESPACE} FETCH PAYMENTS`);
const FETCH_ACCOUNT_PAYMENT = asyncActionType(`${NAMESPACE} FETCH PAYMENTS`);
const CLEAR_ACCOUNT_PAYMENT_VALUES = `${NAMESPACE} CLEAR_ACCOUNT_PAYMENT_VALUES`;

const ADD_ACCOUNT = asyncActionType(`${NAMESPACE} ADD`);
const SET_ACCOUNT_TYPE = asyncActionType(`${NAMESPACE} SET TYPE`);
const UPDATE_ACCOUNT = asyncActionType(`${NAMESPACE} UPDATE`);
const UPDATE_INVESTOR_TYPE = asyncActionType(`${NAMESPACE} UPDATE_INVESTOR_TYPE`);
const UPDATE_INVESTMENTOBJECTIVES = asyncActionType(`${NAMESPACE} UPDATE_INVESTMENTOBJECTIVES`);
const UPDATE_KNOWLEDGE = asyncActionType(`${NAMESPACE} UPDATE_KNOWLEDGE`);
const UPDATE_FINANCIALSITUATION = asyncActionType(`${NAMESPACE} UPDATE_FINANCIALSITUATION`);
const UPDATE_REFERENCEBANK = asyncActionType(`${NAMESPACE} UPDATE_REFERENCEBANK`);
const UPDATE_AML = asyncActionType(`${NAMESPACE} UPDATE_AML`);

const UPDATE_ACCOUNT_EMAIL = asyncActionType(`${NAMESPACE} UPDATE EMAIL`);
const UPDATE_ACCOUNT_MOBILE = asyncActionType(`${NAMESPACE} UPDATE MOBILE`);
const UPDATE_ACCOUNT_PAPERWALLET = asyncActionType(`${NAMESPACE} UPDATE PAPERWALLET`);
const CONFIRM_LINK_PAYMENT_ACCOUNT = asyncActionType(`${NAMESPACE} LINK PAYMENT ACCOUNT`);
const CONFIRM_UPDATE_EMAIL_ACCOUNT = asyncActionType(`${NAMESPACE} EMAIL UPDATE CONFIRM`);
const CONFIRM_UPDATE_EMAIL_PIN_ACCOUNT = asyncActionType(`${NAMESPACE} EMAIL UPDATE PIN CONFIRM`);
const CONFIRM_UPDATE_MOBILE_ACCOUNT = asyncActionType(`${NAMESPACE} MOBILE UPDATE CONFIRM`);
const ADD_BLOCKCHAINWALLET = asyncActionType(`${NAMESPACE} ADD BLOCKCHAINWALLET`);
const TRANSFER_TOKEN_FROM_BLOCKCHAINWALLET = asyncActionType(`${NAMESPACE} TRANSFER TOKEN`);
const CHECKSIGNATURE_BLOCKCHAINWALLET = asyncActionType(`${NAMESPACE} ADD CHECK SIGNATURE`);
const CHANGE_PROFILE_PASSWORD = asyncActionType(`${NAMESPACE} CHANGE PASSWORD`);
const SET_MAINBLOCKCHAINWALLET = asyncActionType(`${NAMESPACE} SET MAIN BLOCKCHAIN WALLET`);
const SWITCH_ACCOUNT_TYPE = `${NAMESPACE}  SWITCH_ACCOUNT_TYPE`;
const CREATE_ACCOUNT_MODAL = `${NAMESPACE} - CREATE_ACCOUNT_MODAL`;
const SWITCH_ACCOUNT_MODAL = `${NAMESPACE} - SWITCH_ACCOUNT_MODAL`;
const TOGGLE_ORDER_DETAILS_MODAL = `${NAMESPACE} TOGGLE_ORDER_DETAILS_MODAL`;
const SET_SELECTED_ORDER = `${NAMESPACE} SET_SELECTED_ORDER`;

const GET_SIGND_DETAILS = asyncActionType(`${NAMESPACE} GET_SIGND_DETAILS`);
const CONFIRM_SIGND = asyncActionType(`${NAMESPACE} CONFIRM SIGND`);

const FETCH_GUBBI_KYC = asyncActionType(`${NAMESPACE} FETCH GUBBI KYC`);

const FETCH_SUMSUB_KYC = asyncActionType(`${NAMESPACE} FETCH SUMSUB KYC`);

const FETCH_WALLET = asyncActionType(`${NAMESPACE} FETCH WALLET`);

// My Orders
const FETCH_MY_ORDERS = asyncActionType(`${NAMESPACE} FETCH My_ORDERS`);
const FETCH_ORDER_DETAILS = asyncActionType(`${NAMESPACE} FETCH ORDER_DETAILS`);

const SET_STEP = `${NAMESPACE} STEP`;
const SET_WALLETADD_STEP = `${NAMESPACE} STEP WALLETADD`;
const SET_WALLETTRANSFER_STEP = `${NAMESPACE} STEP WALLETTRANSFER`;
const SET_WALLETTRANSFER_TRANSACTION = `${NAMESPACE} TRANSACTION WALLETTRANSFER`;
const CLEAR_VALUES = `${NAMESPACE} CLEAR_VALUES`;
const CLEAR_TRANSACTIONS = `${NAMESPACE} CLEAR_VALUES TRANSACTIONS`;

const SET_WALLETLIST_STEP = `${NAMESPACE} STEP LIST STEP`;
const SET_WALLETLIST_TRANSACTION = `${NAMESPACE} LIST TRANSACTION`;
const LIST_TOKENONMARKET = asyncActionType(`${NAMESPACE} ListToken`);

export const actionsTypes = {
  FETCH_ACCOUNT,
  FETCH_ACCOUNT_PORTFOLIO,
  FETCH_ACCOUNT_ORDERS,
  FETCH_ACCOUNT_OPENORDERS,
  FETCH_ACCOUNT_ASSET,
  FETCH_ACCOUNT_TRANSFERS,
  FETCH_ACCOUNT_TRANSFER,
  FETCH_WALLET,

  FETCH_ACCOUNT_PAYMENTS,
  FETCH_ACCOUNT_PAYMENT,
  CLEAR_ACCOUNT_PAYMENT_VALUES,

  SET_ACCOUNT_TYPE,

  UPDATE_ACCOUNT,
  UPDATE_INVESTOR_TYPE,
  UPDATE_INVESTMENTOBJECTIVES,
  UPDATE_KNOWLEDGE,
  UPDATE_FINANCIALSITUATION,
  UPDATE_REFERENCEBANK,
  UPDATE_ACCOUNT_EMAIL,
  UPDATE_ACCOUNT_PAPERWALLET,
  UPDATE_ACCOUNT_MOBILE,
  UPDATE_AML,
  CONFIRM_UPDATE_MOBILE_ACCOUNT,

  CONFIRM_UPDATE_EMAIL_ACCOUNT,
  CONFIRM_UPDATE_EMAIL_PIN_ACCOUNT,
  ADD_ACCOUNT,
  ADD_BLOCKCHAINWALLET,
  TRANSFER_TOKEN_FROM_BLOCKCHAINWALLET,
  CONFIRM_LINK_PAYMENT_ACCOUNT,
  CHECKSIGNATURE_BLOCKCHAINWALLET,
  SET_MAINBLOCKCHAINWALLET,
  SET_STEP,
  SET_WALLETADD_STEP,
  SET_WALLETTRANSFER_STEP,
  SET_WALLETTRANSFER_TRANSACTION,
  CLEAR_VALUES,
  CLEAR_TRANSACTIONS,
  SWITCH_ACCOUNT_TYPE,
  CREATE_ACCOUNT_MODAL,
  SWITCH_ACCOUNT_MODAL,
  CHANGE_PROFILE_PASSWORD,

  FETCH_GUBBI_KYC,

  GET_SIGND_DETAILS,
  CONFIRM_SIGND,

  FETCH_SUMSUB_KYC,

  // My orders
  FETCH_MY_ORDERS,
  TOGGLE_ORDER_DETAILS_MODAL,
  FETCH_ORDER_DETAILS,
  SET_SELECTED_ORDER,

  SET_WALLETLIST_STEP,
  SET_WALLETLIST_TRANSACTION,
  LIST_TOKENONMARKET,
};

// General
export const ButtonList = [
  {
    name: 'Individual',
    value: 'Individual',
  },
  {
    name: 'Company',
    value: 'Company',
  },
];

export const ACCOUNT_TYPES = {
  INDIVIDUAL: 'Individual',
  COMPANY: 'Company',
};
