import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
import { showError } from 'src/components/shared/Notification/actions';

import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';
import { actionsTypes } from './constants';
import {
  fetchAffiliateActions,
  activateReferralAction,
  fetchOrdersActions,
  fetchSignupsActions,
  fetchAffiliatedAccountActions,
} from './affiliate.action';
import { getAffiliateOrdersLimit, getAffiliateOrdersToken } from './affiliate.selector';

export function* fetchAccountData() {
  let params = {
    accountId: {
      eq: localStorage.getItem('activeAccount'),
    },
  };

  try {
    const response = yield call([API, 'graphql'], graphqlOperation(queries.readAffiliate, params));
    const account = {
      ...response.data.myPortal.accounts.items[0].account,
      ...response.data.myPortal.accounts.items[0].account.address,
    };

    const affiliate = response.data.myPortal.accounts.items[0].account.affiliate;

    yield put(
      fetchAffiliateActions.success({
        affiliate,
        account,
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line

    yield put(fetchAffiliateActions.error());
  }
}

export function* fetchOrdersData({ payload }) {
  try {
    const nextToken = yield select(getAffiliateOrdersToken);
    const limit = yield select(getAffiliateOrdersLimit);

    const query = {
      accountId: {
        eq: localStorage.getItem('activeAccount'),
      },
      limit,
      nextToken: nextToken !== '' ? nextToken : null,
    };

    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(queries.readAffiliateOrders, query),
    );

    const _nextToken =
      response.data.myPortal.accounts.items[0].account.affiliate !== null &&
      response.data.myPortal.accounts.items[0].account.affiliate.orders !== null
        ? response.data.myPortal.accounts.items[0].account.affiliate.orders.nextToken
        : null;
    const data =
      response.data.myPortal.accounts.items[0].account.affiliate !== null &&
      response.data.myPortal.accounts.items[0].account.affiliate.orders !== null
        ? response.data.myPortal.accounts.items[0].account.affiliate.orders.items
        : [];

    yield put(
      fetchOrdersActions.success({
        data,
        nextToken: _nextToken,
      }),
    );

    if (_nextToken !== undefined && _nextToken !== null && _nextToken !== '' && data.length < 20) {
      yield put(fetchOrdersActions.pending());
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchOrdersActions.error());
  }
}

export function* fetchSignupsData({ payload }) {
  try {
    const nextToken = yield select(getAffiliateOrdersToken);
    const limit = yield select(getAffiliateOrdersLimit);

    const query = {
      accountId: {
        eq: localStorage.getItem('activeAccount'),
      },
      limit,
      nextToken: nextToken !== '' ? nextToken : null,
    };

    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(queries.readAffiliateSignups, query),
    );

    const _nextToken =
      response.data.myPortal.accounts.items[0].account.affiliate !== null &&
      response.data.myPortal.accounts.items[0].account.affiliate.signups !== null
        ? response.data.myPortal.accounts.items[0].account.affiliate.signups.nextToken
        : null;
    const data =
      response.data.myPortal.accounts.items[0].account.affiliate !== null &&
      response.data.myPortal.accounts.items[0].account.affiliate.signups !== null
        ? response.data.myPortal.accounts.items[0].account.affiliate.signups.items
        : [];

    yield put(
      fetchSignupsActions.success({
        data,
        nextToken: _nextToken,
      }),
    );

    if (_nextToken !== undefined && _nextToken !== null && _nextToken !== '' && data.length < 20) {
      yield put(fetchSignupsActions.pending());
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchSignupsActions.error());
  }
}

export function* fetchAffiliatedAccountData({ payload }) {
  try {
    const query = {
      accountId: {
        eq: localStorage.getItem('activeAccount'),
      },
      partnerAccountId: payload,
    };

    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(queries.readAffiliatedAccount, query),
    );

    const data =
      response.data.myPortal.accounts.items[0].account.affiliate !== null &&
      response.data.myPortal.accounts.items[0].account.affiliate.partner !== null
        ? response.data.myPortal.accounts.items[0].account.affiliate.partner
        : {};

    yield put(
      fetchAffiliatedAccountActions.success({
        data,
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchAffiliatedAccountActions.error());
  }
}

export function* activateReferralData({ payload }) {
  try {
    const params = {
      accountId: payload,
    };

    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.activateReferral, params),
    );
    yield put(
      activateReferralAction.success({
        account: response.data.activateReferral,
        affiliate: response.data.activateReferral.affiliate,
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(activateReferralAction.error());
    yield put(
      showError({
        title: 'view_affiliate:Notification.Error.Title',
        message: `view_affiliate:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.message
        }`,
      }),
    );
  }
}

export default function* appSeetingsSagas() {
  yield all([takeLatest(actionsTypes.FETCH_AFFILIATE.PENDING, fetchAccountData)]);
  yield all([takeLatest(actionsTypes.ACTIVATE_AFFILIATE.PENDING, activateReferralData)]);
  yield all([takeLatest(actionsTypes.FETCH_ORDERS.PENDING, fetchOrdersData)]);
  yield all([takeLatest(actionsTypes.FETCH_SIGNUPS.PENDING, fetchSignupsData)]);
  yield all([takeLatest(actionsTypes.FETCH_AFFILIATEDACCOUNT.PENDING, fetchAffiliatedAccountData)]);
}
