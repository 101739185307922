export const readMessages = /* GraphQL */ `
  query Portal(
    $accountId: ModelIDKeyConditionInput
    $folderId: ID!
    $nextToken: String
    $sortDirection: String
  ) {
    myPortal {
      id
      tenantId
      accounts(accountId: $accountId) {
        items {
          status
          accountId
          account {
            id

            messages(folderId: $folderId, nextToken: $nextToken, sortDirection: $sortDirection) {
              items {
                tenantId
                id

                title
                content
                timestamp
              }
              nextToken
            }
          }
        }
      }
    }
  }
`;
