import { fromJS } from 'immutable';
import { STATUS } from 'src/constants/Status';
import { actionsTypes } from './constants';

const initialState = fromJS({
  status: STATUS.LOADING,
});

const accountReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionsTypes.FETCH_REFERRAL.PENDING:
      return state.set(
        'status',
        payload !== undefined && payload.silent ? STATUS.SUCCESS : STATUS.LOADING,
      );
    case actionsTypes.FETCH_REFERRAL.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .set('referral', payload.referral || {})
        .set('account', payload.account || {});
    case actionsTypes.FETCH_REFERRAL.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.ACTIVATE_REFERRAL.PENDING:
      return state.set('status', STATUS.UPDATING);
    case actionsTypes.ACTIVATE_REFERRAL.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .set('account', payload.account || {})
        .set('referral', payload.referral || {});
    case actionsTypes.ACTIVATE_REFERRAL.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.CLEAR_VALUES:
      return initialState;

    default:
      return state;
  }
};
export default accountReducer;
