// analytics.js
import Analytics from 'analytics';
// import originalSrc from 'analytics-plugin-original-source';
import { isEmpty } from 'lodash';
import googleAnalytics from '@analytics/google-analytics';
import googleTagManager from '@analytics/google-tag-manager';
// import SimplyAnalytics from './analytics/SimplyAnalytics';

// const plugins = [originalSrc(), SimplyAnalytics];
const plugins = [];
const isEnabled = localStorage.getItem('doNotTrack') ? false : true;

if (!isEmpty(localStorage.getItem('measurementIds')))
  plugins.push(
    googleAnalytics({
      measurementIds: [localStorage.getItem('measurementIds')],
      enabled: isEnabled,
    }),
  );

if (!isEmpty(localStorage.getItem('containerId')))
  plugins.push(
    googleTagManager({
      containerId: localStorage.getItem('containerId'),
      enabled: isEnabled,
    }),
  );

const analyticsInstance = Analytics({
  app: 'my-app',
  plugins,
});

export default analyticsInstance;
