import { asyncActionType } from 'src/utils/reduxActions';

export const NAMESPACE = 'AFFILIATE';

const FETCH_AFFILIATE = asyncActionType(`${NAMESPACE} FETCH`);
const FETCH_AFFILIATEDACCOUNT = asyncActionType(`${NAMESPACE} FETCH ACCOUNT`);
const FETCH_SIGNUPS = asyncActionType(`${NAMESPACE} FETCH SIGNUPS`);
const FETCH_ORDERS = asyncActionType(`${NAMESPACE} FETCH ORDERS`);
const ACTIVATE_AFFILIATE = asyncActionType(`${NAMESPACE} ACTIVATE`);

const CLEAR_VALUES = `${NAMESPACE} CLEAR_VALUES`;
const CLEAR_ORDERS = `${NAMESPACE} CLEAR ORDERS`;
const CLEAR_SIGNUPS = `${NAMESPACE} CLEAR SIGNUPS`;

export const actionsTypes = {
  FETCH_AFFILIATE,
  ACTIVATE_AFFILIATE,

  FETCH_SIGNUPS,
  FETCH_ORDERS,

  FETCH_AFFILIATEDACCOUNT,

  CLEAR_VALUES,
  CLEAR_ORDERS,
  CLEAR_SIGNUPS,
};
