export const activateReferral = /* GraphQL */ `
  mutation ActivateReferral($accountId: ID!, $active: Boolean) {
    activateReferral(accountId: $accountId, active: $active) {
      tenantId
      id
      name
      affiliate {
        referralLink
      }
    }
  }
`;
