export const addIpOfferingOrder = /* GraphQL */ `
  mutation AddIpOfferingOrder($accountId: ID!, $input: OrderOfferingInput) {
    addIPOfferingOrder(accountId: $accountId, input: $input) {
      tenantId
      transactionId
      accountId
      offeringId

      amount
      amountBonus
      status

      orderData {
        orderDate
        pricePerToken
        amountInCurrency
        currency

        orderValid
        fundingPricePerTokenList {
          currency
          price
          vatPercentage
          bruttoPrice
          includedVAT
          nettoPrice
        }
        gasPriceList {
          currency
          bruttoPrice
          nettoPrice
          includedVAT
        }
        accountType
        company {
          name
        }
        individual {
          gender
          firstName
          lastName
          dateOfBirth
          nationality
        }
        address {
          street
          postalCode
          city
          state
          country
        }
      }
    }
  }
`;
export const updateIpOfferingOrder = /* GraphQL */ `
  mutation UpdateIpOfferingOrder($accountId: ID!, $orderId: ID!, $input: OrderOfferingInput) {
    updateIPOfferingOrder(accountId: $accountId, orderId: $orderId, input: $input) {
      tenantId
      transactionId
      accountId
      offeringId
      amount
      amountBonus
      status

      paymentCode
      orderPaymentServiceId

      orderData {
        orderDate
        pricePerToken
        amountInCurrency
        currency

        orderValid
        fundingPricePerTokenList {
          currency
          price
          vatPercentage
          bruttoPrice
          includedVAT
          nettoPrice
        }
        gasPriceList {
          currency
          bruttoPrice
          nettoPrice
          includedVAT
        }
        accountType
        company {
          name
        }
        individual {
          gender
          firstName
          lastName
          dateOfBirth
          nationality
        }
        address {
          street
          postalCode
          city
          state
          country
        }
      }
    }
  }
`;
export const confirmIpOfferingOrder = /* GraphQL */ `
  mutation ConfirmIpOfferingOrder($accountId: ID!, $orderId: ID!, $input: OrderOfferingInput) {
    confirmIPOfferingOrder(accountId: $accountId, orderId: $orderId, input: $input) {
      tenantId
      transactionId
      accountId
      offeringId
      amount
      amountBonus
      status

      paymentCode

      orderPaymentServiceId
      orderData {
        orderDate
        pricePerToken
        amountInCurrency
        currency
        orderValid
        fundingPricePerTokenList {
          currency
          price
        }
        accountType
        company {
          name
        }
        individual {
          gender
          firstName
          lastName
          dateOfBirth
          nationality
        }
        address {
          street
          postalCode
          city
          state
          country
        }
      }
    }
  }
`;
export const cancelIpOfferingOrder = /* GraphQL */ `
  mutation cancelIPOfferingOrder($accountId: ID!, $orderId: ID!) {
    cancelIPOfferingOrder(accountId: $accountId, orderId: $orderId) {
      tenantId
      status
    }
  }
`;

export const whitelistIpOfferingOrder = /* GraphQL */ `
  mutation WhitelistIpOfferingOrder($accountId: ID!, $input: OrderOfferingInput) {
    whitelistIPOfferingOrder(accountId: $accountId, input: $input) {
      tenantId
      transactionId
      accountId
      offeringId

      status
    }
  }
`;

export const createOrder = /* GraphQL */ `
  mutation CreateOrder($accountId: ID!, $input: OrderInput) {
    createOrder(accountId: $accountId, input: $input) {
      tenantId
      transactionId
      onboardingDone
      paymentDataList
      status
      offering {
        name
        symbol
      }
    }
  }
`;

export const confirmOrder = /* GraphQL */ `
  mutation ConfirmOrder($accountId: ID!, $orderId: ID!, $input: OrderInput) {
    confirmOrder(accountId: $accountId, orderId: $orderId, input: $input) {
      tenantId
      transactionId
      onboardingDone
      paymentDataList
      status
    }
  }
`;
