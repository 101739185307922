import createSelector from 'src/utils/reselect';
import { Map } from 'immutable';

const account = state => state.get('account', new Map());

export const getAccountStatus = createSelector(account, data => data.get('status'));
export const getAccountUpdateStatus = createSelector(account, data => data.get('statusUpdate'));
export const getAccountPortfolioStatus = createSelector(account, data =>
  data.get('statusPortfolio'),
);

export const getStep = createSelector(account, data => data.get('step') || '1');
export const getWalletAddStep = createSelector(account, data => data.get('stepWalletAdd') || '1');
export const getWalletTransferStep = createSelector(
  account,
  data => data.get('stepWalletTransfer') || '1',
);
export const getWalletTransferTransaction = createSelector(
  account,
  data => data.get('walletTransaction') || {},
);

export const getAccountData = createSelector(account, data => data.get('account') || {});
export const getOpenOrdersData = createSelector(account, data => data.get('openOrders') || {});
export const getUsersData = createSelector(account, data => data.get('users') || []);
export const getAccountWalletData = createSelector(account, data => data.get('wallet') || {});
export const getAccountType = createSelector(account, data => data.get('accountType'));
export const getFullRegistrationRequired = createSelector(
  getAccountData,
  data => data?.requireFullRegistration || false,
);
export const getBlockchainWalletsData = createSelector(
  account,
  data => data.get('blockchainWallets') || [],
);
export const getBlockchainWalletData = createSelector(
  account,
  data => data.get('blockchainWallet') || [],
);

export const getAssetsData = createSelector(account, data => data.get('assets') || []);
export const getPortfolioTransactionsData = createSelector(
  account,
  data => data.get('transactions') || [],
);

export const getCreateAccountPopupStatus = createSelector(account, data =>
  data.getIn(['createAccount', 'showModal']),
);

export const getCreateAccountStatus = createSelector(account, data =>
  data.getIn(['createAccount', 'status']),
);

// Switch Account
export const getSwitchAccountPopupStatus = createSelector(account, data =>
  data.getIn(['switchAccount', 'showModal']),
);
export const getSwitchAccountStatus = createSelector(account, data =>
  data.getIn(['switchAccount', 'status']),
);

export const getChangePasswordStatus = createSelector(account, data =>
  data.get('changePasswordStatus'),
);

// My Orders
export const getOrders = createSelector(account, data => data.get('orders'));
export const getOrdersData = createSelector(getOrders, data => data.get('data') || []);

const getOrder = createSelector(account, data => data.get('order'));
export const getOrderDetailsStatus = createSelector(getOrder, data => data.get('status'));
export const getOrderDetailsShowModal = createSelector(getOrder, data => data.get('showModal'));
export const getSelectedOrderId = createSelector(getOrder, data => data.get('selectedOrder'));
export const getOrderDetails = createSelector(getOrder, data => data.get('details'));

// My Asset

const getAsset = createSelector(account, data => data.get('asset'));
export const getAssetStatus = createSelector(getAsset, data => data.get('status'));
export const getAssetData = createSelector(getAsset, data => data.get('data'));
export const getAssetsNextToken = createSelector(getAsset, data => data.get('nextToken'));
export const getAssetNextToken = createSelector(getAsset, data => data.get('assetNextToken'));
export const getAssetStatistics = createSelector(getAsset, data => data.get('statistics'));

// TransferToken
const getTransferToken = createSelector(account, data => data.get('transfer'));
export const getTransferTokenStatus = createSelector(getTransferToken, data => data.get('status'));
export const getTransferData = createSelector(getTransferToken, data => data.get('data'));
export const getTransferNextToken = createSelector(getTransferToken, data => data.get('nextToken'));

// KYC signD
export const getSignDDetails = createSelector(account, data => data.get('kycSignD') || {});

// KYC Sumsub
export const getSumsubDetails = createSelector(account, data => data.get('kycSumsub') || {});

// KYC signD
const getGubbiKYZ = createSelector(account, data => data.get('gubbiKYC'));

export const getGubbiKYCStatus = createSelector(getGubbiKYZ, data => data.get('status'));
export const getGubbiKYCUrl = createSelector(getGubbiKYZ, data => data.get('url'));

// My Orders
export const getTransfers = createSelector(account, data => data.get('transfers') || []);

export const getTransferStatus = createSelector(getTransfers, data => data.get('status'));
export const getTransfersData = createSelector(getTransfers, data => data.get('data'));

// Listing Steps

export const getWalletListStep = createSelector(account, data => data.get('stepWalletList') || '1');
export const getWalletListingTransaction = createSelector(
  account,
  data => data.get('walletListing') || {},
);

// Listing Token
const getListingToken = createSelector(account, data => data.get('listing'));
export const getListingStatus = createSelector(getListingToken, data => data.get('status'));
export const getListingData = createSelector(getListingToken, data => data.get('data'));

// Payments
const getPayments = createSelector(account, data => data.get('payments'));
export const getPaymentsStatus = createSelector(getPayments, data => data.get('status'));
export const getPaymentsData = createSelector(getPayments, data => data.get('data'));
