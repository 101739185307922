export const getGiveaways = /* GraphQL */ `
  query Portal(
    $id: ModelIDKeyConditionInput
    $filter: ModelGiveawayFilterInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    myPortal {
      tenantId
      giveaways(
        id: $id
        filter: $filter
        sortDirection: $sortDirection
        limit: $limit
        nextToken: $nextToken
      ) {
        nextToken
        items {
          tenantId
          id
          name
          description
          overview
          heroImage
          overviewImage
        }
      }
    }
  }
`;
