import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
import { isEmpty } from 'lodash';
import { actionsTypes } from './constants';
import { fetchCommentAction, fetchCommentsAction } from './communication.action';

import * as queries from './graphql/queries';
import { getCommentsData, getCommentsNextToken } from './communication.selector';

export function* fetchCommentsData({ payload }) {
  const nextToken = yield select(getCommentsNextToken);
  const messages = yield select(getCommentsData);

  try {
    const params = {
      channelId: payload,
      nextToken: !isEmpty(nextToken) ? nextToken : null,
      byRating: true,
    };
    const response = yield call([API, 'graphql'], graphqlOperation(queries.readComments, params));
    const _data = response.data.myPortal.channel.comments.items;
    const _nextToken = response.data.myPortal.channel.comments.nextToken;

    yield put(
      fetchCommentsAction.success({
        data: _data,
        nextToken: _nextToken,
      }),
    );

    const elements = messages.length + _data.length;
    if (!isEmpty(_nextToken) && elements < 50) {
      yield put(fetchCommentsAction.pending());
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchCommentsAction.error());
  }
}

export function* fetchCommentData({ payload }) {
  // const nextToken = yield select(getCommentsNextToken);
  // const replies = [] ; // yield select(getCommentsData);

  // console.log(nextToken)
  // console.log(replies)

  try {
    const params = {
      channelId: payload.channelId,
      commentId: payload.commentId,
      // nextToken: !isEmpty(nextToken) ? nextToken : null,
    };

    const response = yield call([API, 'graphql'], graphqlOperation(queries.readComment, params));
    const _data = response.data.myPortal.channel.comment;
    const _id = response.data.myPortal.channel.comment.id;
    const _nextToken = response.data.myPortal.channel.comment.replies?.nextToken;
    const _replies = response.data.myPortal.channel.comment.replies?.items;

    yield put(
      fetchCommentAction.success({
        id: _id,
        data: _data,
        replies: _replies,
        nextToken: _nextToken,
      }),
    );

    // const elements = replies.length + _data.length;
    // if (!isEmpty(_nextToken) && elements < 50) {
    //   yield put(
    //     fetchCommentAction.pending({ channelId: payload.channelId, commentId: payload.commentId }),
    //   );
    // }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchCommentAction.error());
  }
}

export default function* newsAndEventsSaga() {
  yield all([takeLatest(actionsTypes.FETCH_COMMENTS.PENDING, fetchCommentsData)]);
  yield all([takeLatest(actionsTypes.FETCH_COMMENT.PENDING, fetchCommentData)]);
}
