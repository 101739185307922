import { asyncActionType } from 'src/utils/reduxActions';

export const NAMESPACE = 'PUBLIC NEWS & EVENTS';

const FETCH_PUBLIC_ALL_NEWS_AND_EVENTS = asyncActionType(`${NAMESPACE} FETCH ALL`);
const FETCH_PUBLIC_FEATURED_NEWS_AND_EVENTS = asyncActionType(`${NAMESPACE} FETCH FEATURED`);
const FETCH_PUBLIC_OTHER_NEWS_AND_EVENTS = asyncActionType(`${NAMESPACE} FETCH OTHER`);
const FETCH_PUBLIC_DETAILS_NEWS_AND_EVENT = asyncActionType(`${NAMESPACE} FETCH DETAILS`);

export const actionsTypes = {
  FETCH_PUBLIC_ALL_NEWS_AND_EVENTS,
  FETCH_PUBLIC_FEATURED_NEWS_AND_EVENTS,
  FETCH_PUBLIC_OTHER_NEWS_AND_EVENTS,
  FETCH_PUBLIC_DETAILS_NEWS_AND_EVENT,
};
