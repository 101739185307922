import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
import { isEmpty } from 'lodash';
import { actionsTypes } from '../persistance/constants';

import {
  buyTokenActions,
  fetchBuyTokenTransactionActions,
  fetchMarketOfferActions,
  fetchMarketplaceActions,
  setBuyTokenStepActions,
} from '../persistance/marketplace.action';
import * as queries from './GraphQL/queries';
import * as mutations from './GraphQL/mutations';
import {
  getBuyTokenNextToken,
  getMarketplacesNextToken,
} from '../persistance/marketplace.selector';

export function* fetchMarketplaceSaga(payload) {
  const nextToken = yield select(getMarketplacesNextToken);
  try {
    const params = {
      // sortDirection: 'DESC', // todo need to check why it's sending empty limit when it's uncommented
      // filter: {
      //   // status: {
      //   //   eq: 'PUBLIC',
      //   // },
      //   ...payload.filter,
      // },
      limit: (payload && payload.limit) || 100,
      nextToken: nextToken !== '' ? nextToken : null,
    };
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(queries.getMarketOffers, params),
    );
    const data = response.data.myPortal.marketOffers.items;
    const _nextToken = response.data.myPortal.marketOffers.nextToken;

    yield put(fetchMarketplaceActions.success({ data, nextToken: _nextToken }));

    if (!isEmpty(_nextToken) && data.length < 10) {
      yield put(fetchMarketplaceActions.pending(payload));
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchMarketplaceActions.error());
  }
}

export function* fetchMarketOfferSaga({ payload }) {
  try {
    const params = {
      id: payload,
    };
    const response = yield call([API, 'graphql'], graphqlOperation(queries.getMarketOffer, params));
    const marketOffer = response.data.myPortal.marketOffer;

    yield put(fetchMarketOfferActions.success({ marketOffer }));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchMarketOfferActions.error());
  }
}

export function* buyTokensSaga({ payload }) {
  try {
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.buyTokenFromMarket, payload),
    );

    yield put(
      buyTokenActions.success({
        transaction: response.data.buyTokenFromMarket,
      }),
    );

    yield put(setBuyTokenStepActions(3));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(buyTokenActions.error());
  }
}

export function* fetchBuyTokenTransactionSaga({ payload }) {
  const nextToken = yield select(getBuyTokenNextToken);

  try {
    const params = {
      accountId: {
        eq: localStorage.getItem('activeAccount'),
      },
      filter: {
        transactionId: {
          // beginsWith: 'ORDER#',
          eq: payload,
        },
      },
      nextToken: nextToken !== '' ? nextToken : null,
    };

    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(queries.readAccountTransfers, params),
    );
    const transactions = response.data.myPortal.accounts.items[0].account.transactions.items;
    const _nextToken = response.data.myPortal.accounts.items[0].account.transactions.nextToken;

    yield put(
      fetchBuyTokenTransactionActions.success({
        transaction: transactions[0] || {},
        nextToken: transactions.length === 0 ? _nextToken : null,
      }),
    );
    if (!isEmpty(_nextToken) && transactions.length === 0) {
      yield put(fetchBuyTokenTransactionActions.pending(payload));
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchBuyTokenTransactionActions.error());
  }
}

export default function* newsAndEventsSaga() {
  yield all([takeLatest(actionsTypes.FETCH_MARKETPLACE.PENDING, fetchMarketplaceSaga)]);
  yield all([takeLatest(actionsTypes.FETCH_MARKETOFFER.PENDING, fetchMarketOfferSaga)]);
  yield all([takeLatest(actionsTypes.BUY_TOKENFROMMARKET.PENDING, buyTokensSaga)]);
  yield all([
    takeLatest(actionsTypes.FETCH_BUYTOKENTRANSACTION.PENDING, fetchBuyTokenTransactionSaga),
  ]);
}
