import { all, takeLatest, takeEvery, put, call, select } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
import {
  getActiveTenant,
  getPublicGraphql,
  getPublicGraphqlAPIKEY,
} from 'src/components/AppSettings/appSettings.selector';
import { actionsTypes } from './constants';
import {
  fetchDetailsAction,
  fetchFeaturedNewsAndEventsActions,
  fetchNewsAndEventsActions,
  fetchOtherNewsAndEventsActions,
} from './news.action';
import * as queries from './GraphQL/queries';

export function* fetchNewsAndEventsData({ payload }) {
  const tenantId = yield select(getActiveTenant);
  const endpointPublic = yield select(getPublicGraphql);
  const apiKey = yield select(getPublicGraphqlAPIKEY);

  try {
    const params = {
      sortDirection: 'DESC', // todo need to check why it's sending empty limit when it's uncommented
      // filter: {
      //   // status: {
      //   //   eq: 'PUBLIC',
      //   // },
      //   ...payload.filter,
      // },
      tenantId,
      limit: (payload && payload.limit) || 100,
    };

    // Create a custom API client instance with the desired endpoint
    API.configure({
      aws_appsync_graphqlEndpoint: endpointPublic,
      aws_appsync_region: 'eu-central-1',
      aws_appsync_authenticationType: 'API_KEY',
      aws_appsync_apiKey: apiKey,
    });

    const response = yield call([API, 'graphql'], graphqlOperation(queries.listActivities, params));
    const newsAndEvents = response.data.listActivities.items;

    yield put(fetchNewsAndEventsActions.success({ newsAndEvents }));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchNewsAndEventsActions.error());
  }
}

export function* fetchFeaturedNewsAndEventsData({ payload }) {
  const tenantId = yield select(getActiveTenant);
  const endpointPublic = yield select(getPublicGraphql);
  const apiKey = yield select(getPublicGraphqlAPIKEY);

  try {
    const params = {
      sortDirection: 'DESC',
      filter: {
        featured: {
          eq: 'true',
        },
        // status: {
        //   eq: 'PUBLIC',
        // },
      },
      tenantId,
      limit: payload.limit,
    };

    // Create a custom API client instance with the desired endpoint
    API.configure({
      aws_appsync_graphqlEndpoint: endpointPublic,
      aws_appsync_region: 'eu-central-1',
      aws_appsync_authenticationType: 'API_KEY',
      aws_appsync_apiKey: apiKey,
    });

    const response = yield call([API, 'graphql'], graphqlOperation(queries.listActivities, params));
    const newsAndEvents = response.data.listActivities.items;

    yield put(fetchFeaturedNewsAndEventsActions.success({ newsAndEvents }));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchFeaturedNewsAndEventsActions.error());
  }
}

export function* fetchOthersNewsAndEventsData({ payload }) {
  const tenantId = yield select(getActiveTenant);
  const endpointPublic = yield select(getPublicGraphql);
  const apiKey = yield select(getPublicGraphqlAPIKEY);

  try {
    const params = {
      sortDirection: 'DESC',
      filter: {
        or: {
          activityType: {
            eq: 'POST',
          },
        },
      },
      //   // ...payload.filter,
      // },
      tenantId,
      limit: 100,
    };
    API.configure({
      aws_appsync_graphqlEndpoint: endpointPublic,
      aws_appsync_region: 'eu-central-1',
      aws_appsync_authenticationType: 'API_KFEY',
      aws_appsync_apiKey: apiKey,
    });

    // Load 20 last POST or Events
    let countActivities = 0;
    let activityData = [];
    let nextToken = undefined;
    while (countActivities < 20 && nextToken !== null) {
      params['nextToken'] = nextToken;
      const response = yield call(
        [API, 'graphql'],
        graphqlOperation(queries.listActivities, params),
      );
      const _activityData = response.data.listActivities.items.slice(0, payload.limit);
      const _nextToken = response.data.listActivities.nextToken;
      nextToken = _nextToken;
      activityData = [...activityData, ..._activityData];
      countActivities = activityData.length;
      yield put(fetchOtherNewsAndEventsActions.success({ newsAndEvents: activityData }));
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchOtherNewsAndEventsActions.error());
  }
}
export function* fetchNewsAndEventDetails({ payload }) {
  const { id } = payload;

  const tenantId = yield select(getActiveTenant);
  const endpointPublic = yield select(getPublicGraphql);
  const apiKey = yield select(getPublicGraphqlAPIKEY);

  // Create a custom API client instance with the desired endpoint
  API.configure({
    aws_appsync_graphqlEndpoint: endpointPublic,
    aws_appsync_region: 'eu-central-1',
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: apiKey,
  });

  let params = {};
  try {
    params = {
      slug: {
        eq: id,
      },
      tenantId,
    };

    const response = yield call([API, 'graphql'], graphqlOperation(queries.getActivity, params));
    const newsAndEvent = response.data.activityBySlug.items[0];

    yield put(fetchDetailsAction.success({ newsAndEvent }));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchDetailsAction.error());
  }
}

export default function* newsAndEventsSaga() {
  yield all([
    takeEvery(actionsTypes.FETCH_PUBLIC_ALL_NEWS_AND_EVENTS.PENDING, fetchNewsAndEventsData),
    takeEvery(
      actionsTypes.FETCH_PUBLIC_FEATURED_NEWS_AND_EVENTS.PENDING,
      fetchFeaturedNewsAndEventsData,
    ),
    takeEvery(
      actionsTypes.FETCH_PUBLIC_OTHER_NEWS_AND_EVENTS.PENDING,
      fetchOthersNewsAndEventsData,
    ),
    takeLatest(actionsTypes.FETCH_PUBLIC_DETAILS_NEWS_AND_EVENT.PENDING, fetchNewsAndEventDetails),
  ]);
}
