import { actionsTypes } from './constants';

export const fetchGiveawaysActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_GIVEAWAYS.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.FETCH_GIVEAWAYS.SUCCESS,
    payload: { data },
  }),
  error: () => ({
    type: actionsTypes.FETCH_GIVEAWAYS.ERROR,
  }),
};

export const fetchGiveawayAction = {
  pending: payload => ({
    type: actionsTypes.FETCH_GIVEAWAY.PENDING,
    payload,
  }),
  success: ({ giveaway }) => ({
    type: actionsTypes.FETCH_GIVEAWAY.SUCCESS,
    payload: { giveaway },
  }),
  error: () => ({
    type: actionsTypes.FETCH_GIVEAWAY.ERROR,
  }),
};

export const redeemGiveawayActions = {
  pending: payload => ({
    type: actionsTypes.REDEEM_GIVEAWAY.PENDING,
    payload,
  }),
  success: () => ({
    type: actionsTypes.REDEEM_GIVEAWAY.SUCCESS,
  }),
  error: () => ({
    type: actionsTypes.REDEEM_GIVEAWAY.ERROR,
  }),
};
