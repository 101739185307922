export const STATUS = {
  UNINITIALIZED: 'UNINITIALIZED',
  SUCCESS: 'SUCCESS',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  REFRESHING: 'REFRESHING',
  SUBMITTING: 'SUBMITTING',
  UPDATING: 'UPDATING',
  ADDING: 'ADDING',
};

export const EMAIL_STATUS = {
  PENDING: 'PENDING',
  UPDATED: 'UPDATED',
  CONFIRMED: 'CONFIRMED',
};

export const MOBILE_STATUS = {
  PENDING: 'PENDING',
  UPDATED: 'UPDATED',
  CONFIRMED: 'CONFIRMED',
};

export const ECSP_STATUS = {
  NEW: 'NEW',
  MISSING_DATA: 'MISSINGDATA',
  CONFIRMED: 'CONFIRMED',
  PENDING: 'PENDING',
  UPDATED: 'UPDATED',
};

export const INVESTOR_PROFILE_STATUS = {
  NEW: 'NEW',
  MISSING_DATA: 'MISSINGDATA',
  CONFIRMED: 'CONFIRMED',
  PENDING: 'PENDING',
  UPDATED: 'UPDATED',
};

export const AML_STATUS = {
  OPEN_NEW: 'OPEN_NEW',
  OPEN_MISSINGDATA: 'OPEN_MISSINGDATA',
  CLOSED_CONFIRMED: 'CLOSED_CONFIRMED',
  OPEN_PENDING: 'OPEN_PENDING',
  OPEN_UPDATEREQUIRED: 'OPEN_UPDATEREQUIRED',
};

export const INVESTMENT_OBJECTIVES_STATUS = {
  OPEN_NEW: 'OPEN_NEW',
  OPEN_MISSING_DATA: 'OPEN_MISSING_DATA',
  CLOSED_CONFIRMED: 'CLOSED_CONFIRMED',
  OPEN_PENDING: 'OPEN_PENDING',
  OPEN_UPDATEREQUIRED: 'OPEN_UPDATEREQUIRED',
};

export const FINANCIAL_SITUATION_STATUS = {
  NEW: 'NEW',
  MISSING_DATA: 'MISSINGDATA',
  CONFIRMED: 'CONFIRMED',
  PENDING: 'PENDING',
  UPDATED: 'UPDATED',

  OPEN_NEW: 'OPEN_NEW',
  OPEN_MISSING_DATA: 'OPEN_MISSINGDATA',
  CLOSED_CONFIRMED: 'CLOSED_CONFIRMED',
  OPEN_PENDING: 'OPEN_PENDING',
  OPEN_UPDATEREQUIRED: 'OPEN_UPDATEREQUIRED',
};

export const KNOWLEDGE_STATUS = {
  NEW: 'NEW',
  MISSING_DATA: 'MISSINGDATA',
  CONFIRMED: 'CONFIRMED',
  PENDING: 'PENDING',
  UPDATED: 'UPDATED',

  OPEN_NEW: 'OPEN_NEW',
  OPEN_MISSING_DATA: 'OPEN_MISSINGDATA',
  CLOSED_CONFIRMED: 'CLOSED_CONFIRMED',
  OPEN_PENDING: 'OPEN_PENDING',
  OPEN_UPDATEREQUIRED: 'OPEN_UPDATEREQUIRED',
};
export const REFERENCEBANK_STATUS = {
  NEW: 'NEW',
  MISSING_DATA: 'MISSINGDATA',
  CONFIRMED: 'CONFIRMED',
  PENDING: 'PENDING',
  UPDATED: 'UPDATED',

  OPEN_NEW: 'OPEN_NEW',
  OPEN_MISSING_DATA: 'OPEN_MISSINGDATA',
  CLOSED_CONFIRMED: 'CLOSED_CONFIRMED',
  OPEN_PENDING: 'OPEN_PENDING',
  OPEN_UPDATEREQUIRED: 'OPEN_UPDATEREQUIRED',
};

export const PERSONAL_STATUS = {
  NEW: 'NEW',
  MISSING_DATA: 'MISSINGDATA',
  CONFIRMED: 'CONFIRMED',
  PENDING: 'PENDING',

  OPEN_NEW: 'OPEN_NEW',
  OPEN_MISSING_DATA: 'OPEN_MISSINGDATA',
  CLOSED_CONFIRMED: 'CLOSED_CONFIRMED',
  OPEN_PENDING: 'OPEN_PENDING',
};

export const KYC_STATUS = {
  NEW: 'NEW',
  MISSING_DATA: 'MISSINGDATA',
  CONFIRMED: 'CONFIRMED',
  PENDING: 'PENDING',

  OPEN_NEW: 'OPEN_NEW',
  OPEN_MISSING_DATA: 'OPEN_MISSINGDATA',
  CLOSED_CONFIRMED: 'CLOSED_CONFIRMED',
  OPEN_PENDING: 'OPEN_PENDING',
};
