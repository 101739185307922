import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
import { isEmpty } from 'lodash';
import { actionsTypes } from './constants';
import { fetchMessagesAction } from './postbox.action';

import * as queries from './graphql/queries';
import { getPostboxData, getPostboxNextToken } from './postbox.selector';

export function* fetchMessagesData({ payload }) {
  const nextToken = yield select(getPostboxNextToken);
  const accountId = localStorage.getItem('activeAccount');
  const messages = yield select(getPostboxData);

  try {
    const params = {
      accountId: {
        eq: accountId,
      },
      folderId: `POSTBOX#INBOX#${accountId}`,
      nextToken: !isEmpty(nextToken) ? nextToken : null,
    };
    const response = yield call([API, 'graphql'], graphqlOperation(queries.readMessages, params));
    const _data = response.data.myPortal.accounts.items[0].account.messages.items;
    const _nextToken = response.data.myPortal.accounts.items[0].account.messages.nextToken;

    yield put(
      fetchMessagesAction.success({
        data: _data,
        nextToken: _nextToken,
      }),
    );

    const elements = messages.length + _data.length;
    if (!isEmpty(_nextToken) && elements < 50) {
      yield put(fetchMessagesAction.pending());
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchMessagesAction.error());
  }
}

export default function* newsAndEventsSaga() {
  yield all([takeLatest(actionsTypes.FETCH_MESSAGES.PENDING, fetchMessagesData)]);
}
