import { all, takeEvery, put, call } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
import NotificationActions from 'src/components/shared/Notification/actions';

import { actionsTypes } from './constants';
import {
  fetchGiveawayAction,
  fetchGiveawaysActions,
  redeemGiveawayActions,
} from './giveaway.action';

import { getGiveaways } from './graphql/queries';
import { redeemCode } from './graphql/mutations';

export function* fetchGivewaysData({ payload }) {
  try {
    const params = {
      // sortDirection: 'DESC', // todo need to check why it's sending empty limit when it's uncommented
      // filter: {
      //   // status: {
      //   //   eq: 'PUBLIC',
      //   // },
      //   ...payload.filter,
      // },
      limit: (payload && payload.limit) || 10,
    };

    const response = yield call([API, 'graphql'], graphqlOperation(getGiveaways, params));
    const giveaways = response.data.myPortal.giveaways.items;

    yield put(fetchGiveawaysActions.success({ data: giveaways }));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchGiveawaysActions.error());
  }
}

export function* fetchGivewayData({ payload }) {
  try {
    const params = {
      id: {
        eq: payload,
      },
    };
    const response = yield call([API, 'graphql'], graphqlOperation(getGiveaways, params));
    const giveaway = response.data.myPortal.giveaways.items[0];
    yield put(fetchGiveawayAction.success({ giveaway }));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchGiveawayAction.error());
  }
}

export function* redeemGivewayData({ payload }) {
  try {
    const response = yield call([API, 'graphql'], graphqlOperation(redeemCode, payload));

    if (response.data.redeemCode) {
      yield put(
        NotificationActions.showSuccess({
          title: 'view_giveaways:Notification.RedeemedCode.Title',
          message: 'view_giveaways:Notification.RedeemedCode.Message',
        }),
      );
      yield put(redeemGiveawayActions.success());
    }
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(redeemGiveawayActions.error());
    yield put(
      NotificationActions.showError({
        title: 'view_giveaways:Notification.Error.Title',
        message: Array.isArray(error.errors)
          ? `view_giveaways:Notification.Error.${error.errors[0].message}`
          : error.errors,
      }),
    );
  }
}

export default function* newsAndEventsSaga() {
  yield all([
    takeEvery(actionsTypes.FETCH_GIVEAWAYS.PENDING, fetchGivewaysData),
    takeEvery(actionsTypes.FETCH_GIVEAWAY.PENDING, fetchGivewayData),
    takeEvery(actionsTypes.REDEEM_GIVEAWAY.PENDING, redeemGivewayData),
  ]);
}
