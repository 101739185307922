import { asyncActionType } from 'src/utils/reduxActions';

export const NAMESPACE = 'STORE';

const FETCH_ORDER = asyncActionType(`${NAMESPACE} FETCH`);
const FETCH_WHITELISTED = asyncActionType(`${NAMESPACE} FETCH WHITELISTED`);

const ADD_ORDER = asyncActionType(`${NAMESPACE} ADD`);
const UPDATE_ORDER = asyncActionType(`${NAMESPACE} UPDATE`);
const CONFIRM_ORDER = asyncActionType(`${NAMESPACE} CONFIRM`);
const CANCEL_ORDER = asyncActionType(`${NAMESPACE} CANCEL_ORDER`);
const CLEAR_VALUES = `${NAMESPACE} CLEAR_VALUES`;
const UPDATE_ONBOARDING = asyncActionType(`${NAMESPACE} UPDATE ONBOARDING`);
const SET_ONBOARDING_STEPS = `${NAMESPACE} SET ONBOARDING_STEPS`;
const WHITELISTING = asyncActionType(`${NAMESPACE} WHITELISTING`);
const SET_MIN_INVEST_AMOUNT = `${NAMESPACE} SET_MIN_INVEST_AMOUNT`;
const SET_SELECTED_OFFERING = `${NAMESPACE} SET_SELECTED_OFFERING`;
const CONFIRMATION_MODAL = `${NAMESPACE} CONFIRMATION_MODAL`;
const CONFIRMATION_MODAL_STEP = `${NAMESPACE} CONFIRMATION_MODAL_STEP`;
const SET_STEP = `${NAMESPACE} - STEP`;
const SET_ONBOARDNIG = `${NAMESPACE} ONBOARDING`;
const VERIFYBOARDING = `${NAMESPACE} VERIFYBOARDING`;

const BUY_TOKEN = asyncActionType(`${NAMESPACE} BUY TOKEN`);
const BUY_TOKEN_RESET = `${NAMESPACE} RESET BUY TOKEN`;
const BUY_TOKEN_SET_PAYMENTDATA = `${NAMESPACE} BUY SET PAYMENT DATA`;
const BUY_TOKEN_CONFIRM = asyncActionType(`${NAMESPACE} BUY TOKEN CONFIRM`);

export const actionsTypes = {
  SET_STEP,
  ADD_ORDER,
  FETCH_ORDER,
  CLEAR_VALUES,
  UPDATE_ORDER,
  CONFIRM_ORDER,
  CANCEL_ORDER,
  SET_MIN_INVEST_AMOUNT,
  SET_ONBOARDING_STEPS,
  UPDATE_ONBOARDING,
  WHITELISTING,
  FETCH_WHITELISTED,
  SET_SELECTED_OFFERING,
  CONFIRMATION_MODAL,
  CONFIRMATION_MODAL_STEP,
  SET_ONBOARDNIG,
  VERIFYBOARDING,
  BUY_TOKEN,
  BUY_TOKEN_RESET,
  BUY_TOKEN_SET_PAYMENTDATA,
  BUY_TOKEN_CONFIRM,
};

export const BOARDING_STEPS_NAME = {
  ACCOUNTTYPE: 'ACCOUNTTYPE',
  PERSONAL: 'PERSONAL',
  COMPANY: 'COMPANY',
  AML: 'AML',
  EMAIL: 'EMAIL',
  MOBILE: 'MOBILE',
  WALLET: 'WALLET',
  INVESTOR_TYPE: 'INVESTOR_TYPE',
  ECSP: 'ECSP',
  INVESTMENT_OBJECTIVES: 'INVESTMENT_OBJECTIVES',
  FINANCIAL_SITUATION: 'FINANCIAL_SITUATION',
  KNOWLEDGE: 'KNOWLEDGE',
  REFERENCE_BANK: 'REFERENCE_BANK',
  IDENTITY: 'IDENTITY',
  REFERRAL: 'REFERRAL',
  AFFILIATE: 'AFFILIATE',
};

export const ON_BOARDING_STEPS = {
  [BOARDING_STEPS_NAME.ACCOUNTTYPE]: {
    name: BOARDING_STEPS_NAME.ACCOUNTTYPE,
    isCompleted: false,
    order: 0,
    redirectTo: '/account/type',
  },
  [BOARDING_STEPS_NAME.PERSONAL]: {
    name: BOARDING_STEPS_NAME.PERSONAL,
    isCompleted: false,
    order: 1,
    redirectTo: '/account/edit',
  },
  [BOARDING_STEPS_NAME.COMPANY]: {
    name: BOARDING_STEPS_NAME.COMPANY,
    isCompleted: false,
    order: 1,
    redirectTo: '/account/edit',
  },
  [BOARDING_STEPS_NAME.EMAIL]: {
    name: BOARDING_STEPS_NAME.EMAIL,
    isCompleted: false,
    order: 2,
    redirectTo: '/account/email/edit',
  },
  [BOARDING_STEPS_NAME.MOBILE]: {
    name: BOARDING_STEPS_NAME.MOBILE,
    isCompleted: false,
    order: 3,
    redirectTo: '/account/mobile/edit',
  },
  [BOARDING_STEPS_NAME.WALLET]: {
    name: BOARDING_STEPS_NAME.WALLET,
    isCompleted: false,
    order: 4,
    redirectTo: '/account/wallet/type',
  },
  [BOARDING_STEPS_NAME.AML]: {
    name: BOARDING_STEPS_NAME.AML,
    isCompleted: false,
    order: 5,
    redirectTo: '/account/aml',
  },
  [BOARDING_STEPS_NAME.ECSP]: {
    name: BOARDING_STEPS_NAME.ECSP,
    isCompleted: false,
    order: 6,
    redirectTo: '/account/ecsp',
  },
  [BOARDING_STEPS_NAME.INVESTMENT_OBJECTIVES]: {
    name: BOARDING_STEPS_NAME.INVESTMENT_OBJECTIVES,
    isCompleted: false,
    order: 7,
    redirectTo: '/account/investmentObjectives',
  },
  [BOARDING_STEPS_NAME.FINANCIAL_SITUATION]: {
    name: BOARDING_STEPS_NAME.FINANCIAL_SITUATION,
    isCompleted: false,
    order: 8,
    redirectTo: '/account/financialSituation',
  },
  [BOARDING_STEPS_NAME.KNOWLEDGE]: {
    name: BOARDING_STEPS_NAME.KNOWLEDGE,
    isCompleted: false,
    order: 9,
    redirectTo: '/account/knowledgeAndExperience',
  },
  [BOARDING_STEPS_NAME.REFERENCE_BANK]: {
    name: BOARDING_STEPS_NAME.REFERENCE_BANK,
    isCompleted: false,
    order: 10,
    redirectTo: '/account/referenceBank',
  },
  [BOARDING_STEPS_NAME.IDENTITY]: {
    name: BOARDING_STEPS_NAME.IDENTITY,
    isCompleted: false,
    order: 11,
    redirectTo: '/account/edit',
  },
  [BOARDING_STEPS_NAME.REFERRAL]: {
    name: BOARDING_STEPS_NAME.REFERRAL,
    isCompleted: false,
    order: 12,
    redirectTo: '/account/edit',
  },
  [BOARDING_STEPS_NAME.AFFILIATE]: {
    name: BOARDING_STEPS_NAME.AFFILIATE,
    isCompleted: false,
    order: 13,
    redirectTo: '/account/edit',
  },
};

export const PATHS_TO_IGNORE = [
  ...Object.values(ON_BOARDING_STEPS).map(path => path.redirectTo),
  '/onboarding',
  '/account/type',
  '/account/gubbiKYC',
  '/account/signd',
  '/account/wallet/add',
  '/account/email/edit',
  '/account/wallet/type',
  '/account/wallet/addMetaMask',
  '/terms/term1',
  '/terms/term2',
  '/terms/term3',
  '/terms/term4',
  '/terms/term5',
];

export const TERMS_TO_IGNORE = [
  '/terms/term1',
  '/terms/term2',
  '/terms/term3',
  '/terms/term4',
  '/terms/term5',
];

export const SKIP_VERIFICATION_REDIRECTION = ['/account/wallet/add'];
