import createSelector from 'src/utils/reselect';
import { Map } from 'immutable';

const affiliate = state => state.get('affiliate', new Map());

export const getAffiliateStatus = createSelector(affiliate, data => data.get('status'));
export const getAccountData = createSelector(affiliate, data => data.get('account'));
export const getAffiliateData = createSelector(affiliate, data => data.get('affiliate'));

// Orders
const getAffiliateOrders = createSelector(affiliate, data => data.get('orders') || {});
export const getAffiliateOrdersData = createSelector(
  getAffiliateOrders,
  data => data.get('data') || false,
);
export const getAffiliateOrdersStatus = createSelector(getAffiliateOrders, data =>
  data.get('status'),
);
export const getAffiliateOrdersToken = createSelector(getAffiliateOrders, data =>
  data.get('nextToken'),
);
export const getAffiliateOrdersLimit = createSelector(getAffiliateOrders, data =>
  data.get('limit'),
);

// Signups
const getAffiliateSignups = createSelector(affiliate, data => data.get('signups') || {});
export const getAffiliateSignupsData = createSelector(
  getAffiliateSignups,
  data => data.get('data') || false,
);
export const getAffiliateSignupsStatus = createSelector(getAffiliateSignups, data =>
  data.get('status'),
);
export const getAffiliateSignupsToken = createSelector(getAffiliateSignups, data =>
  data.get('nextToken'),
);
export const getAffiliateSignupsLimit = createSelector(getAffiliateSignups, data =>
  data.get('limit'),
);

// Affiliated Account
const getAffiliatedAccount = createSelector(affiliate, data => data.get('affiliatedAccount') || {});
export const getAffiliatedAccountData = createSelector(
  getAffiliatedAccount,
  data => data.get('data') || false,
);
export const getAffiliatedAccountStatus = createSelector(getAffiliatedAccount, data =>
  data.get('status'),
);
