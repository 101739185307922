import { all, takeLatest, put, call } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
import { showError } from 'src/components/shared/Notification/actions';

import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';
import { actionsTypes } from './constants';
import { activateReferralAction, fetchReferralActions } from './referrals.action';

export function* fetchAccountData() {
  let params = {
    accountId: {
      eq: localStorage.getItem('activeAccount'),
    },
  };

  try {
    const response = yield call([API, 'graphql'], graphqlOperation(queries.readAffiliate, params));
    const account = {
      ...response.data.myPortal.accounts.items[0].account,
      ...response.data.myPortal.accounts.items[0].account.address,
    };

    const referral = response.data.myPortal.accounts.items[0].account.referral;
    referral.status = 'active';

    yield put(
      fetchReferralActions.success({
        referral,
        account,
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line
    const noReferral = error.errors.filter(e => e.message === 'NoActiveReferral');
    const notRegistered = error.errors.filter(e => e.message === 'NotRegistered');
    if (noReferral.length > 0) {
      yield put(
        fetchReferralActions.success({
          referral: {
            status: 'noReferral',
          },
        }),
      );
    } else if (notRegistered.length > 0) {
      yield put(
        fetchReferralActions.success({
          referral: {
            status: 'notRegistered',
          },
        }),
      );
    } else {
      yield put(fetchReferralActions.error());
    }
  }
}

export function* activateReferralData({ payload }) {
  try {
    payload.accountId = localStorage.getItem('activeAccount');
    const response = yield call(
      [API, 'graphql'],
      graphqlOperation(mutations.activateReferral, payload),
    );
    yield put(
      activateReferralAction.success({
        account: response.data.activateReferral,
        referral: response.data.activateReferral.affiliate,
      }),
    );

    yield put(fetchReferralActions.pending());
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(activateReferralAction.error());
    yield put(
      showError({
        title: 'view_referrals:Notification.Error.Title',
        message: `view_referrals:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.message
        }`,
      }),
    );
  }
}

export default function* appSeetingsSagas() {
  yield all([takeLatest(actionsTypes.FETCH_REFERRAL.PENDING, fetchAccountData)]);
  yield all([takeLatest(actionsTypes.ACTIVATE_REFERRAL.PENDING, activateReferralData)]);
}
