import createSelector from 'src/utils/reselect';
import { Map } from 'immutable';

const communication = state => state.get('communication', new Map());

export const getChatStatus = createSelector(communication, data => data.get('status'));
export const getChatMessages = createSelector(communication, data => data.get('data') || '');
export const getChatNextToken = createSelector(communication, data => data.get('nextToken') || '');
export const getChatLimit = createSelector(communication, data => data.get('limit') || '');

const getComments = createSelector(communication, data => data.get('comments'));
export const getCommentsStatus = createSelector(getComments, data => data.get('status'));
export const getCommentsData = createSelector(getComments, data => data.get('data') || '');
export const getCommentsNextToken = createSelector(
  getComments,
  data => data.get('nextToken') || '',
);
export const getCommentsLimit = createSelector(getComments, data => data.get('limit') || '');

// Comment
export const getCommentData = createSelector(communication, data => data.get('comment'));
export const getCommentStatus = createSelector(communication, data =>
  data.getIn('comment', 'status'),
);
