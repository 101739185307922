export const readAccountSpecificOrder = /* GraphQL */ `
  query Portal($accountId: ModelIDKeyConditionInput, $filter: TransactionFilterInput) {
    myPortal {
      id
      tenantId
      accounts(accountId: $accountId) {
        items {
          status
          accountId

          account {
            id

            transactions(filter: $filter) {
              items {
                tenantId
                transactionId
                accountId
                offeringId
                paymentCode
                blockchainAccountWalletId
                blockchainAccountWallet {
                  name
                  publicAddress
                  blockchainProvider
                }
                amount
                # vatDigital
                # vatPhysical
                status
                orderPaymentServiceId
                orderData {
                  pricePerToken
                  amountInCurrency
                  orderValid
                  fundingPricePerTokenList {
                    currency
                    price
                    bruttoPrice
                    nettoPrice
                    includedVAT
                  }
                  gasPriceList {
                    currency
                    bruttoPrice
                    nettoPrice
                    includedVAT
                  }
                  currency
                  contractUrl
                  compliance
                  orderDate
                  paymentDate
                  issuingDate
                  accountType
                  company {
                    name
                  }

                  individual {
                    gender
                    firstName
                    lastName
                    dateOfBirth
                    nationality
                  }
                  address {
                    street
                    postalCode
                    city
                    state
                    country
                  }
                }

                offering {
                  id
                  name
                  symbol
                  decimals
                  chain
                  publicAddress
                  fundingEndDate
                  fundingStartDate
                  fundingSupplyCap
                  hasHeroImage
                  hasOverviewImage
                  fundingComplianceStep
                  legal {
                    companyName
                    bank
                    iban
                    bic
                  }
                }
              }
              nextToken
            }
          }
        }
      }
    }
  }
`;


export const readAccountWhitelisted = /* GraphQL */ `
  query Portal($accountId: ModelIDKeyConditionInput, $filter: TransactionFilterInput) {
    myPortal {
      id
      tenantId
      accounts(accountId: $accountId) {
        items {
          status
          accountId

          account {
            id

            transactions(filter: $filter) {
              items {
                tenantId
                transactionId
                accountId
                offeringId

                status
              }
              nextToken
            }
          }
        }
      }
    }
  }
`;
