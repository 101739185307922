export const addIpAccount = /* GraphQL */ `
  mutation AddIpAccount($input: UpdateAccountInput) {
    addIPAccount(input: $input) {
      tenantId
      id
      name
    }
  }
`;

export const addIPBlockchainWallet = /* GraphQL */ `
  mutation AddIPBlockchainWallet($accountId: ID, $input: BlockchainWalletInput) {
    addIPBlockchainWallet(accountId: $accountId, input: $input) {
      id
      id
      name
      publicAddress
      blockchainProvider
      blockchainStatus
      blockchainRecovery
      signatureMessage
      status
    }
  }
`;

export const checkIPMetamaskSignature = /* GraphQL */ `
  mutation CheckIPMetamaskSignature($accountId: ID, $input: BlockchainWalletInput) {
    checkIPMetamaskSignature(accountId: $accountId, input: $input) {
      tenantId
      id
      name
      publicAddress
      blockchainProvider
      blockchainStatus

      account {
        tenantId
        name
        id
        createPaperWallet
        mainBlockchainWalletId
        status
        kycStatus
        email
        emailStatus

        mobile
        mobileStatus
        createPaperWallet
        individualCompanyStatus
        individualCompanyMessage

        confirmedTerms1
        confirmedTerms2
        confirmedTerms3
        confirmedTerms4
        confirmedTerms5

        investorProfileStatus
        ecspStatus
        company {
          name
          description
          websiteUrl
          identificationNumber
          ultimateBeneficialOwner {
            title
            firstName
            lastName
            dateOfBirth
            cityOfBirth
            countryOfBirth
            nationality
            countryOfResidence
            phoneNumber
            mobileNumber
          }
        }
        individual {
          title
          gender
          firstName
          lastName
          dateOfBirth
          cityOfBirth
          countryOfBirth
          nationality
          countryOfResidence
          phoneNumber
          mobileNumber
        }

        investmentObjectivesStatus
        investmentObjectivesMessage
        investmentObjectives {
          riskTolerance
          timeHorizon
          investmentReason
        }

        financialSituationStatus
        financialSituationMessage
        financialSituation {
          monthlyNetIncome
          monthlyExpenses
          liquidAssets
          illiquidAssets
          liabilities
          financialReserves

          disclaimerFinancialReserve
          disclaimerLongterm
          disclaimerFlexibility
          disclaimerLifeStandard
          disclaimerFluctuations
        }

        knowledgeAndExperienceStatus
        knowledgeAndExperienceMessage
        knowledgeAndExperience {
          knowHow1
          knowHow2
          knowHow3
          knowHow4
          knowHow5
        }

        referenceBankAccountStatus
        referenceBankAccountMessage
        referenceBankAccount {
          iban
          bic
          owner
          bankName
        }

        amlStatus
        amlMessage
        aml {
          sourceOfFunds
          sourceOfFundsOthers
          sourceOfWealth
          sourceOfWealthOthers
          pep
          pepRelatedPerson
          pepStatusDescription
        }

        address {
          street
          postalCode
          city
          state
          country
        }
        tenant {
          allowReferAFriend
          showReferAFriend
          allowAffiliate
          showAffiliate

          requireVAT
          requireIdentificationNr
          requireWebsite
          requireTIN
          requireOccupation
          requireEducation
          requireAML
          requireWallet
          requireInvestorType
          requireECSPProfile
          requireInvestmentObjective
          requireFinancialSituation
          requireKnowledge
          requireReferenceBank
          requireEmail
          requireMobile
          kycProvider
          walletProvider
          requireTerms1
          requireTerms2
          requireTerms3
          requireTerms4
          requireTerms5
          terms {
            key
            title
            content
            description
          }
        }
        accountType
      }

      status
    }
  }
`;

export const setAccountType = /* GraphQL */ `
  mutation SetAccountType($accountId: ID!, $accountType: AccountType!) {
    setAccountType(accountId: $accountId, accountType: $accountType) {
      tenantId
      name
      id
      createPaperWallet
      mainBlockchainWalletId
      status
      kycStatus
      email
      emailStatus

      mobile
      mobileStatus
      createPaperWallet
      individualCompanyStatus
      individualCompanyMessage

      confirmedTerms1
      confirmedTerms2
      confirmedTerms3
      confirmedTerms4
      confirmedTerms5

      isReferral
      isAffiliate
      hasReferral

      investorProfileStatus
      ecspStatus
      company {
        name
        description
        websiteUrl
        identificationNumber
        ultimateBeneficialOwner {
          title
          firstName
          lastName
          dateOfBirth
          cityOfBirth
          countryOfBirth
          nationality
          countryOfResidence
          phoneNumber
          mobileNumber
        }
      }
      individual {
        title
        gender
        firstName
        lastName
        dateOfBirth
        cityOfBirth
        countryOfBirth
        nationality
        countryOfResidence
        phoneNumber
        mobileNumber
      }

      investmentObjectivesStatus
      investmentObjectivesMessage
      investmentObjectives {
        riskTolerance
        timeHorizon
        investmentReason
      }

      financialSituationStatus
      financialSituationMessage
      financialSituation {
        monthlyNetIncome
        monthlyExpenses
        liquidAssets
        illiquidAssets
        liabilities
        financialReserves

        disclaimerFinancialReserve
        disclaimerLongterm
        disclaimerFlexibility
        disclaimerLifeStandard
        disclaimerFluctuations
      }

      knowledgeAndExperienceStatus
      knowledgeAndExperienceMessage
      knowledgeAndExperience {
        knowHow1
        knowHow2
        knowHow3
        knowHow4
        knowHow5
      }

      referenceBankAccountStatus
      referenceBankAccountMessage
      referenceBankAccount {
        iban
        bic
        owner
        bankName
      }

      amlStatus
      amlMessage
      aml {
        sourceOfFunds
        sourceOfFundsOthers
        sourceOfWealth
        sourceOfWealthOthers
        pep
        pepRelatedPerson
        pepStatusDescription
      }

      address {
        street
        postalCode
        city
        state
        country
      }
      tenant {
        allowReferAFriend
        showReferAFriend
        allowAffiliate
        showAffiliate

        requireVAT
        requireIdentificationNr
        requireWebsite
        requireTIN
        requireOccupation
        requireEducation
        requireAML
        requireWallet
        requireInvestorType
        requireECSPProfile
        requireInvestmentObjective
        requireFinancialSituation
        requireKnowledge
        requireReferenceBank
        requireEmail
        requireMobile
        kycProvider
        walletProvider
        requireTerms1
        requireTerms2
        requireTerms3
        requireTerms4
        requireTerms5
        terms {
          key
          title
          content
          description
        }
      }
      accountType
    }
  }
`;

export const updateIpAccount = /* GraphQL */ `
  mutation UpdateIpAccount($accountId: ID!, $input: UpdateAccountInput) {
    updateIPAccount(accountId: $accountId, input: $input) {
      tenantId
      name
      id
      createPaperWallet
      mainBlockchainWalletId
      status
      kycStatus
      email
      emailStatus

      mobile
      mobileStatus
      createPaperWallet
      individualCompanyStatus
      individualCompanyMessage

      confirmedTerms1
      confirmedTerms2
      confirmedTerms3
      confirmedTerms4
      confirmedTerms5

      isReferral
      isAffiliate
      hasReferral

      investorProfileStatus
      ecspStatus
      company {
        name
        description
        websiteUrl
        identificationNumber
        ultimateBeneficialOwner {
          title
          firstName
          lastName
          dateOfBirth
          cityOfBirth
          countryOfBirth
          nationality
          countryOfResidence
          phoneNumber
          mobileNumber
        }
      }
      individual {
        title
        gender
        firstName
        lastName
        dateOfBirth
        cityOfBirth
        countryOfBirth
        nationality
        countryOfResidence
        phoneNumber
        mobileNumber
      }

      investmentObjectivesStatus
      investmentObjectivesMessage
      investmentObjectives {
        riskTolerance
        timeHorizon
        investmentReason
      }

      financialSituationStatus
      financialSituationMessage
      financialSituation {
        monthlyNetIncome
        monthlyExpenses
        liquidAssets
        illiquidAssets
        liabilities
        financialReserves

        disclaimerFinancialReserve
        disclaimerLongterm
        disclaimerFlexibility
        disclaimerLifeStandard
        disclaimerFluctuations
      }

      knowledgeAndExperienceStatus
      knowledgeAndExperienceMessage
      knowledgeAndExperience {
        knowHow1
        knowHow2
        knowHow3
        knowHow4
        knowHow5
      }

      referenceBankAccountStatus
      referenceBankAccountMessage
      referenceBankAccount {
        iban
        bic
        owner
        bankName
      }

      amlStatus
      amlMessage
      aml {
        sourceOfFunds
        sourceOfFundsOthers
        sourceOfWealth
        sourceOfWealthOthers
        pep
        pepRelatedPerson
        pepStatusDescription
      }

      address {
        street
        postalCode
        city
        state
        country
      }
      tenant {
        allowReferAFriend
        showReferAFriend
        allowAffiliate
        showAffiliate

        requireVAT
        requireIdentificationNr
        requireWebsite
        requireTIN
        requireOccupation
        requireEducation
        requireAML
        requireWallet
        requireInvestorType
        requireECSPProfile
        requireInvestmentObjective
        requireFinancialSituation
        requireKnowledge
        requireReferenceBank
        requireEmail
        requireMobile
        kycProvider
        walletProvider
        requireTerms1
        requireTerms2
        requireTerms3
        requireTerms4
        requireTerms5
        terms {
          key
          title
          content
          description
        }
      }
      accountType
    }
  }
`;

export const updateAML = /* GraphQL */ `
  mutation UpdateAML($accountId: ID!, $input: UpdateAMLInput!) {
    updateAML(accountId: $accountId, input: $input) {
      tenantId
      name
      id
      createPaperWallet
      mainBlockchainWalletId
      status
      kycStatus
      email
      emailStatus

      mobile
      mobileStatus
      createPaperWallet
      individualCompanyStatus
      individualCompanyMessage

      confirmedTerms1
      confirmedTerms2
      confirmedTerms3
      confirmedTerms4
      confirmedTerms5

      isReferral
      isAffiliate
      hasReferral

      investorProfileStatus
      ecspStatus
      company {
        name
        description
        websiteUrl
        identificationNumber
        ultimateBeneficialOwner {
          title
          firstName
          lastName
          dateOfBirth
          cityOfBirth
          countryOfBirth
          nationality
          countryOfResidence
          phoneNumber
          mobileNumber
        }
      }
      individual {
        title
        gender
        firstName
        lastName
        dateOfBirth
        cityOfBirth
        countryOfBirth
        nationality
        countryOfResidence
        phoneNumber
        mobileNumber
      }

      investmentObjectivesStatus
      investmentObjectivesMessage
      investmentObjectives {
        riskTolerance
        timeHorizon
        investmentReason
      }

      financialSituationStatus
      financialSituationMessage
      financialSituation {
        monthlyNetIncome
        monthlyExpenses
        liquidAssets
        illiquidAssets
        liabilities
        financialReserves

        disclaimerFinancialReserve
        disclaimerLongterm
        disclaimerFlexibility
        disclaimerLifeStandard
        disclaimerFluctuations
      }

      knowledgeAndExperienceStatus
      knowledgeAndExperienceMessage
      knowledgeAndExperience {
        knowHow1
        knowHow2
        knowHow3
        knowHow4
        knowHow5
      }

      referenceBankAccountStatus
      referenceBankAccountMessage
      referenceBankAccount {
        iban
        bic
        owner
        bankName
      }

      amlStatus
      amlMessage
      aml {
        sourceOfFunds
        sourceOfFundsOthers
        sourceOfWealth
        sourceOfWealthOthers
        pep
        pepRelatedPerson
        pepStatusDescription
      }

      address {
        street
        postalCode
        city
        state
        country
      }
      tenant {
        allowReferAFriend
        showReferAFriend
        allowAffiliate
        showAffiliate

        requireVAT
        requireIdentificationNr
        requireWebsite
        requireTIN
        requireOccupation
        requireEducation
        requireAML
        requireWallet
        requireInvestorType
        requireECSPProfile
        requireInvestmentObjective
        requireFinancialSituation
        requireKnowledge
        requireReferenceBank
        requireEmail
        requireMobile
        kycProvider
        walletProvider
        requireTerms1
        requireTerms2
        requireTerms3
        requireTerms4
        requireTerms5
        terms {
          key
          title
          content
          description
        }
      }
      accountType
    }
  }
`;

export const updateInvestmentObjectives = /* GraphQL */ `
  mutation UpdateInvestmentObjectives($accountId: ID!, $input: UpdateInvestmentObjectivesInput!) {
    updateInvestmentObjectives(accountId: $accountId, input: $input) {
      tenantId
      name
      id
      createPaperWallet
      mainBlockchainWalletId
      status
      kycStatus
      email
      emailStatus

      mobile
      mobileStatus
      createPaperWallet
      individualCompanyStatus
      individualCompanyMessage

      confirmedTerms1
      confirmedTerms2
      confirmedTerms3
      confirmedTerms4
      confirmedTerms5

      isReferral
      isAffiliate
      hasReferral

      investorProfileStatus
      ecspStatus
      company {
        name
        description
        websiteUrl
        identificationNumber
        ultimateBeneficialOwner {
          title
          firstName
          lastName
          dateOfBirth
          cityOfBirth
          countryOfBirth
          nationality
          countryOfResidence
          phoneNumber
          mobileNumber
        }
      }
      individual {
        title
        gender
        firstName
        lastName
        dateOfBirth
        cityOfBirth
        countryOfBirth
        nationality
        countryOfResidence
        phoneNumber
        mobileNumber
      }

      investmentObjectivesStatus
      investmentObjectivesMessage
      investmentObjectives {
        riskTolerance
        timeHorizon
        investmentReason
      }

      financialSituationStatus
      financialSituationMessage
      financialSituation {
        monthlyNetIncome
        monthlyExpenses
        liquidAssets
        illiquidAssets
        liabilities
        financialReserves

        disclaimerFinancialReserve
        disclaimerLongterm
        disclaimerFlexibility
        disclaimerLifeStandard
        disclaimerFluctuations
      }

      knowledgeAndExperienceStatus
      knowledgeAndExperienceMessage
      knowledgeAndExperience {
        knowHow1
        knowHow2
        knowHow3
        knowHow4
        knowHow5
      }

      referenceBankAccountStatus
      referenceBankAccountMessage
      referenceBankAccount {
        iban
        bic
        owner
        bankName
      }

      amlStatus
      amlMessage
      aml {
        sourceOfFunds
        sourceOfFundsOthers
        sourceOfWealth
        sourceOfWealthOthers
        pep
        pepRelatedPerson
        pepStatusDescription
      }

      address {
        street
        postalCode
        city
        state
        country
      }
      tenant {
        allowReferAFriend
        showReferAFriend
        allowAffiliate
        showAffiliate

        requireVAT
        requireIdentificationNr
        requireWebsite
        requireTIN
        requireOccupation
        requireEducation
        requireAML
        requireWallet
        requireInvestorType
        requireECSPProfile
        requireInvestmentObjective
        requireFinancialSituation
        requireKnowledge
        requireReferenceBank
        requireEmail
        requireMobile
        kycProvider
        walletProvider
        requireTerms1
        requireTerms2
        requireTerms3
        requireTerms4
        requireTerms5
        terms {
          key
          title
          content
          description
        }
      }
      accountType
    }
  }
`;

export const updateFinancialSituation = /* GraphQL */ `
  mutation UpdateFinancialSituation($accountId: ID!, $input: UpdateFinancialSituationInput!) {
    updateFinancialSituation(accountId: $accountId, input: $input) {
      tenantId
      name
      id
      createPaperWallet
      mainBlockchainWalletId
      status
      kycStatus
      email
      emailStatus

      mobile
      mobileStatus
      createPaperWallet
      individualCompanyStatus
      individualCompanyMessage

      confirmedTerms1
      confirmedTerms2
      confirmedTerms3
      confirmedTerms4
      confirmedTerms5

      isReferral
      isAffiliate
      hasReferral

      investorProfileStatus
      ecspStatus
      company {
        name
        description
        websiteUrl
        identificationNumber
        ultimateBeneficialOwner {
          title
          firstName
          lastName
          dateOfBirth
          cityOfBirth
          countryOfBirth
          nationality
          countryOfResidence
          phoneNumber
          mobileNumber
        }
      }
      individual {
        title
        gender
        firstName
        lastName
        dateOfBirth
        cityOfBirth
        countryOfBirth
        nationality
        countryOfResidence
        phoneNumber
        mobileNumber
      }

      investmentObjectivesStatus
      investmentObjectivesMessage
      investmentObjectives {
        riskTolerance
        timeHorizon
        investmentReason
      }

      financialSituationStatus
      financialSituationMessage
      financialSituation {
        monthlyNetIncome
        monthlyExpenses
        liquidAssets
        illiquidAssets
        liabilities
        financialReserves

        disclaimerFinancialReserve
        disclaimerLongterm
        disclaimerFlexibility
        disclaimerLifeStandard
        disclaimerFluctuations
      }

      knowledgeAndExperienceStatus
      knowledgeAndExperienceMessage
      knowledgeAndExperience {
        knowHow1
        knowHow2
        knowHow3
        knowHow4
        knowHow5
      }

      referenceBankAccountStatus
      referenceBankAccountMessage
      referenceBankAccount {
        iban
        bic
        owner
        bankName
      }

      amlStatus
      amlMessage
      aml {
        sourceOfFunds
        sourceOfFundsOthers
        sourceOfWealth
        sourceOfWealthOthers
        pep
        pepRelatedPerson
        pepStatusDescription
      }

      address {
        street
        postalCode
        city
        state
        country
      }
      tenant {
        allowReferAFriend
        showReferAFriend
        allowAffiliate
        showAffiliate

        requireVAT
        requireIdentificationNr
        requireWebsite
        requireTIN
        requireOccupation
        requireEducation
        requireAML
        requireWallet
        requireInvestorType
        requireECSPProfile
        requireInvestmentObjective
        requireFinancialSituation
        requireKnowledge
        requireReferenceBank
        requireEmail
        requireMobile
        kycProvider
        walletProvider
        requireTerms1
        requireTerms2
        requireTerms3
        requireTerms4
        requireTerms5
        terms {
          key
          title
          content
          description
        }
      }
      accountType
    }
  }
`;

export const updateKnowledgeAndExperience = /* GraphQL */ `
  mutation UpdateKnowledgeAndExperience(
    $accountId: ID!
    $input: UpdateKnowledgeAndExperienceInput!
  ) {
    updateKnowledgeAndExperience(accountId: $accountId, input: $input) {
      tenantId
      name
      id
      createPaperWallet
      mainBlockchainWalletId
      status
      kycStatus
      email
      emailStatus

      mobile
      mobileStatus
      createPaperWallet
      individualCompanyStatus
      individualCompanyMessage

      confirmedTerms1
      confirmedTerms2
      confirmedTerms3
      confirmedTerms4
      confirmedTerms5

      isReferral
      isAffiliate
      hasReferral

      investorProfileStatus
      ecspStatus
      company {
        name
        description
        websiteUrl
        identificationNumber
        ultimateBeneficialOwner {
          title
          firstName
          lastName
          dateOfBirth
          cityOfBirth
          countryOfBirth
          nationality
          countryOfResidence
          phoneNumber
          mobileNumber
        }
      }
      individual {
        title
        gender
        firstName
        lastName
        dateOfBirth
        cityOfBirth
        countryOfBirth
        nationality
        countryOfResidence
        phoneNumber
        mobileNumber
      }

      investmentObjectivesStatus
      investmentObjectivesMessage
      investmentObjectives {
        riskTolerance
        timeHorizon
        investmentReason
      }

      financialSituationStatus
      financialSituationMessage
      financialSituation {
        monthlyNetIncome
        monthlyExpenses
        liquidAssets
        illiquidAssets
        liabilities
        financialReserves

        disclaimerFinancialReserve
        disclaimerLongterm
        disclaimerFlexibility
        disclaimerLifeStandard
        disclaimerFluctuations
      }

      knowledgeAndExperienceStatus
      knowledgeAndExperienceMessage
      knowledgeAndExperience {
        knowHow1
        knowHow2
        knowHow3
        knowHow4
        knowHow5
      }

      referenceBankAccountStatus
      referenceBankAccountMessage
      referenceBankAccount {
        iban
        bic
        owner
        bankName
      }

      amlStatus
      amlMessage
      aml {
        sourceOfFunds
        sourceOfFundsOthers
        sourceOfWealth
        sourceOfWealthOthers
        pep
        pepRelatedPerson
        pepStatusDescription
      }

      address {
        street
        postalCode
        city
        state
        country
      }
      tenant {
        allowReferAFriend
        showReferAFriend
        allowAffiliate
        showAffiliate

        requireVAT
        requireIdentificationNr
        requireWebsite
        requireTIN
        requireOccupation
        requireEducation
        requireAML
        requireWallet
        requireInvestorType
        requireECSPProfile
        requireInvestmentObjective
        requireFinancialSituation
        requireKnowledge
        requireReferenceBank
        requireEmail
        requireMobile
        kycProvider
        walletProvider
        requireTerms1
        requireTerms2
        requireTerms3
        requireTerms4
        requireTerms5
        terms {
          key
          title
          content
          description
        }
      }
      accountType
    }
  }
`;

export const updateReferenceBankAccount = /* GraphQL */ `
  mutation UpdateReferenceBankAccount($accountId: ID!, $input: UpdateReferenceBankAccountInput!) {
    updateReferenceBankAccount(accountId: $accountId, input: $input) {
      tenantId
      name
      id
      createPaperWallet
      mainBlockchainWalletId
      status
      kycStatus
      email
      emailStatus

      mobile
      mobileStatus
      createPaperWallet
      individualCompanyStatus
      individualCompanyMessage

      confirmedTerms1
      confirmedTerms2
      confirmedTerms3
      confirmedTerms4
      confirmedTerms5

      isReferral
      isAffiliate
      hasReferral

      investorProfileStatus
      ecspStatus
      company {
        name
        description
        websiteUrl
        identificationNumber
        ultimateBeneficialOwner {
          title
          firstName
          lastName
          dateOfBirth
          cityOfBirth
          countryOfBirth
          nationality
          countryOfResidence
          phoneNumber
          mobileNumber
        }
      }
      individual {
        title
        gender
        firstName
        lastName
        dateOfBirth
        cityOfBirth
        countryOfBirth
        nationality
        countryOfResidence
        phoneNumber
        mobileNumber
      }

      investmentObjectivesStatus
      investmentObjectivesMessage
      investmentObjectives {
        riskTolerance
        timeHorizon
        investmentReason
      }

      financialSituationStatus
      financialSituationMessage
      financialSituation {
        monthlyNetIncome
        monthlyExpenses
        liquidAssets
        illiquidAssets
        liabilities
        financialReserves

        disclaimerFinancialReserve
        disclaimerLongterm
        disclaimerFlexibility
        disclaimerLifeStandard
        disclaimerFluctuations
      }

      knowledgeAndExperienceStatus
      knowledgeAndExperienceMessage
      knowledgeAndExperience {
        knowHow1
        knowHow2
        knowHow3
        knowHow4
        knowHow5
      }

      referenceBankAccountStatus
      referenceBankAccountMessage
      referenceBankAccount {
        iban
        bic
        owner
        bankName
      }

      amlStatus
      amlMessage
      aml {
        sourceOfFunds
        sourceOfFundsOthers
        sourceOfWealth
        sourceOfWealthOthers
        pep
        pepRelatedPerson
        pepStatusDescription
      }

      address {
        street
        postalCode
        city
        state
        country
      }
      tenant {
        allowReferAFriend
        showReferAFriend
        allowAffiliate
        showAffiliate

        requireVAT
        requireIdentificationNr
        requireWebsite
        requireTIN
        requireOccupation
        requireEducation
        requireAML
        requireWallet
        requireInvestorType
        requireECSPProfile
        requireInvestmentObjective
        requireFinancialSituation
        requireKnowledge
        requireReferenceBank
        requireEmail
        requireMobile
        kycProvider
        walletProvider
        requireTerms1
        requireTerms2
        requireTerms3
        requireTerms4
        requireTerms5
        terms {
          key
          title
          content
          description
        }
      }
      accountType
    }
  }
`;
export const updateIpAccountPaperWallet = /* GraphQL */ `
  mutation UpdateIpAccountPaperWallet($accountId: ID!, $paperWallet: Boolean) {
    updateIPAccountPaperWallet(accountId: $accountId, paperWallet: $paperWallet) {
      tenantId
      name
      id
      createPaperWallet
      mainBlockchainWalletId
      status
      kycStatus
      email
      emailStatus

      mobile
      mobileStatus
      createPaperWallet
      individualCompanyStatus
      individualCompanyMessage

      confirmedTerms1
      confirmedTerms2
      confirmedTerms3
      confirmedTerms4
      confirmedTerms5

      isReferral
      isAffiliate
      hasReferral

      investorProfileStatus
      ecspStatus
      company {
        name
        description
        websiteUrl
        identificationNumber
        ultimateBeneficialOwner {
          title
          firstName
          lastName
          dateOfBirth
          cityOfBirth
          countryOfBirth
          nationality
          countryOfResidence
          phoneNumber
          mobileNumber
        }
      }
      individual {
        title
        gender
        firstName
        lastName
        dateOfBirth
        cityOfBirth
        countryOfBirth
        nationality
        countryOfResidence
        phoneNumber
        mobileNumber
      }

      investmentObjectivesStatus
      investmentObjectivesMessage
      investmentObjectives {
        riskTolerance
        timeHorizon
        investmentReason
      }

      financialSituationStatus
      financialSituationMessage
      financialSituation {
        monthlyNetIncome
        monthlyExpenses
        liquidAssets
        illiquidAssets
        liabilities
        financialReserves

        disclaimerFinancialReserve
        disclaimerLongterm
        disclaimerFlexibility
        disclaimerLifeStandard
        disclaimerFluctuations
      }

      knowledgeAndExperienceStatus
      knowledgeAndExperienceMessage
      knowledgeAndExperience {
        knowHow1
        knowHow2
        knowHow3
        knowHow4
        knowHow5
      }

      referenceBankAccountStatus
      referenceBankAccountMessage
      referenceBankAccount {
        iban
        bic
        owner
        bankName
      }

      amlStatus
      amlMessage
      aml {
        sourceOfFunds
        sourceOfFundsOthers
        sourceOfWealth
        sourceOfWealthOthers
        pep
        pepRelatedPerson
        pepStatusDescription
      }

      address {
        street
        postalCode
        city
        state
        country
      }
      tenant {
        allowReferAFriend
        showReferAFriend
        allowAffiliate
        showAffiliate

        requireVAT
        requireIdentificationNr
        requireWebsite
        requireTIN
        requireOccupation
        requireEducation
        requireAML
        requireWallet
        requireInvestorType
        requireECSPProfile
        requireInvestmentObjective
        requireFinancialSituation
        requireKnowledge
        requireReferenceBank
        requireEmail
        requireMobile
        kycProvider
        walletProvider
        requireTerms1
        requireTerms2
        requireTerms3
        requireTerms4
        requireTerms5
        terms {
          key
          title
          content
          description
        }
      }
      accountType
    }
  }
`;
export const updateIpAccountEmail = /* GraphQL */ `
  mutation UpdateIpAccountEmail($accountId: ID!, $email: String!) {
    updateIPAccountEmail(accountId: $accountId, email: $email) {
      tenantId
      name
      id
      createPaperWallet
      mainBlockchainWalletId
      status
      kycStatus
      email
      emailStatus
      createPaperWallet
      individualCompanyStatus
      individualCompanyMessage

      investorProfileStatus
      ecspStatus
      company {
        name
        description
        websiteUrl
        identificationNumber
        ultimateBeneficialOwner {
          title
          firstName
          lastName
          dateOfBirth
          cityOfBirth
          countryOfBirth
          nationality
          countryOfResidence
          phoneNumber
          mobileNumber
        }
      }
      individual {
        title
        gender
        firstName
        lastName
        dateOfBirth
        cityOfBirth
        countryOfBirth
        nationality
        countryOfResidence
        phoneNumber
        mobileNumber
      }

      investmentObjectivesStatus
      investmentObjectivesMessage
      investmentObjectives {
        riskTolerance
        timeHorizon
        investmentReason
      }

      financialSituationStatus
      financialSituationMessage
      financialSituation {
        monthlyNetIncome
        monthlyExpenses
        liquidAssets
        illiquidAssets
        liabilities
        financialReserves

        disclaimerFinancialReserve
        disclaimerLongterm
        disclaimerFlexibility
        disclaimerLifeStandard
        disclaimerFluctuations
      }

      knowledgeAndExperienceStatus
      knowledgeAndExperienceMessage
      knowledgeAndExperience {
        knowHow1
        knowHow2
        knowHow3
        knowHow4
        knowHow5
      }

      referenceBankAccountStatus
      referenceBankAccountMessage
      referenceBankAccount {
        iban
        bic
        owner
        bankName
      }

      address {
        street
        postalCode
        city
        state
        country
      }
      tenant {
        requireInvestorType
        requireECSPProfile
        requireInvestmentObjective
        requireFinancialSituation
        requireKnowledge
        requireReferenceBank
        requireEmail
        requireMobile
        kycProvider
        walletProvider
        requireTerms1
        requireTerms2
        requireTerms3
        requireTerms4
        requireTerms5
        terms {
          key
          title
          content
          description
        }
      }
      accountType
      mainBlockchainWalletId
      email
      emailStatus
      createPaperWallet

      status
      kycStatus
    }
  }
`;

export const confirmIpAccountEmail = /* GraphQL */ `
  mutation ConfirmIpAccountEmail($accountId: ID!, $emailCode: String!) {
    confirmIPAccountEmail(accountId: $accountId, emailCode: $emailCode) {
      tenantId
      id
      name
      company {
        name
        description
        websiteUrl
        identificationNumber
        ultimateBeneficialOwner {
          gender
          title
          firstName
          lastName
          dateOfBirth
          cityOfBirth
          countryOfBirth
          nationality
          countryOfResidence
          phoneNumber
          mobileNumber
        }
      }
      individual {
        gender
        title
        firstName
        lastName
        dateOfBirth
        cityOfBirth
        countryOfBirth
        nationality
        countryOfResidence
        phoneNumber
        mobileNumber
      }
      address {
        street
        postalCode
        city
        state
        country
      }
      mainBlockchainWalletId
      email
      emailStatus
      createPaperWallet

      accountType
      status
      kycStatus
    }
  }
`;

export const confirmIpAccountEmailPin = /* GraphQL */ `
  mutation ConfirmIpAccountEmailPin($accountId: ID!, $emailPin: String!) {
    confirmIPAccountEmailPin(accountId: $accountId, emailPin: $emailPin) {
      tenantId
      name
      id
      createPaperWallet
      mainBlockchainWalletId
      status
      kycStatus
      email
      emailStatus

      mobile
      mobileStatus
      createPaperWallet
      individualCompanyStatus
      individualCompanyMessage

      confirmedTerms1
      confirmedTerms2
      confirmedTerms3
      confirmedTerms4
      confirmedTerms5

      isReferral
      isAffiliate
      hasReferral

      investorProfileStatus
      ecspStatus
      company {
        name
        description
        websiteUrl
        identificationNumber
        ultimateBeneficialOwner {
          title
          firstName
          lastName
          dateOfBirth
          cityOfBirth
          countryOfBirth
          nationality
          countryOfResidence
          phoneNumber
          mobileNumber
        }
      }
      individual {
        title
        gender
        firstName
        lastName
        dateOfBirth
        cityOfBirth
        countryOfBirth
        nationality
        countryOfResidence
        phoneNumber
        mobileNumber
      }

      investmentObjectivesStatus
      investmentObjectivesMessage
      investmentObjectives {
        riskTolerance
        timeHorizon
        investmentReason
      }

      financialSituationStatus
      financialSituationMessage
      financialSituation {
        monthlyNetIncome
        monthlyExpenses
        liquidAssets
        illiquidAssets
        liabilities
        financialReserves

        disclaimerFinancialReserve
        disclaimerLongterm
        disclaimerFlexibility
        disclaimerLifeStandard
        disclaimerFluctuations
      }

      knowledgeAndExperienceStatus
      knowledgeAndExperienceMessage
      knowledgeAndExperience {
        knowHow1
        knowHow2
        knowHow3
        knowHow4
        knowHow5
      }

      referenceBankAccountStatus
      referenceBankAccountMessage
      referenceBankAccount {
        iban
        bic
        owner
        bankName
      }

      amlStatus
      amlMessage
      aml {
        sourceOfFunds
        sourceOfFundsOthers
        sourceOfWealth
        sourceOfWealthOthers
        pep
        pepRelatedPerson
        pepStatusDescription
      }

      address {
        street
        postalCode
        city
        state
        country
      }
      tenant {
        allowReferAFriend
        showReferAFriend
        allowAffiliate
        showAffiliate

        requireVAT
        requireIdentificationNr
        requireWebsite
        requireTIN
        requireOccupation
        requireEducation
        requireAML
        requireWallet
        requireInvestorType
        requireECSPProfile
        requireInvestmentObjective
        requireFinancialSituation
        requireKnowledge
        requireReferenceBank
        requireEmail
        requireMobile
        kycProvider
        walletProvider
        requireTerms1
        requireTerms2
        requireTerms3
        requireTerms4
        requireTerms5
        terms {
          key
          title
          content
          description
        }
      }
      accountType
    }
  }
`;

export const updateAccountMobile = /* GraphQL */ `
  mutation UpdateAccountMobile($accountId: ID!, $mobile: String!) {
    updateAccountMobile(accountId: $accountId, mobile: $mobile) {
      tenantId
      name
      id
      createPaperWallet
      mainBlockchainWalletId
      status
      kycStatus
      email
      emailStatus

      mobile
      mobileStatus
      createPaperWallet
      individualCompanyStatus
      individualCompanyMessage

      confirmedTerms1
      confirmedTerms2
      confirmedTerms3
      confirmedTerms4
      confirmedTerms5

      isReferral
      isAffiliate
      hasReferral

      investorProfileStatus
      ecspStatus
      company {
        name
        description
        websiteUrl
        identificationNumber
        ultimateBeneficialOwner {
          title
          firstName
          lastName
          dateOfBirth
          cityOfBirth
          countryOfBirth
          nationality
          countryOfResidence
          phoneNumber
          mobileNumber
        }
      }
      individual {
        title
        gender
        firstName
        lastName
        dateOfBirth
        cityOfBirth
        countryOfBirth
        nationality
        countryOfResidence
        phoneNumber
        mobileNumber
      }

      investmentObjectivesStatus
      investmentObjectivesMessage
      investmentObjectives {
        riskTolerance
        timeHorizon
        investmentReason
      }

      financialSituationStatus
      financialSituationMessage
      financialSituation {
        monthlyNetIncome
        monthlyExpenses
        liquidAssets
        illiquidAssets
        liabilities
        financialReserves

        disclaimerFinancialReserve
        disclaimerLongterm
        disclaimerFlexibility
        disclaimerLifeStandard
        disclaimerFluctuations
      }

      knowledgeAndExperienceStatus
      knowledgeAndExperienceMessage
      knowledgeAndExperience {
        knowHow1
        knowHow2
        knowHow3
        knowHow4
        knowHow5
      }

      referenceBankAccountStatus
      referenceBankAccountMessage
      referenceBankAccount {
        iban
        bic
        owner
        bankName
      }

      amlStatus
      amlMessage
      aml {
        sourceOfFunds
        sourceOfFundsOthers
        sourceOfWealth
        sourceOfWealthOthers
        pep
        pepRelatedPerson
        pepStatusDescription
      }

      address {
        street
        postalCode
        city
        state
        country
      }
      tenant {
        allowReferAFriend
        showReferAFriend
        allowAffiliate
        showAffiliate

        requireVAT
        requireIdentificationNr
        requireWebsite
        requireTIN
        requireOccupation
        requireEducation
        requireAML
        requireWallet
        requireInvestorType
        requireECSPProfile
        requireInvestmentObjective
        requireFinancialSituation
        requireKnowledge
        requireReferenceBank
        requireEmail
        requireMobile
        kycProvider
        walletProvider
        requireTerms1
        requireTerms2
        requireTerms3
        requireTerms4
        requireTerms5
        terms {
          key
          title
          content
          description
        }
      }
      accountType
    }
  }
`;

export const confirmAccountMobile = /* GraphQL */ `
  mutation ConfirmAccountMobile($accountId: ID!, $mobileCode: String!) {
    confirmAccountMobile(accountId: $accountId, mobileCode: $mobileCode) {
      tenantId
      name
      id
      createPaperWallet
      mainBlockchainWalletId
      status
      kycStatus
      email
      emailStatus

      mobile
      mobileStatus
      createPaperWallet
      individualCompanyStatus
      individualCompanyMessage

      confirmedTerms1
      confirmedTerms2
      confirmedTerms3
      confirmedTerms4
      confirmedTerms5

      isReferral
      isAffiliate
      hasReferral

      investorProfileStatus
      ecspStatus
      company {
        name
        description
        websiteUrl
        identificationNumber
        ultimateBeneficialOwner {
          title
          firstName
          lastName
          dateOfBirth
          cityOfBirth
          countryOfBirth
          nationality
          countryOfResidence
          phoneNumber
          mobileNumber
        }
      }
      individual {
        title
        gender
        firstName
        lastName
        dateOfBirth
        cityOfBirth
        countryOfBirth
        nationality
        countryOfResidence
        phoneNumber
        mobileNumber
      }

      investmentObjectivesStatus
      investmentObjectivesMessage
      investmentObjectives {
        riskTolerance
        timeHorizon
        investmentReason
      }

      financialSituationStatus
      financialSituationMessage
      financialSituation {
        monthlyNetIncome
        monthlyExpenses
        liquidAssets
        illiquidAssets
        liabilities
        financialReserves

        disclaimerFinancialReserve
        disclaimerLongterm
        disclaimerFlexibility
        disclaimerLifeStandard
        disclaimerFluctuations
      }

      knowledgeAndExperienceStatus
      knowledgeAndExperienceMessage
      knowledgeAndExperience {
        knowHow1
        knowHow2
        knowHow3
        knowHow4
        knowHow5
      }

      referenceBankAccountStatus
      referenceBankAccountMessage
      referenceBankAccount {
        iban
        bic
        owner
        bankName
      }

      amlStatus
      amlMessage
      aml {
        sourceOfFunds
        sourceOfFundsOthers
        sourceOfWealth
        sourceOfWealthOthers
        pep
        pepRelatedPerson
        pepStatusDescription
      }

      address {
        street
        postalCode
        city
        state
        country
      }
      tenant {
        allowReferAFriend
        showReferAFriend
        allowAffiliate
        showAffiliate

        requireVAT
        requireIdentificationNr
        requireWebsite
        requireTIN
        requireOccupation
        requireEducation
        requireAML
        requireWallet
        requireInvestorType
        requireECSPProfile
        requireInvestmentObjective
        requireFinancialSituation
        requireKnowledge
        requireReferenceBank
        requireEmail
        requireMobile
        kycProvider
        walletProvider
        requireTerms1
        requireTerms2
        requireTerms3
        requireTerms4
        requireTerms5
        terms {
          key
          title
          content
          description
        }
      }
      accountType
    }
  }
`;

export const confirmIpLinkAccount = /* GraphQL */ `
  mutation ConfirmIpLinkAccount($accountId: ID, $paymentAccountId: ID, $confirmationCode: String) {
    confirmIPLinkAccount(
      accountId: $accountId
      paymentAccountId: $paymentAccountId
      confirmationCode: $confirmationCode
    )
  }
`;

export const transferIpToken = /* GraphQL */ `
  mutation TransferIpToken($accountId: ID, $input: BlockchainWalletTransferInput) {
    transferIPToken(accountId: $accountId, input: $input) {
      tenantId
      transactionId
      amount
      status
      message
      transactionData {
        txTo
        txValue
      }
    }
  }
`;

export const listTokenOnMarket = /* GraphQL */ `
  mutation ListTokenOnMarket($accountId: ID, $input: BlockchainWalletListingInput) {
    listTokenOnMarket(accountId: $accountId, input: $input) {
      tenantId
      transactionId
      amount
      status
      message
      transactionData {
        txTo
        txValue
      }
    }
  }
`;

export const setIpMainBlockchainWallet = /* GraphQL */ `
  mutation SetIpMainBlockchainWallet($accountId: ID, $blockchainWalletAccountId: ID) {
    setIPMainBlockchainWallet(
      accountId: $accountId
      blockchainWalletAccountId: $blockchainWalletAccountId
    )
  }
`;

export const generateIPSigndSessionToken = /* GraphQL */ `
  mutation generateIPSigndSessionToken($accountId: ID!) {
    generateIPSigndSessionToken(accountId: $accountId) {
      id
      session_token
    }
  }
`;

export const confirmSigndIdentification = /* GraphQL */ `
  mutation confirmSigndIdentification($accountId: ID!) {
    confirmSigndIdentification(accountId: $accountId) {
      tenantId
      name
      id
      createPaperWallet
      mainBlockchainWalletId
      status
      kycStatus
      email
      emailStatus

      mobile
      mobileStatus
      createPaperWallet
      individualCompanyStatus
      individualCompanyMessage

      confirmedTerms1
      confirmedTerms2
      confirmedTerms3
      confirmedTerms4
      confirmedTerms5

      isReferral
      isAffiliate
      hasReferral

      investorProfileStatus
      ecspStatus
      company {
        name
        description
        websiteUrl
        identificationNumber
        ultimateBeneficialOwner {
          title
          firstName
          lastName
          dateOfBirth
          cityOfBirth
          countryOfBirth
          nationality
          countryOfResidence
          phoneNumber
          mobileNumber
        }
      }
      individual {
        title
        gender
        firstName
        lastName
        dateOfBirth
        cityOfBirth
        countryOfBirth
        nationality
        countryOfResidence
        phoneNumber
        mobileNumber
      }

      investmentObjectivesStatus
      investmentObjectivesMessage
      investmentObjectives {
        riskTolerance
        timeHorizon
        investmentReason
      }

      financialSituationStatus
      financialSituationMessage
      financialSituation {
        monthlyNetIncome
        monthlyExpenses
        liquidAssets
        illiquidAssets
        liabilities
        financialReserves

        disclaimerFinancialReserve
        disclaimerLongterm
        disclaimerFlexibility
        disclaimerLifeStandard
        disclaimerFluctuations
      }

      knowledgeAndExperienceStatus
      knowledgeAndExperienceMessage
      knowledgeAndExperience {
        knowHow1
        knowHow2
        knowHow3
        knowHow4
        knowHow5
      }

      referenceBankAccountStatus
      referenceBankAccountMessage
      referenceBankAccount {
        iban
        bic
        owner
        bankName
      }

      amlStatus
      amlMessage
      aml {
        sourceOfFunds
        sourceOfFundsOthers
        sourceOfWealth
        sourceOfWealthOthers
        pep
        pepRelatedPerson
        pepStatusDescription
      }

      address {
        street
        postalCode
        city
        state
        country
      }
      tenant {
        allowReferAFriend
        showReferAFriend
        allowAffiliate
        showAffiliate

        requireVAT
        requireIdentificationNr
        requireWebsite
        requireTIN
        requireOccupation
        requireEducation
        requireAML
        requireWallet
        requireInvestorType
        requireECSPProfile
        requireInvestmentObjective
        requireFinancialSituation
        requireKnowledge
        requireReferenceBank
        requireEmail
        requireMobile
        kycProvider
        walletProvider
        requireTerms1
        requireTerms2
        requireTerms3
        requireTerms4
        requireTerms5
        terms {
          key
          title
          content
          description
        }
      }
      accountType
    }
  }
`;

export const generateSumsubToken = /* GraphQL */ `
  mutation GenerateSumsubToken($accountId: ID!) {
    generateSumsubToken(accountId: $accountId) {
      kycUrl
      kycSession

      tenantId
      name
      id
      createPaperWallet
      mainBlockchainWalletId
      status
      kycStatus
      email
      emailStatus
      createPaperWallet
      individualCompanyStatus
      individualCompanyMessage

      confirmedTerms1
      confirmedTerms2
      confirmedTerms3
      confirmedTerms4
      confirmedTerms5

      isReferral
      isAffiliate
      hasReferral

      investorProfileStatus
      ecspStatus
      company {
        name
        description
        websiteUrl
        identificationNumber
        ultimateBeneficialOwner {
          title
          firstName
          lastName
          dateOfBirth
          cityOfBirth
          countryOfBirth
          nationality
          countryOfResidence
          phoneNumber
          mobileNumber
        }
      }
      individual {
        title
        gender
        firstName
        lastName
        dateOfBirth
        cityOfBirth
        countryOfBirth
        nationality
        countryOfResidence
        phoneNumber
        mobileNumber
      }

      investmentObjectivesStatus
      investmentObjectivesMessage
      investmentObjectives {
        riskTolerance
        timeHorizon
        investmentReason
      }

      financialSituationStatus
      financialSituationMessage
      financialSituation {
        monthlyNetIncome
        monthlyExpenses
        liquidAssets
        illiquidAssets
        liabilities
        financialReserves

        disclaimerFinancialReserve
        disclaimerLongterm
        disclaimerFlexibility
        disclaimerLifeStandard
        disclaimerFluctuations
      }

      knowledgeAndExperienceStatus
      knowledgeAndExperienceMessage
      knowledgeAndExperience {
        knowHow1
        knowHow2
        knowHow3
        knowHow4
        knowHow5
      }

      referenceBankAccountStatus
      referenceBankAccountMessage
      referenceBankAccount {
        iban
        bic
        owner
        bankName
      }

      address {
        street
        postalCode
        city
        state
        country
      }
      tenant {
        requireInvestorType
        requireECSPProfile
        requireInvestmentObjective
        requireFinancialSituation
        requireKnowledge
        requireReferenceBank
        requireEmail
        kycProvider
        walletProvider
        requireTerms1
        requireTerms2
        requireTerms3
        requireTerms4
        requireTerms5
        terms {
          key
          title
          content
          description
        }
      }
      accountType
    }
  }
`;

export const generateGubbiKYCSession = /* GraphQL */ `
  mutation GenerateGubbiKYCSession($accountId: ID!) {
    generateGubbiKYCSession(accountId: $accountId) {
      kycUrl
      kycSession

      tenantId
      name
      id
      createPaperWallet
      mainBlockchainWalletId
      status
      kycStatus
      email
      emailStatus
      createPaperWallet
      individualCompanyStatus
      individualCompanyMessage

      confirmedTerms1
      confirmedTerms2
      confirmedTerms3
      confirmedTerms4
      confirmedTerms5

      isReferral
      isAffiliate
      hasReferral

      investorProfileStatus
      ecspStatus
      company {
        name
        description
        websiteUrl
        identificationNumber
        ultimateBeneficialOwner {
          title
          firstName
          lastName
          dateOfBirth
          cityOfBirth
          countryOfBirth
          nationality
          countryOfResidence
          phoneNumber
          mobileNumber
        }
      }
      individual {
        title
        gender
        firstName
        lastName
        dateOfBirth
        cityOfBirth
        countryOfBirth
        nationality
        countryOfResidence
        phoneNumber
        mobileNumber
      }

      investmentObjectivesStatus
      investmentObjectivesMessage
      investmentObjectives {
        riskTolerance
        timeHorizon
        investmentReason
      }

      financialSituationStatus
      financialSituationMessage
      financialSituation {
        monthlyNetIncome
        monthlyExpenses
        liquidAssets
        illiquidAssets
        liabilities
        financialReserves

        disclaimerFinancialReserve
        disclaimerLongterm
        disclaimerFlexibility
        disclaimerLifeStandard
        disclaimerFluctuations
      }

      knowledgeAndExperienceStatus
      knowledgeAndExperienceMessage
      knowledgeAndExperience {
        knowHow1
        knowHow2
        knowHow3
        knowHow4
        knowHow5
      }

      referenceBankAccountStatus
      referenceBankAccountMessage
      referenceBankAccount {
        iban
        bic
        owner
        bankName
      }

      address {
        street
        postalCode
        city
        state
        country
      }
      tenant {
        requireInvestorType
        requireECSPProfile
        requireInvestmentObjective
        requireFinancialSituation
        requireKnowledge
        requireReferenceBank
        requireEmail
        requireMobile
        kycProvider
        walletProvider
        requireTerms1
        requireTerms2
        requireTerms3
        requireTerms4
        requireTerms5
        terms {
          key
          title
          content
          description
        }
      }
      accountType
    }
  }
`;
