import createSelector from 'src/utils/reselect';
import { Map } from 'immutable';

const postbox = state => state.get('postbox', new Map());

export const getPostboxStatus = createSelector(postbox, data => data.get('status'));
export const getPostboxData = createSelector(postbox, data => data.get('data') || '');
export const getPostboxNextToken = createSelector(postbox, data => data.get('nextToken') || '');
export const getPostboxLimit = createSelector(postbox, data => data.get('limit') || '');

const getPostboxMessage = createSelector(postbox, data => data.get('message') || {});

export const getPostboxMessageStatus = createSelector(getPostboxMessage, data =>
  data.get('status'),
);
// export const getPostboxMessageData = createSelector(
//   getPostboxMessage,
//   data => data.get('data') || {},
// );
