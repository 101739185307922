import { fromJS } from 'immutable';
import { STATUS } from 'src/constants/Status';
import i18n from '../../i18n';
import { actionsTypes } from './constants';

const initialState = fromJS({
  status: STATUS.LOADING,
  portalStatus: STATUS.UNINITIALIZED,
  collapsedSidebar: false,
  activeAccount: {
    id: localStorage.getItem('activeAccount'),
  },
  redirect: null,
  language: localStorage.getItem('i18nextLng'),
  isMarketPlaceActive: false,
  isGovernanceActive: false,
  requireFullRegistration: true,
  webpage: {},
  changeLanguage: {
    showModal: false,
  },
});

const appSettingsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionsTypes.TOGGLE_SIDEBAR:
      return state.set('collapsedSidebar', !state.get('collapsedSidebar'));

    case actionsTypes.SET_WEBPAGE:
      return state.set('webpage', payload.webpage);

    case actionsTypes.ACTIVE_ACCOUNT:
      localStorage.setItem('activeAccount', payload.accountId);
      return state
        .setIn(['activeAccount', 'id'], payload.accountId)
        .setIn(['activeAccount', 'affiliateId'], payload.affiliateId)
        .setIn(['activeAccount', 'name'], payload.name);

    case actionsTypes.SET_TENANT_METADATA:
      return state
        .set('publicKey', payload.publicKey)
        .set('isMultiLang', payload.isMultiLang)
        .set('languages', payload.languages)
        .set('locale', payload.locale)
        .set('allowPaperWallet', payload.allowPaperWallet)
        .set('allowMMLogin', payload.allowMMLogin)
        .set('requireFullRegistration', payload.requireFullRegistration)
        .set('allowCreateWallet', payload.allowCreateWallet);

    case actionsTypes.ACTIVE_LANGUAGE:
      i18n.changeLanguage(payload.language);
      localStorage.setItem('i18nextLng', payload.language);
      window.location.reload();

      return state.set('language', payload.language);

    case actionsTypes.FETCH_PORTAL.PENDING:
      return state.set('status', STATUS.LOADING).set('portalStatus', STATUS.LOADING);
    case actionsTypes.FETCH_PORTAL.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .set('portalStatus', STATUS.SUCCESS)
        .set('portal', payload.portal || {})
        .set('accounts', payload.accounts || [])
        .set('publicKey', payload.portal?.myPortal?.publicKey || '')
        .set('allowAffiliate', payload.allowAffiliate || '')
        .set('allowReferAFriend', payload.allowReferAFriend || '')
        .set('showReferAFriend', payload.showReferAFriend || '')
        .set('showAffiliate', payload.showAffiliate || '')
        .set('tenantId', payload.portal?.myPortal?.tenantId || '');
    case actionsTypes.FETCH_PORTAL.ERROR:
      return state.set('status', STATUS.ERROR).set('portalStatus', STATUS.ERROR);

    case actionsTypes.FETCH_PAGE.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.FETCH_PAGE.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('page', payload.data || '');
    case actionsTypes.FETCH_PAGE.ERROR:
      return state.set('status', STATUS.ERROR);
    case actionsTypes.SET_IS_MARKET_PLACE_AVAILABLE:
      return state.set('isMarketPlaceActive', payload.isActive);
    case actionsTypes.SET_IS_GOVERNANCE_AVAILABLE:
      return state.set('isGovernanceActive', payload.isActive);
    case actionsTypes.SET_ALLOW_WALLETLOGIN:
      return state.set('allowWalletLogin', payload.isActive);
    case actionsTypes.SET_REQUIRE_AFFILIATELINK:
      return state.set('requireAffiliateLink', payload.isActive);
    case actionsTypes.SET_PAGE_HIERARCHY:
      return state.set('pageHierarchy', payload.pageHierarchy);
    case actionsTypes.SET_GRAPHQL:
      return state.set('graphql', payload.graphql).set('graphqlAuth', payload.auth);
    case actionsTypes.SET_GRAPHQL_PUBLIC:
      return state.set('publicGraphql', payload.publicGraphql).set('apiKey', payload.apiKey);

    case actionsTypes.SET_TENANTID:
      return state.set('tenantId', payload.tenantId);

    case actionsTypes.SET_REDIRECT:
      return state.set('redirect', payload.redirect);

    case actionsTypes.SET_PORTALABILITY:
      return state.set('portalAbilitySet', payload.abilitySet);

    case actionsTypes.SWITCH_LANGUAGE_MODAL: {
      const previousStatus = state.getIn(['changeLanguage', 'showModal']);
      return state.setIn(['changeLanguage', 'showModal'], !previousStatus);
    }

    default:
      return state;
  }
};
export default appSettingsReducer;
