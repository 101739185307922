import { actionsTypes } from './constants';

export const fetchAllReportsActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_ALL_REPORT.PENDING,
    payload,
  }),
  success: ({ reports }) => ({
    type: actionsTypes.FETCH_ALL_REPORT.SUCCESS,
    payload: { reports },
  }),
  error: () => ({
    type: actionsTypes.FETCH_ALL_REPORT.ERROR,
  }),
};
