import { actionsTypes } from './constants';

export const fetchPublicOfferingWidgetAction = {
  pending: payload => ({
    type: actionsTypes.FETCH_PUBLIC_OFFERING.PENDING,
    payload,
  }),
  success: ({ offering }) => ({
    type: actionsTypes.FETCH_PUBLIC_OFFERING.SUCCESS,
    payload: { offering },
  }),
  error: () => ({
    type: actionsTypes.FETCH_PUBLIC_OFFERING.ERROR,
  }),
};

export const fetchPublicOfferingQuoteWidgetAction = {
  pending: payload => ({
    type: actionsTypes.FETCH_PUBLIC_QUOTE.PENDING,
    payload,
  }),
  success: ({ quote }) => ({
    type: actionsTypes.FETCH_PUBLIC_QUOTE.SUCCESS,
    payload: { quote },
  }),
  error: () => ({
    type: actionsTypes.FETCH_PUBLIC_QUOTE.ERROR,
  }),
};

export const setOrderProcess = payload => ({ type: actionsTypes.ORDER_PROCESS, payload });
