import { Amplify } from 'aws-amplify';
import 'react-app-polyfill/stable';
import 'core-js';
import {
  setAllowWalletLogin,
  setGraphql,
  setGraphqlPublic,
  setIsGovernanceActive,
  setIsMarketPlaceActive,
  setRequireAffiliateLink,
  setTenantId,
  setWebpageAction,
  setWebpageHierarchy,
} from 'src/components/AppSettings/appSettings.action';
import { isEmpty } from 'lodash';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { getEnvDetails } from './services/evn';
import { store } from './store/store';
import i18n from './i18n';

getEnvDetails().then(res => {
  Amplify.configure(res);
  let {
    isMarketPlaceActive = false,
    colorSchema,
    isGovernanceActive = false,
    requireAffiliate = false,
    allowMMLogin = false,
    webpage = {},
    pageHierarchy = [],
    supportedLanguages = [],
    standardLanguage = 'en',
    aws_appsync_graphqlEndpoint,
    aws_appsync_public_graphqlEndpoint,
    aws_appsync_public_graphqlEndpoint_apiKey,
    aws_appsync_authenticationType,
    tenantId,
    googleAnalyticsMeasurementId,
    googleTagContainer,
    walletConnect,
  } = res;

  if (!supportedLanguages.includes(i18n.language?.substr(0, 2))) {
    i18n.changeLanguage(standardLanguage);
  }

  if (!isEmpty(googleAnalyticsMeasurementId)) {
    localStorage.setItem('measurementIds', googleAnalyticsMeasurementId);
  }

  if (!isEmpty(googleTagContainer)) {
    localStorage.setItem('containerId', googleTagContainer);
  }

  if (!isEmpty(walletConnect)) {
    localStorage.setItem('walletConnect', walletConnect);
  }

  store.dispatch(setTenantId(tenantId));
  store.dispatch(setIsMarketPlaceActive(isMarketPlaceActive));
  store.dispatch(setIsGovernanceActive(isGovernanceActive));
  store.dispatch(setRequireAffiliateLink(requireAffiliate));
  store.dispatch(setAllowWalletLogin(allowMMLogin));
  store.dispatch(setWebpageAction(webpage));
  store.dispatch(setWebpageHierarchy(pageHierarchy));
  store.dispatch(setGraphql(aws_appsync_graphqlEndpoint, aws_appsync_authenticationType));
  store.dispatch(
    setGraphqlPublic(aws_appsync_public_graphqlEndpoint, aws_appsync_public_graphqlEndpoint_apiKey),
  );

  (async () => {
    await import('./scss/Themes/' + colorSchema.toLowerCase() + '.scss');
    import('./AppRenderer');
  })();
});
