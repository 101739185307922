export const getAllReports = /* GraphQL */ `
  query Portal($filter: ModelActivityFilterInput, $sortDirection: ModelSortDirection, $limit: Int) {
    portal {
      me {
        portalUserId
        firstName
        lastName
        tenantId
        activities(filter: $filter, sortDirection: $sortDirection, limit: $limit) {
          items {
            id
            name
            publishingDate
            activityType
            status
            title
            slug
            description
            content
            featured

          }
        }
      }
    }
  }
`;
