import { actionsTypes } from './constants';

export const fetchGiveawaysActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_GIVEAWAYS.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.FETCH_GIVEAWAYS.SUCCESS,
    payload: { data },
  }),
  error: () => ({
    type: actionsTypes.FETCH_GIVEAWAYS.ERROR,
  }),
};
