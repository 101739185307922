export const getOffering = /* GraphQL */ `
  query Query($tenantId: ID!, $offeringId: ID!, $referral: Boolean) {
    getOffering(tenantId: $tenantId, offeringId: $offeringId, referral: $referral) {
      tenantId
      id
      name
      symbol

      description
      overview
      faq

      visibility
      advanced
      hasOverviewImage
      hasHeroImage
      videoUrl

      tokenDetails {
        field0
        field1
        field2
        field3
      }

      fundingSupplyGoal
      fundingSupplyCap
      fundingCurrency
      fundingAllowedCurrencies
      fundingPricePerToken
      fundingPricePerTokenList {
        currency
        price
        vatPercentage
        bruttoPrice
        includedVAT
        nettoPrice
      }
    }
  }
`;

export const getQuote = /* GraphQL */ `
  query Query(
    $tenantId: ID!
    $offeringId: ID!
    $currency: Currency!
    $amount: String
    $tokenAmount: String
    $referral: Boolean
  ) {
    getQuote(
      tenantId: $tenantId
      offeringId: $offeringId
      currency: $currency
      amount: $amount
      tokenAmount: $tokenAmount
      referral: $referral
    ) {
      tenantId
      offeringId
      quote
      currency
      bonusToken
      amount
      provider
      errorMsg
    }
  }
`;
