import { fromJS, List, Map } from 'immutable';
import { STATUS } from 'src/constants/Status';
import { actionsTypes } from './constants';

const initialState = fromJS({
  status: null,
  newsAndEvents: [],
  featured: [],
  others: [],
  othersStatus: null,
  featuredStatus: null,
  details: {
    status: null,
    newsAndEvent: {},
  },
});

const newsAndEventsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionsTypes.FETCH_ALL_NEWS_AND_EVENTS.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.FETCH_ALL_NEWS_AND_EVENTS.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .set('newsAndEvents', fromJS(payload.newsAndEvents) || new List());
    case actionsTypes.FETCH_ALL_NEWS_AND_EVENTS.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.FETCH_FEATURED_NEWS_AND_EVENTS.PENDING:
      return state.set('featuredStatus', STATUS.LOADING);
    case actionsTypes.FETCH_FEATURED_NEWS_AND_EVENTS.SUCCESS: {
      return state
        .set('featuredStatus', STATUS.SUCCESS)
        .set('featured', fromJS(payload.newsAndEvents) || new List());
    }
    case actionsTypes.FETCH_FEATURED_NEWS_AND_EVENTS.ERROR:
      return state.set('featuredStatus', STATUS.ERROR);

    case actionsTypes.FETCH_OTHER_NEWS_AND_EVENTS.PENDING:
      return state.set('othersStatus', STATUS.LOADING);
    case actionsTypes.FETCH_OTHER_NEWS_AND_EVENTS.SUCCESS: {
      return state
        .set('othersStatus', STATUS.SUCCESS)
        .set('others', fromJS(payload.newsAndEvents) || new List());
    }
    case actionsTypes.FETCH_OTHER_NEWS_AND_EVENTS.ERROR:
      return state.set('othersStatus', STATUS.ERROR);

    case actionsTypes.FETCH_DETAILS_NEWS_AND_EVENT.PENDING:
      return state.setIn(['details', 'status'], STATUS.LOADING);
    case actionsTypes.FETCH_DETAILS_NEWS_AND_EVENT.SUCCESS:
      return state
        .setIn(['details', 'status'], STATUS.SUCCESS)
        .setIn(['details', 'newsAndEvent'], fromJS(payload.newsAndEvent) || new Map());
    case actionsTypes.FETCH_DETAILS_NEWS_AND_EVENT.ERROR:
      return state.setIn(['details', 'status'], STATUS.ERROR);

    default:
      return state;
  }
};
export default newsAndEventsReducer;
