export const readPayments = /* GraphQL */ `
  query Portal(
    $accountId: ModelIDKeyConditionInput
    $filter: PaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    myPortal {
      id
      tenantId
      accounts(accountId: $accountId) {
        items {
          status
          accountId
          account {
            id
            payments(filter: $filter, limit: $limit, nextToken: $nextToken) {
              items {
                tenantId
                transactionId
                accountId
              }
              nextToken
            }
          }
        }
      }
    }
  }
`;
