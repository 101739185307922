import { asyncActionType } from 'src/utils/reduxActions';

export const NAMESPACE = 'PAGE';

const FETCH_PAGE = asyncActionType(`${NAMESPACE} FETCH`);
const FETCH_LAYOUT = asyncActionType(`${NAMESPACE} LAYOUT FETCH`);
const CLEAR_VALUES = `${NAMESPACE} CLEAR_VALUES`;

export const actionsTypes = {
  FETCH_PAGE,
  FETCH_LAYOUT,
  CLEAR_VALUES,
};
