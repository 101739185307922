import { all, takeLatest, put, call } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
import { actionsTypes } from './constants';
import { fetchOfferingsActions, fetchOfferingDetailsAction } from './offering.action';
import { getOfferingDetails, getAllOfferings } from './GraphQL/queries';

export function* fetchAllOfferings({ payload }) {
  try {
    const params = {
      accountId: {
        eq: '',
      },
    };
    const response = yield call([API, 'graphql'], graphqlOperation(getAllOfferings, params));
    const offerings = response.data.myPortal.offerings.items;

    yield put(fetchOfferingsActions.success({ offerings }));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchOfferingsActions.error());
  }
}
export function* fetchOfferingDetails({ payload }) {
  const { id } = payload;
  try {
    const params = {
      id: {
        eq: id,
      },
      accountId: localStorage.getItem('activeAccount'),
    };
    const response = yield call([API, 'graphql'], graphqlOperation(getOfferingDetails, params));
    const offering = response.data.myPortal.offerings.items[0];

    yield put(fetchOfferingDetailsAction.success({ offering }));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(fetchOfferingDetailsAction.error());
  }
}

export default function* newsAndEventsSaga() {
  yield all([
    takeLatest(actionsTypes.FETCH_ALL_OFFERING.PENDING, fetchAllOfferings),
    takeLatest(actionsTypes.FETCH_OFFERING_DETAILS.PENDING, fetchOfferingDetails),
  ]);
}
