import { actionsTypes } from './constants';

export const fetchPageAction = {
  pending: payload => ({
    type: actionsTypes.FETCH_PAGE.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.FETCH_PAGE.SUCCESS,
    payload: { data },
  }),
  error: () => ({
    type: actionsTypes.FETCH_PAGE.ERROR,
  }),
};

export const fetchPageLayoutAction = {
  pending: payload => ({
    type: actionsTypes.FETCH_LAYOUT.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.FETCH_LAYOUT.SUCCESS,
    payload: { data },
  }),
  error: () => ({
    type: actionsTypes.FETCH_LAYOUT.ERROR,
  }),
};

export const resetValues = () => ({ type: actionsTypes.CLEAR_VALUES });
