export const getAllNewsAndEvents = /* GraphQL */ `
  query Portal(
    $filter: ModelActivityFilterInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    myPortal {
      tenantId
      activities(
        filter: $filter
        sortDirection: $sortDirection
        limit: $limit
        nextToken: $nextToken
      ) {
        nextToken
        items {
          tenantId
          id
          name
          publishingDate
          activityType
          status
          title
          slug
          description
          content
          featured
          hasOverviewImage
        }
      }
    }
  }
`;

export const getNewsAndEventDetails = /* GraphQL */ `
  query Portal($slug: ModelStringKeyConditionInput, $filter: ModelActivityFilterInput) {
    myPortal {
      tenantId
      activityBySlug(slug: $slug, filter: $filter) {
        items {
          tenantId
          id
          name
          publishingDate
          activityType
          status
          title
          slug
          description
          content
        }
      }
    }
  }
`;
