import { actionsTypes } from './constants';

export const fetchAccountActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_ACCOUNT.PENDING,
    payload,
  }),
  success: ({ account, users, blockchainWallets }) => ({
    type: actionsTypes.FETCH_ACCOUNT.SUCCESS,
    payload: { account, users, blockchainWallets },
  }),
  error: () => ({
    type: actionsTypes.FETCH_ACCOUNT.ERROR,
  }),
};

export const fetchAccountPortfolioActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_ACCOUNT_PORTFOLIO.PENDING,
    payload,
  }),
  success: ({ assets, nextToken, statistics, orders, payments, transactions }) => ({
    type: actionsTypes.FETCH_ACCOUNT_PORTFOLIO.SUCCESS,
    payload: { assets, nextToken, statistics, orders, payments, transactions },
  }),
  error: () => ({
    type: actionsTypes.FETCH_ACCOUNT_PORTFOLIO.ERROR,
  }),
};

export const fetchAccountAssetActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_ACCOUNT_ASSET.PENDING,
    payload,
  }),
  success: ({ asset, nextToken }) => ({
    type: actionsTypes.FETCH_ACCOUNT_ASSET.SUCCESS,
    payload: { asset, nextToken },
  }),
  error: () => ({
    type: actionsTypes.FETCH_ACCOUNT_ASSET.ERROR,
  }),
};

export const fetchAccountOrderActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_ACCOUNT_ORDERS.PENDING,
    payload,
  }),
  success: ({ orders }) => ({
    type: actionsTypes.FETCH_ACCOUNT_ORDERS.SUCCESS,
    payload: { orders },
  }),
  error: () => ({
    type: actionsTypes.FETCH_ACCOUNT_ORDERS.ERROR,
  }),
};

export const fetchAccountTransfersActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_ACCOUNT_TRANSFERS.PENDING,
    payload,
  }),
  success: ({ transfers, nextToken }) => ({
    type: actionsTypes.FETCH_ACCOUNT_TRANSFERS.SUCCESS,
    payload: { transfers, nextToken },
  }),
  error: () => ({
    type: actionsTypes.FETCH_ACCOUNT_TRANSFERS.ERROR,
  }),
};

export const fetchAccountOpenOrderActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_ACCOUNT_OPENORDERS.PENDING,
    payload,
  }),
  success: ({ openOrders }) => ({
    type: actionsTypes.FETCH_ACCOUNT_OPENORDERS.SUCCESS,
    payload: { openOrders },
  }),
  error: () => ({
    type: actionsTypes.FETCH_ACCOUNT_OPENORDERS.ERROR,
  }),
};

export const fetchBlockchainWalletActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_WALLET.PENDING,
    payload,
  }),
  success: ({ blockchainWallet }) => ({
    type: actionsTypes.FETCH_WALLET.SUCCESS,
    payload: { blockchainWallet },
  }),
  error: () => ({
    type: actionsTypes.FETCH_WALLET.ERROR,
  }),
};

export const addAccountActions = {
  pending: payload => ({
    type: actionsTypes.ADD_ACCOUNT.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.ADD_ACCOUNT.SUCCESS,
    payload: { account: data },
  }),
  error: () => ({
    type: actionsTypes.ADD_ACCOUNT.ERROR,
  }),
};

export const fetchKycSignDDataActions = {
  pending: () => ({
    type: actionsTypes.GET_SIGND_DETAILS.PENDING,
  }),
  success: credentials => ({
    type: actionsTypes.GET_SIGND_DETAILS.SUCCESS,
    payload: { credentials },
  }),
  error: () => ({
    type: actionsTypes.GET_SIGND_DETAILS.ERROR,
  }),
};

export const confirmKycSignDDataActions = {
  pending: () => ({
    type: actionsTypes.CONFIRM_SIGND.PENDING,
  }),
  success: credentials => ({
    type: actionsTypes.CONFIRM_SIGND.SUCCESS,
    payload: { credentials },
  }),
  error: () => ({
    type: actionsTypes.CONFIRM_SIGND.ERROR,
  }),
};

export const fetchKycGubbiDataActions = {
  pending: () => ({
    type: actionsTypes.FETCH_GUBBI_KYC.PENDING,
  }),
  success: ({ url, account }) => ({
    type: actionsTypes.FETCH_GUBBI_KYC.SUCCESS,
    payload: { url, account },
  }),
  error: () => ({
    type: actionsTypes.FETCH_GUBBI_KYC.ERROR,
  }),
};

export const fetchKycSumsubDataActions = {
  pending: () => ({
    type: actionsTypes.FETCH_SUMSUB_KYC.PENDING,
  }),
  success: ({ credentials, account }) => ({
    type: actionsTypes.FETCH_SUMSUB_KYC.SUCCESS,
    payload: { credentials, account },
  }),
  error: () => ({
    type: actionsTypes.FETCH_SUMSUB_KYC.ERROR,
  }),
};

export const changeProfilePasswordAction = {
  pending: payload => ({
    type: actionsTypes.CHANGE_PROFILE_PASSWORD.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.CHANGE_PROFILE_PASSWORD.SUCCESS,
    payload: { data },
  }),
  error: () => ({
    type: actionsTypes.CHANGE_PROFILE_PASSWORD.ERROR,
  }),
};

export const setMainBlockchainWalletActions = {
  pending: payload => ({
    type: actionsTypes.SET_MAINBLOCKCHAINWALLET.PENDING,
    payload,
  }),
  success: () => ({
    type: actionsTypes.SET_MAINBLOCKCHAINWALLET.SUCCESS,
  }),
  error: () => ({
    type: actionsTypes.SET_MAINBLOCKCHAINWALLET.ERROR,
  }),
};

export const setAccountTypeAction = {
  pending: payload => ({
    type: actionsTypes.SET_ACCOUNT_TYPE.PENDING,
    payload,
  }),
  success: account => ({
    type: actionsTypes.SET_ACCOUNT_TYPE.SUCCESS,
    payload: { account },
  }),
  error: () => ({
    type: actionsTypes.SET_ACCOUNT_TYPE.ERROR,
  }),
};

export const updateAccountActions = {
  pending: payload => ({
    type: actionsTypes.UPDATE_ACCOUNT.PENDING,
    payload,
  }),
  success: ({ account }) => ({
    type: actionsTypes.UPDATE_ACCOUNT.SUCCESS,
    payload: { account },
  }),
  error: () => ({
    type: actionsTypes.UPDATE_ACCOUNT.ERROR,
  }),
};

export const updateAMLActions = {
  pending: payload => ({
    type: actionsTypes.UPDATE_AML.PENDING,
    payload,
  }),
  success: ({ account }) => ({
    type: actionsTypes.UPDATE_AML.SUCCESS,
    payload: { account },
  }),
  error: () => ({
    type: actionsTypes.UPDATE_AML.ERROR,
  }),
};

export const updateInvestmentObjectivesActions = {
  pending: payload => ({
    type: actionsTypes.UPDATE_INVESTMENTOBJECTIVES.PENDING,
    payload,
  }),
  success: ({ account }) => ({
    type: actionsTypes.UPDATE_INVESTMENTOBJECTIVES.SUCCESS,
    payload: { account },
  }),
  error: () => ({
    type: actionsTypes.UPDATE_INVESTMENTOBJECTIVES.ERROR,
  }),
};

export const updateFinancialSituationActions = {
  pending: payload => ({
    type: actionsTypes.UPDATE_FINANCIALSITUATION.PENDING,
    payload,
  }),
  success: ({ account }) => ({
    type: actionsTypes.UPDATE_FINANCIALSITUATION.SUCCESS,
    payload: { account },
  }),
  error: () => ({
    type: actionsTypes.UPDATE_FINANCIALSITUATION.ERROR,
  }),
};

export const updateKnowledgeActions = {
  pending: payload => ({
    type: actionsTypes.UPDATE_KNOWLEDGE.PENDING,
    payload,
  }),
  success: ({ account }) => ({
    type: actionsTypes.UPDATE_KNOWLEDGE.SUCCESS,
    payload: { account },
  }),
  error: () => ({
    type: actionsTypes.UPDATE_KNOWLEDGE.ERROR,
  }),
};

export const updateReferenceBankActions = {
  pending: payload => ({
    type: actionsTypes.UPDATE_REFERENCEBANK.PENDING,
    payload,
  }),
  success: ({ account }) => ({
    type: actionsTypes.UPDATE_REFERENCEBANK.SUCCESS,
    payload: { account },
  }),
  error: () => ({
    type: actionsTypes.UPDATE_REFERENCEBANK.ERROR,
  }),
};

export const updateInvestorTypeActions = {
  pending: payload => ({
    type: actionsTypes.UPDATE_INVESTOR_TYPE.PENDING,
    payload,
  }),
  success: ({ account }) => ({
    type: actionsTypes.UPDATE_INVESTOR_TYPE.SUCCESS,
    payload: { account },
  }),
  error: () => ({
    type: actionsTypes.UPDATE_INVESTOR_TYPE.ERROR,
  }),
};

export const updateAccountPaperWalletActions = {
  pending: payload => ({
    type: actionsTypes.UPDATE_ACCOUNT_PAPERWALLET.PENDING,
    payload,
  }),
  success: ({ account }) => ({
    type: actionsTypes.UPDATE_ACCOUNT_PAPERWALLET.SUCCESS,
    payload: { account },
  }),
  error: () => ({
    type: actionsTypes.UPDATE_ACCOUNT_PAPERWALLET.ERROR,
  }),
};

export const updateAccountEmailActions = {
  pending: payload => ({
    type: actionsTypes.UPDATE_ACCOUNT_EMAIL.PENDING,
    payload,
  }),
  success: ({ account }) => ({
    type: actionsTypes.UPDATE_ACCOUNT_EMAIL.SUCCESS,
    payload: { account },
  }),
  error: () => ({
    type: actionsTypes.UPDATE_ACCOUNT_EMAIL.ERROR,
  }),
};

export const confirmAcountEmailActions = {
  pending: payload => ({
    type: actionsTypes.CONFIRM_UPDATE_EMAIL_ACCOUNT.PENDING,
    payload,
  }),
  success: ({ account }) => ({
    type: actionsTypes.CONFIRM_UPDATE_EMAIL_ACCOUNT.SUCCESS,
    payload: { account },
  }),
  error: () => ({
    type: actionsTypes.CONFIRM_UPDATE_EMAIL_ACCOUNT.ERROR,
  }),
};

export const confirmAcountEmailPinActions = {
  pending: payload => ({
    type: actionsTypes.CONFIRM_UPDATE_EMAIL_PIN_ACCOUNT.PENDING,
    payload,
  }),
  success: ({ account }) => ({
    type: actionsTypes.CONFIRM_UPDATE_EMAIL_PIN_ACCOUNT.SUCCESS,
    payload: { account },
  }),
  error: () => ({
    type: actionsTypes.CONFIRM_UPDATE_EMAIL_PIN_ACCOUNT.ERROR,
  }),
};

export const updateAccountMobileActions = {
  pending: payload => ({
    type: actionsTypes.UPDATE_ACCOUNT_MOBILE.PENDING,
    payload,
  }),
  success: ({ account }) => ({
    type: actionsTypes.UPDATE_ACCOUNT_MOBILE.SUCCESS,
    payload: { account },
  }),
  error: () => ({
    type: actionsTypes.UPDATE_ACCOUNT_MOBILE.ERROR,
  }),
};

export const confirmAcountMobileActions = {
  pending: payload => ({
    type: actionsTypes.CONFIRM_UPDATE_MOBILE_ACCOUNT.PENDING,
    payload,
  }),
  success: ({ account }) => ({
    type: actionsTypes.CONFIRM_UPDATE_MOBILE_ACCOUNT.SUCCESS,
    payload: { account },
  }),
  error: () => ({
    type: actionsTypes.CONFIRM_UPDATE_MOBILE_ACCOUNT.ERROR,
  }),
};

export const confirmLinkPaymentAccountsActions = {
  pending: payload => ({
    type: actionsTypes.CONFIRM_LINK_PAYMENT_ACCOUNT.PENDING,
    payload,
  }),
  success: ({ account }) => ({
    type: actionsTypes.CONFIRM_LINK_PAYMENT_ACCOUNT.SUCCESS,
    payload: { account },
  }),
  error: () => ({
    type: actionsTypes.CONFIRM_LINK_PAYMENT_ACCOUNT.ERROR,
  }),
};

export const addBlockchainWalletActions = {
  pending: payload => ({
    type: actionsTypes.ADD_BLOCKCHAINWALLET.PENDING,
    payload,
  }),
  success: ({ blockchainWallet }) => ({
    type: actionsTypes.ADD_BLOCKCHAINWALLET.SUCCESS,
    payload: { blockchainWallet },
  }),
  error: () => ({
    type: actionsTypes.ADD_BLOCKCHAINWALLET.ERROR,
  }),
};

export const transferTokenFromBlockchainWalletActions = {
  pending: payload => ({
    type: actionsTypes.TRANSFER_TOKEN_FROM_BLOCKCHAINWALLET.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.TRANSFER_TOKEN_FROM_BLOCKCHAINWALLET.SUCCESS,
    payload: { data },
  }),
  error: () => ({
    type: actionsTypes.TRANSFER_TOKEN_FROM_BLOCKCHAINWALLET.ERROR,
  }),
};

export const checkSignatureBlockchainWalletActions = {
  pending: payload => ({
    type: actionsTypes.CHECKSIGNATURE_BLOCKCHAINWALLET.PENDING,
    payload,
  }),
  success: ({ blockchainWallet, account }) => ({
    type: actionsTypes.CHECKSIGNATURE_BLOCKCHAINWALLET.SUCCESS,
    payload: { blockchainWallet, account },
  }),
  error: () => ({
    type: actionsTypes.CHECKSIGNATURE_BLOCKCHAINWALLET.ERROR,
  }),
};

export const setStepActions = payload => ({ type: actionsTypes.SET_STEP, payload });
export const setWalletAddStepActions = payload => ({
  type: actionsTypes.SET_WALLETADD_STEP,
  payload,
});
export const setWalletTransferStepActions = payload => ({
  type: actionsTypes.SET_WALLETTRANSFER_STEP,
  payload,
});
export const setWalletTransferTransactionActions = payload => ({
  type: actionsTypes.SET_WALLETTRANSFER_TRANSACTION,
  payload,
});

export const resetValues = () => ({ type: actionsTypes.CLEAR_VALUES });
export const resetTransactions = () => ({ type: actionsTypes.CLEAR_TRANSACTIONS });

export const switchAccountType = accountType => ({
  type: actionsTypes.SWITCH_ACCOUNT_TYPE,
  accountType,
});

export const toggleCreateAccountModalAction = () => ({
  type: actionsTypes.CREATE_ACCOUNT_MODAL,
});

export const toggleSwitchAccountModalAction = () => ({
  type: actionsTypes.SWITCH_ACCOUNT_MODAL,
});

export const toggleOrderDetailsModal = () => ({
  type: actionsTypes.TOGGLE_ORDER_DETAILS_MODAL,
});

export const setSelectedOrder = id => ({
  type: actionsTypes.SET_SELECTED_ORDER,
  payload: { id },
});

export const fetchOrderDetailsActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_ORDER_DETAILS.PENDING,
    payload,
  }),
  success: ({ details }) => ({
    type: actionsTypes.FETCH_ORDER_DETAILS.SUCCESS,
    payload: { details },
  }),
  error: () => ({
    type: actionsTypes.FETCH_ORDER_DETAILS.ERROR,
  }),
};

export const fetchTransferDetailsActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_ACCOUNT_TRANSFER.PENDING,
    payload,
  }),
  success: ({ transfer, nextToken }) => ({
    type: actionsTypes.FETCH_ACCOUNT_TRANSFER.SUCCESS,
    payload: { transfer, nextToken },
  }),
  error: () => ({
    type: actionsTypes.FETCH_ACCOUNT_TRANSFER.ERROR,
  }),
};

export const setWalletListStepActions = payload => ({
  type: actionsTypes.SET_WALLETLIST_STEP,
  payload,
});

export const setWalletListTransactionActions = payload => ({
  type: actionsTypes.SET_WALLETLIST_TRANSACTION,
  payload,
});

export const listTokenOnMarketActions = {
  pending: payload => ({
    type: actionsTypes.LIST_TOKENONMARKET.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.LIST_TOKENONMARKET.SUCCESS,
    payload: { data },
  }),
  error: () => ({
    type: actionsTypes.LIST_TOKENONMARKET.ERROR,
  }),
};

// Payments
export const fetchAccountPaymentsActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_ACCOUNT_PAYMENTS.PENDING,
    payload,
  }),
  success: ({ data, nextToken }) => ({
    type: actionsTypes.FETCH_ACCOUNT_PAYMENTS.SUCCESS,
    payload: { data, nextToken },
  }),
  error: () => ({
    type: actionsTypes.FETCH_ACCOUNT_PAYMENTS.ERROR,
  }),
};

export const fetchAccountPaymentActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_ACCOUNT_PAYMENT.PENDING,
    payload,
  }),
  success: ({ payment }) => ({
    type: actionsTypes.FETCH_ACCOUNT_PAYMENT.SUCCESS,
    payload: { payment },
  }),
  error: () => ({
    type: actionsTypes.FETCH_ACCOUNT_PAYMENT.ERROR,
  }),
};
export const resetPaymentsValues = () => ({ type: actionsTypes.CLEAR_ACCOUNT_PAYMENT_VALUES });
