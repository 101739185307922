import { asyncActionType } from 'src/utils/reduxActions';

export const NAMESPACE = 'OFFERING';

const FETCH_ALL_OFFERING = asyncActionType(`${NAMESPACE} FETCH ALL`);
const FETCH_OFFERING_DETAILS = asyncActionType(`${NAMESPACE} FETCH DETAILS`);

export const actionsTypes = {
  FETCH_ALL_OFFERING,
  FETCH_OFFERING_DETAILS,
};

// General
export const VISUAL_TYPES = {
  crowdInvesting: 'CROWDINVESTING',
  crowdFunding: 'CROWDFUNDING',
};

export const VISIBILITY = {
  privateSale: 'PRIVATESALE',
  preSale: 'PRESALE',
  preSaleAnnouncement: 'PRESALEANNOUNCEMENT',
  announcement: 'ANNOUNCEMENT',
  public: 'PUBLIC',
  finished: 'FINISHED',
};
