import i18n from 'src/i18n';
import ENV_CONFIG from '../constants/Config';

const getEnvData = async () => {
  const env = await fetch(`${ENV_CONFIG.BASE_URL}/env?id=${ENV_CONFIG.PORTAL_ID}`);
  return env.json();
};

export const getEnvDetails = async () => {
  // const response = await fetch(`${ENV_CONFIG.BASE_URL}/env?id=${ENV_CONFIG.ENV_ID}`);
  const envData =
    process.env.REACT_APP_ISMOCK === true || process.env.REACT_APP_ISMOCK === 'true'
      ? ENV_CONFIG.LOCAL_AWS
      : await getEnvData();
  document.title = envData.tenant?.webpage?.webpagetitle;

  return {
    aws_appsync_region: 'eu-central-1', // todo need to get from backend
    aws_appsync_graphqlEndpoint: envData.tenant.graphql,
    // aws_appsync_public_graphqlEndpoint: envData.tenant.publicGraphql,
    aws_appsync_public_graphqlEndpoint: envData.tenant.graphqlPublic,
    // 'https://j22bzf3e5zcthgxzevhlkps5fy.appsync-api.eu-central-1.amazonaws.com/graphql',

    aws_appsync_public_graphqlEndpoint_apiKey: envData.tenant.graphqlPublicAPIKEY,
    aws_appsync_authenticationType: process.env.REACT_APP_AUTH,
    graphql_headers: async () => ({
      'Content-Language': i18n.language,
      Domain: window.location.hostname,
    }),
    // aws_user_files_s3_bucket: 'contentstore205558-devfour', // todo need to get from backend
    aws_user_files_s3_bucket_region: 'eu-central-1', // todo need to get from backend
    // aws_user_files_s3_dangerously_connect_to_http_endpoint_for_testing: true,
    aws_cognito_identity_pool_id: envData.tenant.identityPoolId,
    aws_cognito_region: 'eu-central-1',
    aws_user_pools_id: envData.tenant.userPoolId,
    aws_user_pools_web_client_id: envData.tenant.userPoolClientId,
    aws_appsync_apiKey: 'da2-fakeApiId123456',
    aws_project_region: 'eu-central-1',
    isMarketPlaceActive: envData.tenant.isMarketPlaceActive,
    isGovernanceActive: envData.tenant.isGovernanceActive,
    colorSchema: envData.tenant.colorSchema,
    requireAffiliate: envData.tenant.requireAffiliate,
    allowMMLogin: envData.tenant.allowMMLogin,
    pageHierarchy: envData.tenant.pageHierarchy,
    standardLanguage: envData.tenant.standardLanguage,
    supportedLanguages: envData.tenant.supportedLanguages,
    tenantId: envData.tenant.id,
    googleTagContainer: envData.tenant.googleTagContainer,
    googleAnalyticsMeasurementId: envData.tenant.googleAnalyticsMeasurementId,
    walletConnect: envData.tenant.walletConnect,
    webpage: {
      loginTitle: envData.tenant.loginTitle,
      loginWelcome: envData.tenant.loginWelcome,
      webpageTitle: envData.tenant.webpagetitle,
      copyright: envData.tenant.copyright,
    },
    // aws_cognito_authenticationFlowType: 'CUSTOM_AUTH',

    // Auth: {
    // authenticationFlowType: 'CUSTOM_AUTH',

    //   identityPoolId: envData.tenant.identityPoolId,
    //   region: 'eu-central-1', // todo need to get from backend
    //   userPoolId: envData.tenant.userPoolId,
    //   userPoolWebClientId: envData.tenant.userPoolClientId,
    // },
  };
};
