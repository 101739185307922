import { asyncActionType } from 'src/utils/reduxActions';

export const NAMESPACE = 'PUBLIC NEWS & EVENTS';

const FETCH_PUBLIC_OFFERING = asyncActionType(`${NAMESPACE} FETCH OFFERING WIDGET`);
const FETCH_PUBLIC_QUOTE = asyncActionType(`${NAMESPACE} FETCH OFFERING QUOTE WIDGET`);

const ORDER_PROCESS = 'PUBLIC ORDER PROCESS';

export const actionsTypes = {
  FETCH_PUBLIC_OFFERING,
  FETCH_PUBLIC_QUOTE,
  ORDER_PROCESS,
};
