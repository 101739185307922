export const onboarding = /* GraphQL */ `
  mutation Onboarding($accountId: ID!, $input: OnboardingInput) {
    onboarding(accountId: $accountId, input: $input) {
      tenantId
      id

      schema
      steps
      step
      data
    }
  }
`;

export const onboardingStatus = /* GraphQL */ `
  mutation OnboardingStatus(
    $accountId: ID!
    $offeringId: ID
    $amount: String
    $currency: Currency
  ) {
    onboardingStatus(
      accountId: $accountId
      offeringId: $offeringId
      amount: $amount
      currency: $currency
    ) {
      onboardingDone
      onboardingSteps
    }
  }
`;
