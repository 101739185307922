import { asyncActionType } from 'src/utils/reduxActions';

export const NAMESPACE = 'ONBOARDING';

const ONBOARDING_STATUS = asyncActionType(`${NAMESPACE} STATUS`);

const UPDATE_ONBOARDING = asyncActionType(`${NAMESPACE} UPDATE`);

const FETCH_TERMS = asyncActionType(`${NAMESPACE} TERMS`);

const SET_STEP = `${NAMESPACE} - STEP`;
const SET_ONBOARDNIG = `${NAMESPACE} ONBOARDING`;
const CLEAR_TERMS = `${NAMESPACE} CLEAR TEERMS`;

export const actionsTypes = {
  FETCH_TERMS,
  ONBOARDING_STATUS,
  UPDATE_ONBOARDING,
  SET_STEP,
  SET_ONBOARDNIG,
  CLEAR_TERMS,
};
