import { Map, fromJS } from 'immutable';
import { STATUS } from 'src/constants/Status';
import { actionsTypes } from './constants';

const initialState = fromJS({
  status: STATUS.LOADING,
  quote: {
    status: STATUS.SUCCESS,
  },
  orderProcess: {
    step: 0,
    data: {},
  },
});

const buyWidgetReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionsTypes.FETCH_PUBLIC_OFFERING.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.FETCH_PUBLIC_OFFERING.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('data', fromJS(payload.offering) || new Map());
    case actionsTypes.FETCH_PUBLIC_OFFERING.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.FETCH_PUBLIC_QUOTE.PENDING:
      return state.setIn(['quote', 'status'], STATUS.LOADING);
    case actionsTypes.FETCH_PUBLIC_QUOTE.SUCCESS:
      return state
        .setIn(['quote', 'status'], STATUS.SUCCESS)
        .setIn(['orderProcess', 'data'], payload.quote)
        .setIn(['orderProcess', 'data', 'selectedAmount'], payload.quote?.amount)
        .setIn(['orderProcess', 'data', 'selectedCurrency'], payload.quote?.currency)
        .setIn(['quote', 'data'], payload.quote);
    case actionsTypes.FETCH_PUBLIC_QUOTE.ERROR:
      return state.setIn(['quote', 'status'], STATUS.ERROR);

    case actionsTypes.ORDER_PROCESS:
      return state
        .setIn(['quote', 'data'], null)
        .setIn(['orderProcess', 'step'], payload.step)
        .setIn(['orderProcess', 'data'], payload.data);

    default:
      return state;
  }
};
export default buyWidgetReducer;
