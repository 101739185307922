import createSelector from 'src/utils/reselect';
import { Map } from 'immutable';

const Invest = state => state.get('store', new Map());

export const getStoreStatus = createSelector(Invest, data => data.get('status'));
export const getInvestStep = createSelector(Invest, data => data.get('step') || '1');

export const getOrderData = createSelector(Invest, data => data.get('order') || {});
export const getOrderId = createSelector(Invest, data => data.getIn(['order', 'transactionId']));
export const getActivityData = createSelector(Invest, data => data.get('activity') || {});

export const getOrderProcessStatus = createSelector(Invest, data =>
  data.getIn(['orderProcess', 'status']),
);

export const getMinimumInvestmentAmount = createSelector(Invest, data =>
  data.get('minimumInvestmentAmount'),
);

// Whitelisted

const getWhitelisted = createSelector(Invest, data => data.get('whitelisting') || {});
export const getWhitelistedData = createSelector(getWhitelisted, data => data.get('data') || []);
export const getWhitelistedStatus = createSelector(getWhitelisted, data => data.get('status'));

export const getBoardingSteps = createSelector(
  Invest,
  data => data.get('boardingSteps').toArray() || [],
);
export const getOnboardingProcess = createSelector(
  Invest,
  data => data.get('onBoardingProcess') || false,
);
export const getBoardingSelectedOffering = createSelector(Invest, data =>
  data.get('selectedOffering'),
);

export const getConfirmationModalStatus = createSelector(
  Invest,
  data => data.getIn(['confirmation', 'popup']) || false,
);

export const getConfirmationModalStep = createSelector(
  Invest,
  data => data.getIn(['confirmation', 'step']) || 1,
);

// buyToken
const buyToken = createSelector(Invest, data => data.get('buyToken') || {});
export const getBuyTokenStatus = createSelector(buyToken, data => data.get('status'));
export const getBuyTokenOrder = createSelector(buyToken, data => data.get('order'));
export const getBuyTokenPaymentData = createSelector(buyToken, data => data.get('paymentData'));
