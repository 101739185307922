import { fromJS } from 'immutable';
import { STATUS } from 'src/constants/Status';
import { actionsTypes, ACCOUNT_TYPES } from './constants';

const initialState = fromJS({
  status: STATUS.LOADING,
  step: 1,
  stepWalletAdd: 1,
  stepWalletTransfer: 1,
  walletTransaction: {},

  stepWalletList: 1,
  walletListing: {},

  accountType: ACCOUNT_TYPES.INDIVIDUAL,
  kycSignD: null,
  assets: [],
  transactions: [],
  gubbiKYC: {
    url: '',
  },
  createAccount: {
    status: null,
    showModal: false,
  },
  switchAccount: {
    status: null,
    showModal: false,
  },
  changePasswordStatus: null,
  myOrders: [],
  order: {
    showModal: false,
    details: {},
    selectedOrder: '',
    status: null,
  },
  transfer: {
    data: {},
    status: null,
  },
  listing: {
    data: {},
    status: null,
  },
  transfers: {
    data: [],
    status: null,
  },
  asset: {
    status: null,
    nextToken: null,
    assetNextToken: null,
    statistics: {},
  },
  orders: {
    status: null,
    nextToken: null,
    assetNextToken: null,
    data: [],
  },
  payments: {
    status: null,
    nextToken: null,
    data: [],
  },
  blockchainWallet: {},
});

const accountReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionsTypes.FETCH_ACCOUNT.PENDING:
      return state.set(
        'status',
        payload !== undefined && payload.silent ? STATUS.SUCCESS : STATUS.LOADING,
      );
    case actionsTypes.FETCH_ACCOUNT.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .set('account', payload.account || {})
        .set('users', payload.users || [])
        .set('blockchainWallets', payload.blockchainWallets || [])
        .set('myOrders', payload.orders || [])
        .set('assets', payload.assets || []);
    case actionsTypes.FETCH_ACCOUNT.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.FETCH_WALLET.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.FETCH_WALLET.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .set('blockchainWallet', payload.blockchainWallet || {});
    case actionsTypes.FETCH_WALLET.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.FETCH_ACCOUNT_PORTFOLIO.PENDING:
      return state.set('statusPortfolio', STATUS.LOADING);
    case actionsTypes.FETCH_ACCOUNT_PORTFOLIO.SUCCESS:
      // if (state.getIn(['asset', 'nextToken'])) {
      //   return state
      //     .set('statusPortfolio', STATUS.SUCCESS)
      //     .update('assets', value => value.concat(payload.assets))
      //     .setIn(['asset', 'nextToken'], payload.nextToken || null);
      // } else {
      return state
        .set('statusPortfolio', STATUS.SUCCESS)
        .set('assets', payload.assets)
        .setIn(['orders', 'data'], payload.orders)
        .setIn(['payments', 'data'], payload.payments)
        .set('transactions', payload.transactions)
        .setIn(['asset', 'statistics'], payload.statistics || null)
        .setIn(['asset', 'nextToken'], payload.nextToken || null);
    // }
    case actionsTypes.FETCH_ACCOUNT_PORTFOLIO.ERROR:
      return state.set('statusPortfolio', STATUS.ERROR);

    case actionsTypes.FETCH_ACCOUNT_ASSET.PENDING:
      return state.setIn(['asset', 'status'], STATUS.LOADING);
    case actionsTypes.FETCH_ACCOUNT_ASSET.SUCCESS:
      return state
        .setIn(['asset', 'status'], STATUS.SUCCESS)
        .setIn(['asset', 'assetNextToken'], payload.nextToken || null)
        .setIn(['asset', 'data'], payload.asset || {});
    case actionsTypes.FETCH_ACCOUNT_ASSET.ERROR:
      return state.setIn(['asset', 'status'], STATUS.ERROR);

    case actionsTypes.FETCH_ACCOUNT_TRANSFERS.PENDING:
      return state.setIn(['transfers', 'status'], STATUS.LOADING);
    case actionsTypes.FETCH_ACCOUNT_TRANSFERS.SUCCESS:
      return state
        .setIn(['transfers', 'status'], STATUS.SUCCESS)
        .setIn(['transfers', 'nextToken'], payload.nextToken)
        .setIn(['transfers', 'data'], payload.transfers || []);
    case actionsTypes.FETCH_ACCOUNT_TRANSFERS.ERROR:
      return state.setIn(['transfers', 'status'], STATUS.ERROR);

    case actionsTypes.FETCH_ACCOUNT_TRANSFER.PENDING:
      return state.setIn(['transfer', 'status'], STATUS.LOADING);
    case actionsTypes.FETCH_ACCOUNT_TRANSFER.SUCCESS:
      return state
        .setIn(['transfer', 'data'], payload.transfer || {})
        .setIn(['transfer', 'nextToken'], payload.nextToken)
        .setIn(['transfer', 'status'], STATUS.SUCCESS);
    case actionsTypes.FETCH_ACCOUNT_TRANSFER.ERROR:
      return state.setIn(['transfer', 'status'], STATUS.ERROR);

    // Payments
    case actionsTypes.FETCH_ACCOUNT_PAYMENTS.PENDING:
      return state.setIn(['payments', 'status'], STATUS.LOADING);
    case actionsTypes.FETCH_ACCOUNT_PAYMENTS.SUCCESS:
      return state
        .setIn(['payments', 'status'], STATUS.SUCCESS)
        .setIn(['payments', 'nextToken'], payload.nextToken)
        .setIn(['payments', 'data'], payload.data || []);
    case actionsTypes.FETCH_ACCOUNT_PAYMENTS.ERROR:
      return state.setIn(['payments', 'status'], STATUS.ERROR);

    case actionsTypes.FETCH_ACCOUNT_PAYMENT.PENDING:
      return state.setIn(['payments', 'status'], STATUS.LOADING);
    case actionsTypes.FETCH_ACCOUNT_PAYMENT.SUCCESS:
      return state
        .setIn(['payments', 'payment'], payload.payment || {})
        .setIn(['payments', 'status'], STATUS.SUCCESS);
    case actionsTypes.FETCH_ACCOUNT_PAYMENT.ERROR:
      return state.setIn(['payments', 'status'], STATUS.ERROR);

    case actionsTypes.CLEAR_ACCOUNT_PAYMENT_VALUES:
      return state
        .setIn(['payments', 'data'], [])
        .setIn(['payments', 'payment'], null)
        .setIn(['payments', 'nextToken'], null);

    // Open Orders
    case actionsTypes.FETCH_ACCOUNT_OPENORDERS.PENDING:
      return state.set('statusPortfolio', STATUS.LOADING);
    case actionsTypes.FETCH_ACCOUNT_OPENORDERS.SUCCESS:
      return state
        .set('statusPortfolio', STATUS.SUCCESS)
        .set('openOrders', payload.openOrders || {});
    case actionsTypes.FETCH_ACCOUNT_OPENORDERS.ERROR:
      return state.set('statusPortfolio', STATUS.ERROR);

    case actionsTypes.FETCH_ACCOUNT_ORDERS.PENDING:
      return state.set('statusOrders', STATUS.LOADING);
    case actionsTypes.FETCH_ACCOUNT_ORDERS.SUCCESS:
      return state
        .set('statusOrders', STATUS.SUCCESS)
        .setIn(['orders', 'data'], payload.orders || {});
    case actionsTypes.FETCH_ACCOUNT_ORDERS.ERROR:
      return state.set('statusOrders', STATUS.ERROR);

    case actionsTypes.GET_SIGND_DETAILS.PENDING:
      return state.set('status', STATUS.LOADING).set('kycSignD', null);
    case actionsTypes.GET_SIGND_DETAILS.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('kycSignD', payload.credentials);
    case actionsTypes.GET_SIGND_DETAILS.ERROR:
      return state.set('status', STATUS.ERROR).set('kycSignD', null);

    case actionsTypes.FETCH_GUBBI_KYC.PENDING:
      return state.setIn(['gubbiKYC', 'status'], STATUS.LOADING).setIn(['gubbiKYC', 'url'], null);
    case actionsTypes.FETCH_GUBBI_KYC.SUCCESS:
      return state
        .setIn(['gubbiKYC', 'status'], STATUS.SUCCESS)
        .setIn(['gubbiKYC', 'url'], payload.url)
        .set('account', payload.account || {});
    case actionsTypes.FETCH_GUBBI_KYC.ERROR:
      return state.setIn(['gubbiKYC', 'status'], STATUS.ERROR).setIn(['gubbiKYC', 'url'], null);

    case actionsTypes.FETCH_SUMSUB_KYC.PENDING:
      return state.set('status', STATUS.LOADING).set('kycSumsub', null);
    case actionsTypes.FETCH_SUMSUB_KYC.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .set('kycSumsub', payload.credentials)
        .set('account', payload.account || {});

    case actionsTypes.FETCH_SUMSUB_KYC.ERROR:
      return state.set('status', STATUS.ERROR).set('kycSumsub', null);

    case actionsTypes.CONFIRM_SIGND.PENDING:
      return state.set('status', STATUS.LOADING).set('kycSignD', null);
    case actionsTypes.CONFIRM_SIGND.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('kycSignD', payload.credentials);
    case actionsTypes.CONFIRM_SIGND.ERROR:
      return state.set('status', STATUS.ERROR).set('kycSignD', null);

    case actionsTypes.SWITCH_ACCOUNT_TYPE:
      return state.set('accountType', action.accountType);

    case actionsTypes.ADD_ACCOUNT.PENDING:
      return state.setIn(['createAccount', 'status'], STATUS.LOADING);
    case actionsTypes.ADD_ACCOUNT.SUCCESS:
      return state
        .setIn(['createAccount', 'status'], STATUS.SUCCESS)
        .set('account', payload.data || {});
    case actionsTypes.ADD_ACCOUNT.ERROR:
      return state.setIn(['createAccount', 'status'], STATUS.ERROR);

    case actionsTypes.TRANSFER_TOKEN_FROM_BLOCKCHAINWALLET.PENDING:
      return state.setIn(['transfer', 'status'], STATUS.ADDING);
    case actionsTypes.TRANSFER_TOKEN_FROM_BLOCKCHAINWALLET.SUCCESS:
      return state
        .setIn(['transfer', 'status'], STATUS.SUCCESS)
        .setIn(['transfer', 'data'], payload.data || {});
    case actionsTypes.TRANSFER_TOKEN_FROM_BLOCKCHAINWALLET.ERROR:
      return state.setIn(['transfer', 'status'], STATUS.ERROR);

    case actionsTypes.LIST_TOKENONMARKET.PENDING:
      return state.setIn(['listing', 'status'], STATUS.ADDING);
    case actionsTypes.LIST_TOKENONMARKET.SUCCESS:
      return state
        .setIn(['listing', 'status'], STATUS.SUCCESS)
        .setIn(['transfer', 'data'], payload.data || {});
    case actionsTypes.LIST_TOKENONMARKET.ERROR:
      return state.setIn(['listing', 'status'], STATUS.ERROR);

    case actionsTypes.CREATE_ACCOUNT_MODAL: {
      const previousStatus = state.getIn(['createAccount', 'showModal']);
      return state.setIn(['createAccount', 'showModal'], !previousStatus);
    }

    case actionsTypes.SWITCH_ACCOUNT_MODAL: {
      const previousStatus = state.getIn(['switchAccount', 'showModal']);
      return state.setIn(['switchAccount', 'showModal'], !previousStatus);
    }

    case actionsTypes.SET_ACCOUNT_TYPE.PENDING:
      return state.set('statusUpdate', STATUS.LOADING);
    case actionsTypes.SET_ACCOUNT_TYPE.SUCCESS:
      return state.set('statusUpdate', STATUS.SUCCESS).set('account', payload.account || {});
    case actionsTypes.SET_ACCOUNT_TYPE.ERROR:
      return state.set('statusUpdate', STATUS.ERROR);

    case actionsTypes.UPDATE_ACCOUNT.PENDING:
      return state.set('statusUpdate', STATUS.LOADING);
    case actionsTypes.UPDATE_ACCOUNT.SUCCESS:
      return state.set('statusUpdate', STATUS.SUCCESS).set('account', payload.account || {});
    case actionsTypes.UPDATE_ACCOUNT.ERROR:
      return state.set('statusUpdate', STATUS.ERROR);

    case actionsTypes.UPDATE_ACCOUNT_PAPERWALLET.PENDING:
      return state.set('statusUpdate', STATUS.UPDATING);
    case actionsTypes.UPDATE_ACCOUNT_PAPERWALLET.SUCCESS:
      return state.set('statusUpdate', STATUS.SUCCESS).set('account', payload.account || {});
    case actionsTypes.UPDATE_ACCOUNT_PAPERWALLET.ERROR:
      return state.set('statusUpdate', STATUS.ERROR);

    case actionsTypes.UPDATE_AML.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.UPDATE_AML.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('account', payload.account || {});
    case actionsTypes.UPDATE_AML.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.UPDATE_ACCOUNT_EMAIL.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.UPDATE_ACCOUNT_EMAIL.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('account', payload.account || {});
    case actionsTypes.UPDATE_ACCOUNT_EMAIL.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.CONFIRM_UPDATE_EMAIL_ACCOUNT.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.CONFIRM_UPDATE_EMAIL_ACCOUNT.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('account', payload.account || {});
    case actionsTypes.CONFIRM_UPDATE_EMAIL_ACCOUNT.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.CONFIRM_UPDATE_EMAIL_PIN_ACCOUNT.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.CONFIRM_UPDATE_EMAIL_PIN_ACCOUNT.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('account', payload.account || {});
    case actionsTypes.CONFIRM_UPDATE_EMAIL_PIN_ACCOUNT.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.UPDATE_ACCOUNT_MOBILE.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.UPDATE_ACCOUNT_MOBILE.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('account', payload.account || {});
    case actionsTypes.UPDATE_ACCOUNT_MOBILE.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.CONFIRM_UPDATE_MOBILE_ACCOUNT.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.CONFIRM_UPDATE_MOBILE_ACCOUNT.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('account', payload.account || {});
    case actionsTypes.CONFIRM_UPDATE_MOBILE_ACCOUNT.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.SET_MAINBLOCKCHAINWALLET.PENDING:
      return state.set('status', STATUS.UPDATING);
    case actionsTypes.SET_MAINBLOCKCHAINWALLET.SUCCESS:
      return state.set('status', STATUS.SUCCESS);
    case actionsTypes.SET_MAINBLOCKCHAINWALLET.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.CONFIRM_LINK_PAYMENT_ACCOUNT.PENDING:
      return state.set('status', STATUS.LOADING);
    case actionsTypes.CONFIRM_LINK_PAYMENT_ACCOUNT.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('account', payload.account || {});
    case actionsTypes.CONFIRM_LINK_PAYMENT_ACCOUNT.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.UPDATE_INVESTOR_TYPE.PENDING:
      return state.set('status', STATUS.UPDATING);
    case actionsTypes.UPDATE_INVESTOR_TYPE.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('account', payload.account || {});
    case actionsTypes.UPDATE_INVESTOR_TYPE.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.UPDATE_INVESTMENTOBJECTIVES.PENDING:
      return state.set('status', STATUS.UPDATING);
    case actionsTypes.UPDATE_INVESTMENTOBJECTIVES.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('account', payload.account || {});
    case actionsTypes.UPDATE_INVESTMENTOBJECTIVES.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.UPDATE_FINANCIALSITUATION.PENDING:
      return state.set('status', STATUS.UPDATING);
    case actionsTypes.UPDATE_FINANCIALSITUATION.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('account', payload.account || {});
    case actionsTypes.UPDATE_FINANCIALSITUATION.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.UPDATE_KNOWLEDGE.PENDING:
      return state.set('status', STATUS.UPDATING);
    case actionsTypes.UPDATE_KNOWLEDGE.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('account', payload.account || {});
    case actionsTypes.UPDATE_KNOWLEDGE.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.UPDATE_REFERENCEBANK.PENDING:
      return state.set('status', STATUS.UPDATING);
    case actionsTypes.UPDATE_REFERENCEBANK.SUCCESS:
      return state.set('status', STATUS.SUCCESS).set('account', payload.account || {});
    case actionsTypes.UPDATE_REFERENCEBANK.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.ADD_BLOCKCHAINWALLET.PENDING:
      return state.set('status', STATUS.ADDING);
    case actionsTypes.ADD_BLOCKCHAINWALLET.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .set('blockchainWallet', payload.blockchainWallet || {});
    case actionsTypes.ADD_BLOCKCHAINWALLET.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.CHECKSIGNATURE_BLOCKCHAINWALLET.PENDING:
      return state.set('status', STATUS.UPDATING);
    case actionsTypes.CHECKSIGNATURE_BLOCKCHAINWALLET.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .set('account', payload.account || {})
        .set('blockchainWallet', payload.blockchainWallet || {});
    case actionsTypes.CHECKSIGNATURE_BLOCKCHAINWALLET.ERROR:
      return state.set('status', STATUS.ERROR);

    case actionsTypes.SET_STEP:
      return state.set('step', payload.step);

    case actionsTypes.SET_WALLETADD_STEP:
      return state.set('stepWalletAdd', payload);

    case actionsTypes.SET_WALLETTRANSFER_STEP:
      return state.set('stepWalletTransfer', payload);

    case actionsTypes.SET_WALLETTRANSFER_TRANSACTION:
      return state.set('walletTransaction', payload);

    case actionsTypes.CLEAR_VALUES:
      return initialState;

    case actionsTypes.CLEAR_TRANSACTIONS:
      return state.set('transactions', []);

    case actionsTypes.CHANGE_PROFILE_PASSWORD.PENDING:
      return state.set('changePasswordStatus', STATUS.UPDATING);
    case actionsTypes.CHANGE_PROFILE_PASSWORD.SUCCESS:
      return state.set('changePasswordStatus', STATUS.SUCCESS);
    case actionsTypes.CHANGE_PROFILE_PASSWORD.ERROR:
      return state.set('changePasswordStatus', STATUS.ERROR);
    case actionsTypes.TOGGLE_ORDER_DETAILS_MODAL: {
      const previousStatus = state.getIn(['order', 'showModal']);
      return state.setIn(['order', 'showModal'], !previousStatus);
    }
    case actionsTypes.SET_SELECTED_ORDER:
      return state.setIn(['order', 'selectedOrder'], payload.id);

    case actionsTypes.FETCH_ORDER_DETAILS.PENDING:
      return state.setIn(['order', 'status'], STATUS.LOADING);
    case actionsTypes.FETCH_ORDER_DETAILS.SUCCESS:
      return state
        .setIn(['order', 'status'], STATUS.SUCCESS)
        .setIn(['order', 'details'], payload.details || {})
        .setIn(['order', 'status'], STATUS.SUCCESS);
    case actionsTypes.FETCH_ORDER_DETAILS.ERROR:
      return state.setIn(['order', 'status'], STATUS.ERROR);

    case actionsTypes.SET_WALLETLIST_STEP:
      return state.set('stepWalletList', payload);
    case actionsTypes.SET_WALLETLIST_TRANSACTION:
      return state.set('walletListing', payload);
    default:
      return state;
  }
};
export default accountReducer;
