export const readAffiliate = /* GraphQL */ `
  query Portal($accountId: ModelIDKeyConditionInput) {
    myPortal {
      tenantId
      id
      accounts(accountId: $accountId) {
        items {
          status
          accountId
          account {
            name
            id
            affiliate {
              referralLink
              referralId
            }

            address {
              street
              postalCode
              city
              state
              country
            }
          }
        }
      }
    }
  }
`;

export const readAffiliateOrders = /* GraphQL */ `
  query Portal($accountId: ModelIDKeyConditionInput, $nextToken: String) {
    myPortal {
      tenantId
      id
      accounts(accountId: $accountId) {
        items {
          status
          accountId
          account {
            name
            id
            affiliate {
              tenantId
              id

              orders(nextToken: $nextToken) {
                items {
                  orderData {
                    name
                    offeringName
                    amountInCurrency
                    currency
                  }
                  amount
                  status
                  level
                  transactionDate
                }
                nextToken
              }
            }
          }
        }
      }
    }
  }
`;

export const readAffiliateSignups = /* GraphQL */ `
  query Portal($accountId: ModelIDKeyConditionInput, $nextToken: String) {
    myPortal {
      tenantId
      id
      accounts(accountId: $accountId) {
        items {
          status
          accountId
          account {
            name
            id
            affiliate {
              tenantId
              id

              signups(nextToken: $nextToken) {
                items {
                  name
                  email
                  status
                  kycStatus
                }
                nextToken
              }
            }
          }
        }
      }
    }
  }
`;

export const readAffiliatedAccount = /* GraphQL */ `
  query Portal($accountId: ModelIDKeyConditionInput, $partnerAccountId: ID) {
    myPortal {
      tenantId
      id
      accounts(accountId: $accountId) {
        items {
          status
          accountId
          account {
            name
            id
            affiliate {
              tenantId
              id

              partner(accountId: $partnerAccountId) {
                name
              }
            }
          }
        }
      }
    }
  }
`;
