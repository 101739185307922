import { actionsTypes } from './constants';

export const fetchOrderActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_ORDER.PENDING,
    payload,
  }),
  success: ({ order, activity }) => ({
    type: actionsTypes.FETCH_ORDER.SUCCESS,
    payload: { order, activity },
  }),
  error: () => ({
    type: actionsTypes.FETCH_ORDER.ERROR,
  }),
};

export const fetchWhitelistedActions = {
  pending: payload => ({
    type: actionsTypes.FETCH_WHITELISTED.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.FETCH_WHITELISTED.SUCCESS,
    payload: { data },
  }),
  error: () => ({
    type: actionsTypes.FETCH_WHITELISTED.ERROR,
  }),
};

export const whitelistingActions = {
  pending: payload => ({
    type: actionsTypes.WHITELISTING.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.WHITELISTING.SUCCESS,
    payload: { data },
  }),
  error: () => ({
    type: actionsTypes.WHITELISTING.ERROR,
  }),
};

export const addOrderActions = {
  pending: payload => ({
    type: actionsTypes.ADD_ORDER.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.ADD_ORDER.SUCCESS,
    payload: { order: data },
  }),
  error: () => ({
    type: actionsTypes.ADD_ORDER.ERROR,
  }),
};

export const updateOrderActions = {
  pending: payload => ({
    type: actionsTypes.UPDATE_ORDER.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.UPDATE_ORDER.SUCCESS,
    payload: { order: data },
  }),
  error: () => ({
    type: actionsTypes.UPDATE_ORDER.ERROR,
  }),
};

export const confirmOrderActions = {
  pending: payload => ({
    type: actionsTypes.CONFIRM_ORDER.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.CONFIRM_ORDER.SUCCESS,
    payload: { order: data },
  }),
  error: () => ({
    type: actionsTypes.CONFIRM_ORDER.ERROR,
  }),
};
export const cancelOrderActions = {
  pending: (payload, callback) => ({
    type: actionsTypes.CANCEL_ORDER.PENDING,
    payload,
    callback,
  }),
  success: ({ data }) => ({
    type: actionsTypes.CANCEL_ORDER.SUCCESS,
    payload: { order: data },
  }),
  error: () => ({
    type: actionsTypes.CANCEL_ORDER.ERROR,
  }),
};

export const updateOnboardingActions = {
  pending: (payload, callback) => ({
    type: actionsTypes.UPDATE_ONBOARDING.PENDING,
    payload,
    callback,
  }),
  success: ({ data }) => ({
    type: actionsTypes.UPDATE_ONBOARDING.SUCCESS,
    payload: { order: data },
  }),
  error: () => ({
    type: actionsTypes.UPDATE_ONBOARDING.ERROR,
  }),
};

export const buyTokenAction = {
  pending: payload => ({
    type: actionsTypes.BUY_TOKEN.PENDING,
    payload,
  }),
  success: ({ data, paymentData }) => ({
    type: actionsTypes.BUY_TOKEN.SUCCESS,
    payload: { order: data, paymentData },
  }),
  error: () => ({
    type: actionsTypes.BUY_TOKEN.ERROR,
  }),
};

export const buyTokenConfirmAction = {
  pending: payload => ({
    type: actionsTypes.BUY_TOKEN_CONFIRM.PENDING,
    payload,
  }),
  success: ({ data }) => ({
    type: actionsTypes.BUY_TOKEN_CONFIRM.SUCCESS,
    payload: { order: data },
  }),
  error: () => ({
    type: actionsTypes.BUY_TOKEN_CONFIRM.ERROR,
  }),
};

export const verifyBoardingStepActions = () => ({
  type: actionsTypes.VERIFYBOARDING,
});

export const setBuyTokenPaymentData = paymentData => ({
  type: actionsTypes.BUY_TOKEN_SET_PAYMENTDATA,
  payload: { paymentData },
});
export const setInvestStepAction = step => ({ type: actionsTypes.SET_STEP, payload: { step } });
export const setOnboarding = onBoardingProcess => ({
  type: actionsTypes.SET_ONBOARDNIG,
  payload: { onBoardingProcess },
});

export const resetValues = () => ({ type: actionsTypes.CLEAR_VALUES });
export const resetBuyToken = () => ({ type: actionsTypes.BUY_TOKEN_RESET });

export const setMinimumInvestment = amount => ({
  type: actionsTypes.SET_MIN_INVEST_AMOUNT,
  payload: { amount },
});

export const setBoardingSteps = boardingSteps => ({
  type: actionsTypes.SET_ONBOARDING_STEPS,
  payload: { boardingSteps },
});

export const setSelectedOffering = selectedOffering => ({
  type: actionsTypes.SET_SELECTED_OFFERING,
  payload: { selectedOffering },
});

export const changeConfirmationModalStatus = status => ({
  type: actionsTypes.CONFIRMATION_MODAL,
  payload: { status },
});
export const changeConfirmationModalStep = step => ({
  type: actionsTypes.CONFIRMATION_MODAL_STEP,
  payload: { step },
});
