export const getMarketOffers = /* GraphQL */ `
  query Portal(
    $id: ModelIDKeyConditionInput
    $filter: ModelMarketOfferFilterInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
  ) {
    myPortal {
      tenantId
      marketOffers(
        id: $id
        filter: $filter
        sortDirection: $sortDirection
        limit: $limit
        nextToken: $nextToken
      ) {
        nextToken
        items {
          tenantId
          id
          name
          description
          overview
          heroImage
          overviewImage

          price
          priceSymbol
          priceDecimals

          offering {
            name
            visualType
          }
        }
      }
    }
  }
`;

export const getMarketOffer = /* GraphQL */ `
  query Portal($id: ID!) {
    myPortal {
      tenantId
      marketOffer(id: $id) {
        id
        name
        description
        overview
        heroImage
        overviewImage
        tokenId
        externalLink

        price
        priceSymbol
        priceAddress
        priceDecimals

        offering {
          visualType
          smartContractTemplate
          publicAddress
          chain
          name
          market {
            publicAddress
            smartContractABI
            smartContractTemplate
            chain
          }
        }
      }
    }
  }
`;

export const readAccountTransfers = /* GraphQL */ `
  query Portal(
    $accountId: ModelIDKeyConditionInput
    $filter: TransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    myPortal {
      id
      tenantId
      accounts(accountId: $accountId) {
        items {
          status
          accountId
          account {
            id

            transactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
              items {
                tenantId
                transactionId
                accountId
                offeringId
                blockchainAccountWalletId
                blockchainAccountWallet {
                  publicAddress
                }
                amount
                status
                message
                contract
                transactionData {
                  txTo
                  txValue
                  txHash
                }

                offering {
                  name
                  symbol
                  chain
                  decimals
                  publicAddress
                  fundingEndDate
                  fundingStartDate
                  fundingSupplyCap
                  decimals
                }
              }
              nextToken
            }
          }
        }
      }
    }
  }
`;
