import { combineReducers } from 'redux-immutable';
import appSettingsReducer from 'src/components/AppSettings/appSettings.reducer';
import accountReducer from 'src/views/Account/account.reducer';
import NewsAndEventsReducer from 'src/views/NewsAndEvent/news.reducer';
import OfferingReducer from 'src/views/Offering/offering.reducer';
import ActionsReducer from 'src/views/Actions/actions.reducer';
import AffiliateReducer from 'src/views/Affiliate/affiliate.reducer';
import ReferralsReducer from 'src/views/Referrals/referrals.reducer';
import notifications from 'src/components/shared/Notification/reducer';
import StoreReducer from 'src/views/Store/store.reducer';
import ReportsReducer from 'src/views/Reports/reports.reducer';
import DashboardReducer from 'src/views/Dashboard/persistance/dashboard.reducer';
import PageReducer from 'src/views/Pages/page.reducer';
import GiveawayReducer from 'src/views/Giveaways/giveaway.reducer';
import OnboardingReducer from 'src/views/Onboarding/onboarding.reducer';
import { actionsTypes } from 'src/components/AppSettings/constants';

import PublicGiveaways from 'src/views/Public/Giveaways/giveaway.reducer';
import PublicNewsAndEvents from 'src/views/Public/NewsAndEvent/news.reducer';
import PublicOfferings from 'src/views/Public/Offerings/offerings.reducer';

import MarketplaceReducer from 'src/views/Marketplace/persistance/marketplace.reducer';
import PostboxReducer from 'src/views/Postbox/postbox.reducer';
import CommunicationReducer from 'src/views/Communication/communication.reducer';

const createRootReducer = routerReducer => {
  const publicReducer = combineReducers({
    giveaways: PublicGiveaways,
    newsAndEvents: PublicNewsAndEvents,
    offerings: PublicOfferings,
  });

  const appReducer = combineReducers({
    dashboard: DashboardReducer,
    appSettings: appSettingsReducer,
    newsAndEvents: NewsAndEventsReducer,
    actions: ActionsReducer,
    reports: ReportsReducer,
    offering: OfferingReducer,
    account: accountReducer,
    affiliate: AffiliateReducer,
    referrals: ReferralsReducer,
    store: StoreReducer,
    notifications,
    pages: PageReducer,
    marketplace: MarketplaceReducer,
    giveaways: GiveawayReducer,
    onboarding: OnboardingReducer,
    public: publicReducer,
    postbox: PostboxReducer,
    communication: CommunicationReducer,
    router: routerReducer,
  });

  return (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === actionsTypes.RESET_APP_SETTING) {
      state = undefined;
    }

    return appReducer(state, action);
  };
};

export default createRootReducer;
