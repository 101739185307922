import { fromJS, List } from 'immutable';
import { STATUS } from 'src/constants/Status';
import { actionsTypes } from './constants';

const initialState = fromJS({
  status: STATUS.LOADING,
  actions: [],
  featuredActivities: [],
  featured: {
    type: '',
    details: null,
  },
  offerings: [],
  activities: [],
});

const dashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionsTypes.FETCH_DASHBOARD.PENDING:
      if (state.get('status') !== STATUS.SUCCESS) {
        return initialState.set('status', STATUS.LOADING);
      } else {
        return state;
      }
    case actionsTypes.FETCH_DASHBOARD.SUCCESS:
      return state
        .set('status', STATUS.SUCCESS)
        .set('actions', fromJS(payload.actions) || new List())
        .set('offerings', fromJS(payload.offerings) || new List())
        .set('featuredActivities', fromJS(payload.featuredActivities) || new List())
        .setIn(['featured', 'details'], fromJS(payload.featuredDetails) || new Map())
        .setIn(['featured', 'type'], payload.type)
        .set('activities', fromJS(payload.activities) || new List());
    case actionsTypes.FETCH_DASHBOARD.ERROR:
      return state.set('status', STATUS.ERROR);

    default:
      return state;
  }
};
export default dashboardReducer;
