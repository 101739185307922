import { asyncActionType } from 'src/utils/reduxActions';

export const NAMESPACE = 'POSTBOX';

const FETCH_MESSAGES = asyncActionType(`${NAMESPACE} FETCH ALL`);
const FETCH_MESSAGE = asyncActionType(`${NAMESPACE} FETCH DETAIL`);
const SEND_MESSAGE = asyncActionType(`${NAMESPACE} SEND MESSAGE`);
const CLEAR_VALUES = `${NAMESPACE} CLEAR_VALUES`;

export const actionsTypes = {
  FETCH_MESSAGES,
  FETCH_MESSAGE,
  SEND_MESSAGE,
  CLEAR_VALUES,
};
