import { all, call, put, takeLatest } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
import { actionsTypes } from '../persistance/constants';
import { fetchDashboardDataActions } from '../persistance/dashboard.action';
import { getDashboardAllDetails } from './GraphQL/queries';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* fetchDashboardDataSaga() {
  try {
    const params = {
      accountId: {
        eq: '',
      },
    };
    const response = yield call([API, 'graphql'], graphqlOperation(getDashboardAllDetails, params));
    const actions = response.data.dashboard.actions.slice(0, 6);
    const allOfferings = response.data.dashboard.offerings;
    const activities = response.data.dashboard.activities;
    let featuredDetails = null;
    let offerings = [];
    let type = '';
    let featuredActivities = [];
    if (allOfferings.length) {
      featuredDetails = allOfferings.shift();
      offerings = allOfferings.splice(0, 3);
      type = 'OFFERING';
    } else if (activities.length) {
      featuredDetails = activities.shift();
      type = 'EVENT';
    }
    if (activities.length) {
      featuredActivities = activities.splice(0, 3);
    }

    yield put(
      fetchDashboardDataActions.success({
        type,
        actions,
        offerings,
        activities,
        featuredDetails,
        featuredActivities,
      }),
    );
  } catch (error) {
    console.error(error); // eslint-disable-line
    if (error.errors[0].message === 'Error: Identity not created') {
      yield call(delay, 1000);
      yield put(fetchDashboardDataActions.pending());
    } else {
      yield put(fetchDashboardDataActions.error());
    }
  }
}

export default function* newsAndEventsSaga() {
  yield all([takeLatest(actionsTypes.FETCH_DASHBOARD.PENDING, fetchDashboardDataSaga)]);
}
