import { applyMiddleware, createStore } from 'redux';

import { Map } from 'immutable';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { routerMiddleware } from 'connected-react-router/immutable';
import createRootReducer from './combineReducers';
import rootSaga from './rootSaga';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
  selectRouterState: state => state.get('router'),
  //other options if needed
});

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsBlacklist, actionsCreators and other options
});

const initialState = new Map();
export const store = createStore(
  createRootReducer(routerReducer),
  initialState,
  composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware)),
  // composeEnhancers(applyMiddleware(sagaMiddleware), applyMiddleware(routerMiddleware)),
);

sagaMiddleware.run(rootSaga);

export const history = createReduxHistory(store);
