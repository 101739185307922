export const portal = /* GraphQL */ `
  query Portal {
    myPortal {
      tenantId
      id
      individual {
        firstName
        lastName
      }
      accounts {
        items {
          status
          accountId
          account {
            name
            id
            blockchainWallets {
              items {
                id
                name
              }
            }
          }
        }
      }
      tenant {
        id
        name
        description
        offerings {
          items {
            id
            name
            symbol
          }
        }
      }
      offerings {
        items {
          id
          name
          symbol
        }
      }
      activities {
        items {
          id
          title
          description
          content
        }
      }
    }
  }
`;

export const readAccount = /* GraphQL */ `
  query Portal($accountId: ModelIDKeyConditionInput, $id: ModelIDKeyConditionInput) {
    myPortal {
      tenantId
      id
      individual {
        firstName
        lastName
      }
      offerings(id: $id) {
        items {
          id
          name
          chain
          noKYC
        }
      }
      accounts(accountId: $accountId) {
        items {
          status
          accountId
          account {
            name
            id
            status
            accountType
            affiliate {
              referralLink
              referralId
            }

            kycStatus
            individualCompanyStatus
            individualCompanyMessage

            investorProfileStatus
            ecspStatus

            createPaperWallet
            mainBlockchainWalletId
            mainBlockchainWallet {
              publicAddress
              blockchainProvider
              blockchainStatus
            }

            email
            emailStatus

            mobile
            mobileStatus

            confirmedTerms1
            confirmedTerms2
            confirmedTerms3
            confirmedTerms4
            confirmedTerms5

            isReferral
            isAffiliate
            hasReferral

            message

            individual {
              gender
              firstName
              lastName
              dateOfBirth
              nationality

              education
              occupation
              tin

              maritalStatus
              numberOfChildren
              identificationDocument
              identificationNumber
              identificationAuthority
              profession
              employer
              industry
              planedNumberOfInvestments

              grossIncome
              financialInstrumentPortfolio
              professionalPositionFinancialSector
              executivePosition
              capitalMarketExperience
              declaredAwarenessOfLossOfInvestorProtection
              confirmedLiabilityForAccuracyOfInformation
              sophisticatedInvestor
            }
            company {
              name
              identificationNumber
              vatNumber
              description
              websiteUrl
            }
            address {
              street
              postalCode
              city
              state
              country
            }

            amlStatus
            amlMessage
            aml {
              sourceOfFunds
              sourceOfFundsOthers
              sourceOfWealth
              sourceOfWealthOthers
              investmentReason

              pep
              pepRelatedPerson
              pepStatusDescription
            }

            investmentObjectivesStatus
            investmentObjectivesMessage
            investmentObjectives {
              riskTolerance
              timeHorizon
              investmentReason
            }

            financialSituationStatus
            financialSituationMessage
            financialSituation {
              monthlyNetIncome
              monthlyExpenses
              liquidAssets
              illiquidAssets
              liabilities
              financialReserves

              disclaimerFinancialReserve
              disclaimerLongterm
              disclaimerFlexibility
              disclaimerLifeStandard
              disclaimerFluctuations
            }

            knowledgeAndExperienceStatus
            knowledgeAndExperienceMessage
            knowledgeAndExperience {
              knowHow1
              knowHow2
              knowHow3
              knowHow4
              knowHow5
            }

            referenceBankAccountStatus
            referenceBankAccountMessage
            referenceBankAccount {
              iban
              bic
              owner
              bankName
            }

            connectedAccounts {
              items {
                id
                name
                status
                publicAddress
                blockchainProvider
              }
            }
            tenant {
              id

              allowReferAFriend
              showReferAFriend
              allowAffiliate
              showAffiliate

              requireInvestorType
              requireECSPProfile
              requireInvestmentObjective
              requireFinancialSituation
              requireKnowledge
              requireReferenceBank
              requireEmail
              requireMobile
              requireFullRegistration

              requireVAT
              requireIdentificationNr
              requireWebsite
              requireTIN
              requireOccupation
              requireEducation

              requireWallet
              requireAML

              onboardingWelcome
              autoWalletMessage

              kycProvider
              walletProvider
              requireTerms1
              requireTerms2
              requireTerms3
              requireTerms4
              requireTerms5
              terms {
                key
                title
                description
                content
              }
            }
          }
        }
      }
    }
  }
`;

export const readAccountPortfolio = /* GraphQL */ `
  query Portal(
    $accountId: ModelIDKeyConditionInput
    $filter: TransactionFilterInput
    $nextToken: String
  ) {
    myPortal {
      id
      tenantId
      accounts(accountId: $accountId) {
        items {
          status
          accountId
          account {
            id

            transactions(filter: $filter, nextToken: $nextToken) {
              items {
                tenantId
                transactionId
                accountId
                offeringId
                tokenId
                transactionDate
                blockchainAccountWalletId
                blockchainAccountWallet {
                  id
                  name
                  publicAddress
                  blockchainProvider
                }

                nftData {
                  name
                  description
                  externalLink
                  imagePath
                }

                amount
                status
                orderData {
                  pricePerToken
                  amountInCurrency
                  currency
                  contractUrl
                  compliance
                  orderDate
                  paymentDate
                  issuingDate
                  accountType
                  company {
                    name
                  }
                }

                offering {
                  id
                  name
                  symbol
                  chain
                  decimals
                  publicAddress
                  smartContractTemplate
                  fundingEndDate
                  fundingStartDate
                  fundingSupplyCap
                  marketPlaceId
                  hasHeroImage
                  hasOverviewImage
                  visualType
                  legal {
                    companyName
                    bank
                    iban
                    bic
                  }
                  market {
                    name
                    smartContractABI
                    publicAddress

                    token {
                      name
                      symbol
                      decimals
                    }
                  }
                }
              }
              nextToken
            }
          }
        }
      }
    }
  }
`;

export const readAccountOrders = /* GraphQL */ `
  query Portal($accountId: ModelIDKeyConditionInput, $filter: TransactionFilterInput, $limit: Int) {
    myPortal {
      id
      tenantId
      accounts(accountId: $accountId) {
        items {
          status
          accountId
          account {
            id

            transactions(filter: $filter, limit: $limit) {
              items {
                tenantId
                transactionId
                accountId
                offeringId
                blockchainAccountWalletId
                blockchainAccountWallet {
                  publicAddress
                }
                amount
                status
                contract
                orderData {
                  pricePerToken
                  amountInCurrency
                  currency
                  contractUrl
                  compliance
                  orderDate
                  paymentDate
                  issuingDate
                  accountType
                  company {
                    name
                  }
                }

                offering {
                  name
                  symbol
                  chain
                  decimals
                  publicAddress
                  fundingEndDate
                  fundingStartDate
                  fundingSupplyCap
                  decimals
                  legal {
                    companyName
                    bank
                    iban
                    bic
                  }
                }
              }
              nextToken
            }
          }
        }
      }
    }
  }
`;

export const readAccountTransfers = /* GraphQL */ `
  query Portal(
    $accountId: ModelIDKeyConditionInput
    $filter: TransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    myPortal {
      id
      tenantId
      accounts(accountId: $accountId) {
        items {
          status
          accountId
          account {
            id

            transactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
              items {
                tenantId
                transactionId
                accountId
                offeringId
                blockchainAccountWalletId
                blockchainAccountWallet {
                  publicAddress
                }
                amount
                status
                message
                contract
                transactionData {
                  txTo
                  txValue
                  txHash
                }

                offering {
                  name
                  symbol
                  chain
                  decimals
                  publicAddress
                  fundingEndDate
                  fundingStartDate
                  fundingSupplyCap
                  decimals
                }
              }
              nextToken
            }
          }
        }
      }
    }
  }
`;

export const readAccountBlockchainWallet = /* GraphQL */ `
  query Portal($accountId: ModelIDKeyConditionInput, $filter: ModelAccountFilterInput) {
    myPortal {
      tenantId
      accounts(accountId: $accountId) {
        items {
          status
          accountId
          account {
            name
            id
            mainBlockchainWalletId
            connectedAccounts(filter: $filter) {
              items {
                id
                name
                status
                message
                publicAddress
                blockchainProvider
                blockchainStatus
                blockchainRecovery
              }
            }
          }
        }
      }
    }
  }
`;
