export const getDashboardAllDetails = /* GraphQL */ `
  query Dashboard {
    dashboard {
      activities {
        tenantId
        id
        title
        description
        publishingDate
        featured
        hasHeroImage
        hasOverviewImage
        slug
      }
      actions {
        tenantId
        id
        title
        description
        publishingDate
        featured
        hasHeroImage
        hasOverviewImage
      }
      offerings {
        tenantId
        id
        name
        symbol
        status
        decimals
        description
        publicationDateTime
        featured
        hasHeroImage
        hasOverviewImage
        visualType
        visibility
        fundingSupplyGoal
        fundingSupplyCap
        fundingCurrency
        fundingPricePerToken
        fundingStartDate
        fundingEndDate
        advanced
        videoUrl
        totalSupplyOrdered
        numSupporterOrdered

        tokenDetails {
          field0
          field1
          field2
          field3
        }
        funding {
          totalSupply
          numSupporter
        }

        collectionGroups {
          id
          translations
          amount
          minAmount
        }
      }
    }
  }
`;

export const getNewsAndEventDetails = /* GraphQL */ `
  query Portal(
    $idPublishingDate: ModelActivityByTenantCompositeKeyConditionInput
    $filter: ModelActivityFilterInput
  ) {
    portal {
      name
      me {
        tenantId
        activities(idPublishingDate: $idPublishingDate, filter: $filter) {
          items {
            id
            name
            publishingDate
            activityType
            status
            title
            slug
            description
            content
          }
        }
      }
    }
  }
`;
