import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
import { isEmpty } from 'lodash';
import {
  getActiveTenant,
  getPublicGraphql,
  getPublicGraphqlAPIKEY,
} from 'src/components/AppSettings/appSettings.selector';
import NotificationActions from 'src/components/shared/Notification/actions';
import { actionsTypes } from './constants';
import {
  fetchPublicOfferingQuoteWidgetAction,
  fetchPublicOfferingWidgetAction,
} from './offerings.action';
import * as queries from './graphql/queries';

export function* fetchPublicOfferingData({ payload }) {
  const tenantId = yield select(getActiveTenant);
  const endpointPublic = yield select(getPublicGraphql);
  const apiKey = yield select(getPublicGraphqlAPIKEY);

  try {
    const params = {
      tenantId,
      offeringId: payload,
    };

    const referral = localStorage.getItem('referral');
    const isReferred =
      localStorage.getItem('isReferred') === true || localStorage.getItem('isReferred') === 'true'
        ? true
        : false;

    if (!isEmpty(referral) || isReferred) {
      params['referral'] = true;
    }
    // Create a custom API client instance with the desired endpoint
    API.configure({
      aws_appsync_graphqlEndpoint: endpointPublic,
      aws_appsync_region: 'eu-central-1',
      aws_appsync_authenticationType: 'API_KEY',
      aws_appsync_apiKey: apiKey,
    });

    const response = yield call([API, 'graphql'], graphqlOperation(queries.getOffering, params));
    const offering = response.data.getOffering;

    yield put(fetchPublicOfferingWidgetAction.success({ offering }));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(
      NotificationActions.showError({
        title: 'view_store:Notification.Error.Title',
        message: `view_store:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.code
        }`,
      }),
    );
    yield put(fetchPublicOfferingWidgetAction.error());
  }
}

export function* fetchPublicOfferingQuoteData({ payload }) {
  const tenantId = yield select(getActiveTenant);
  const endpointPublic = yield select(getPublicGraphql);
  const apiKey = yield select(getPublicGraphqlAPIKEY);

  try {
    const params = {
      tenantId,
      ...payload,
    };

    const referral = localStorage.getItem('referral');
    const isReferred =
      localStorage.getItem('isReferred') === true || localStorage.getItem('isReferred') === 'true'
        ? true
        : false;

    if (!isEmpty(referral) || isReferred) {
      params['referral'] = true;
    }

    // Create a custom API client instance with the desired endpoint
    API.configure({
      aws_appsync_graphqlEndpoint: endpointPublic,
      aws_appsync_region: 'eu-central-1',
      aws_appsync_authenticationType: 'API_KEY',
      aws_appsync_apiKey: apiKey,
    });

    const response = yield call([API, 'graphql'], graphqlOperation(queries.getQuote, params));
    const quote = response.data.getQuote;

    yield put(fetchPublicOfferingQuoteWidgetAction.success({ quote }));
  } catch (error) {
    console.error(error); // eslint-disable-line
    yield put(
      NotificationActions.showError({
        title: 'view_store:Notification.Error.Title',
        message: `view_store:Notification.Error.${
          Array.isArray(error.errors) ? error.errors[0].message : error.code
        }`,
      }),
    );
    yield put(fetchPublicOfferingQuoteWidgetAction.error());
  }
}

export default function* newsAndEventsSaga() {
  yield all([takeLatest(actionsTypes.FETCH_PUBLIC_OFFERING.PENDING, fetchPublicOfferingData)]);
  yield all([takeLatest(actionsTypes.FETCH_PUBLIC_QUOTE.PENDING, fetchPublicOfferingQuoteData)]);
}
